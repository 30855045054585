import React, { Component } from 'react';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Table, TableHead, TableCell, Checkbox, TableRow, TableBody, InputAdornment, FormControlLabel, Radio, RadioGroup, IconButton, Modal, Button, MenuItem, CircularProgress } from '@material-ui/core';
// react-redux
import { connect } from 'react-redux';
import { setFavored } from '../../../../redux/actions/NewOperationActions';
// react-input-mask
import InputMask from "react-input-mask";
// react-toastify
import { toast } from 'react-toastify';
// core-components
import { BankCode } from './BankCode';

class FavoredList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientID: 0,
      clientCNPJ: '',
      wbaID: '',
      
      documento: '',
      nome: '',
      agencia: '',
      banco: '',
      conta: '',

      bankCode: [],

      loading: false,
    }

    this.addFavorecido = this.addFavorecido.bind(this);
  }

  handleDocumentoChange = (event) => {
    this.setState({
      documento: event.target.value,
    });
  }

  handleNomeChange = (event) => {
    this.setState({
      nome: event.target.value,
    });
  }

  handleAgenciaChange = (event) => {
    this.setState({
      agencia: event.target.value,
    });
  }

  handleBancoChange = (event) => {
    this.setState({
      banco: event.target.value,
    });
  }

  handleContaChange = (event) => {
    this.setState({
      conta: event.target.value,
    });
  }

  addFavorecido() {
    // if(this.state.documento.substring(0, 10) != this.state.clientCNPJ.substring(0, 10)){
    //   toast.error("O CNPJ inserido é invalido."); 
    //   return
    // }
    
    if (this.validade() == false) {
      return
    }

    this.setState({
      loading: true,
    })

    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9GQVZPUkVDSURPOjA5MTc1MQ==',
      fid: 135,
      data: {
        FK_CLIENTE: this.state.clientID,
        DOCUMENTO: this.state.documento,
        FAVORECIDO: this.state.nome,
        NRO_AGENCIA: this.state.agencia,
        NRO_BANCO: this.state.banco,
        NRO_CONTA: this.state.conta,
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == null){
          this.addScript(responseJson.data.result.id);
          this.props.refreshComponent();
          this.props.closeModal();
        }
        else{
          console.log(responseJson.error.message);
        }

        this.setState({
          loading: false,
        })
    });
  }

  addScript(favoredID) {
    let wbaID = ''
    if(this.state.wbaID != null) {
      wbaID = this.state.wbaID
    }

    var obj = {
      tid: 'VF9GQVZPUkVDSURPX1NDUklQVDoxNDgxMTQ=',
      fid: 156,
      data: {
        FK_FAVORECIDO: favoredID,
        SCRIPT:  `insert into [SECURIT]..cadfavor (
                    ctrl_id, codigo, favorecido, banco, agencia, conta, 
                    NomeAgencia, CGC, DesconsiderarPraca, DVAGENCIA ) 
                  SELECT  
                    (SELECT MAX(ctrl_id) + 1 FROM [SECURIT]..cadfavor), '` + wbaID + `', '` + this.state.nome + `',
                    '` + this.state.banco + `', '` + this.state.agencia + `', '` + this.state.conta + `', NULL,
                    '` + this.state.documento + `', NULL, NULL`,
      }
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log('addScript', responseJson);
    });
  }

  getClientID(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:CLIENTE_POR_ID',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          this.setState({ 
            clientID: entry.ID,
            clientCNPJ: entry.CNPJ,
            wbaID: entry.CODIGO_WBA,
          });
        })
    });
  }

  componentDidMount() {
    this.getClientID();

    this.setState({
      bankCode: BankCode.map(BankCode => BankCode),
    }, () => {console.log('bankCode', this.state.bankCode)});
  }

  validade() {
    if (!this.validateCNPJ(this.state.documento)) {
      toast.error("CNPJ Invalido.");
      return false;
    }

    if (this.state.nome.length == 0) {
      toast.error("O nome deve ser preenchido.");
      return false;
    }

    if (this.state.agencia.length == 0) {
      toast.error("A agência deve ser preenchido.");
      return false;
    }

    if (this.state.banco.length == 0) {
      toast.error("O banco deve ser selecionado.");
      return false;
    }

    if (this.state.conta.length == 0) {
      toast.error("A conta deve ser preenchido.");
      return false;
    }

    return true
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;

      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  render() {
    return (
      <div style={{paddingTop: "40px"}}>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>CNPJ Favorecido</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask={'99.999.999/9999-99'}
                        maskPlaceholder=" "
                        value={this.state.documento}
                        onChange={this.handleDocumentoChange}
                      >
                        <TextField 
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" } }} 
                        />
                      </InputMask>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Nome Favorecido</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.nome} onChange={this.handleNomeChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Agência</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask={'99999999999999999999'}
                        maskPlaceholder=" "
                        value={this.state.agencia}
                        onChange={this.handleAgenciaChange}
                      >
                        <TextField 
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" } }} 
                        />
                      </InputMask>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Banco</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" style={{ width: '275px' }}>
                      <Select
                        value={this.state.banco}
                        onChange={this.handleBancoChange}
                        SelectDisplayProps={{ style: { padding: '13px' } }}
                        
                      >
                        <MenuItem value=""><em></em></MenuItem>
                        {this.state.bankCode.map((BankCode) => (
                          <MenuItem value={BankCode.value}>{BankCode.value} - {BankCode.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Conta</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask={'99999999999999999999'}
                        maskPlaceholder=" "
                        value={this.state.conta}
                        onChange={this.handleContaChange}
                      >
                        <TextField 
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" } }} 
                        />
                      </InputMask>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs style={{paddingTop: "20px"}}>
          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
            <Box p={0}>
              {this.state.loading == true ? 
                <div style={{marginRight: "32px"}}>
                  <Grid container justify="center" alignItems="center" xs={12}>
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </div>
              :
                <Button variant="contained" color="primary" onClick={this.addFavorecido}>
                  Salvar
                </Button>
              }
            </Box>
          </Box>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(FavoredList);
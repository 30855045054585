import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import img from '../../images/done.png';
import img_upload from '../../images/upload.png';


var self = null;

export default class Step4 extends Component {

    constructor(props) {
        super(props);
        this.state = {action: '' };

        self = this;
      }


      componentDidMount()
      {
          setTimeout(() => {
              self.props.toStatus();
          }, 5000);
      }

      render() {

        return (
            <div style={{flex: 1 }}>
                <Row style={{flex: 1 }}>
                    <Col style={{textAlign: 'center', flex: 1}}>
                        <div style={{justifyContent: 'center',flex: 1 }}>
                            <img src={img} alt="car" style={{width: 100, height: 100, marginTop: 30 }}/>

                            <p style={{color: '#314289', fontSize: 28, fontWeight: '600', margin: 30}}>Parabéns! Pré-Cadastro enviado com sucesso. Você receberá um e-mail com as instruções para continuar</p>
                        </div>
                        
                    </Col>
                </Row>
            </div>
        )
      }

}
import React, { Component } from 'react';
import '../../components/Style.css';
// @material-ui/core
import { Typography, Grid, Paper, Divider, CircularProgress, Box, Modal } from '@material-ui/core';
// @material-ui/icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
// recharts
import { PieChart, Pie, Cell, ResponsiveContainer, Label} from 'recharts';
// react-redux
import { connect } from 'react-redux';

import barImage from '../../../images/bar.png';
// core-components
import OperationsList from '../Operations/OperationsList'
import ListaRecebiveis from '../Reports/ListaRecebiveis';

const COLORS = ['#0088FE', '#d3d3d3'];

var self = null;

class chartPerCent{
  name = '';
  value = 0;
}

class DashboardBox extends Component {
  constructor(props) {
    super(props);

    self = this;

    this.state = {
      totalValue: 0,
      valueToExpire: 0,
      expiredValue: 0,
      borderoPercent: 0,
      bordero: 0,
      loadingTimer: false,
      openModal: false,
    };

    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount(){
    
    var obj = {
        qid : 'PAGINA_DASHBOARD:BORDEROS_ABERTOS',
        conditions: [
          {
            filterid: 'USUARIO',
            values: [this.props.credencials.user_id]
          }
        ]
      }
    
      fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj)
        }).then((response) => response.json()).then((responseJson) => {
          
          console.log(responseJson);
  
          if(responseJson.list.length > 0) {
            var percent = 0;
            
            if (responseJson.list[0].TOTAL > 0)
              percent = (responseJson.list[0].ABERTOS * 100) / responseJson.list[0].TOTAL; 
            else
              percent = 0;
            
            self.setState({
              bordero: responseJson.list[0].ABERTOS, 
              borderoPercent: percent, 
              loadingTimer: true
            });
          }
      });
  }

  formatPercent(number) {
    if (number != null)
      return number.toFixed(2).toLocaleString('pt-BR');
    else 
      return '0,00'
  }

  handleModal() {
    this.setState ({
      openModal: !this.state.openModal
    });
  }

  render() {
    return (
      <div>
        <Paper>
          {this.state.loadingTimer == false ? 
          <div>
            <Grid container justify="center" alignItems="center" xs={12} style={{height: '150px'}}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </div>
          :
          <div style={{ padding: '15px 10px 15px 10px', marginTop: '-10px'}}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="left">
                  Borderôs Abertos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="flex-end">
                  <Grid item xs={6}>
                    <Typography variant="h4" align="left">
                      {this.state.bordero}
                    </Typography>
                  </Grid>               
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                      <Box p={0}>
                        <img src={barImage} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{paddingTop: '10px'}}>
                <Grid container alignItems="flex-end">
                  <Grid item xs={6}>
                    <Typography variant="body2" align="left" color={this.state.borderoPercent > 0 ? "primary" : "error" }>
                      {/* {this.state.borderoPercent > 0 ? <ArrowUpwardIcon fontSize="small"/> : <ArrowDownwardIcon fontSize="small"/>} */}
                      {this.formatPercent(this.state.borderoPercent)}% 
                    </Typography>      
                  </Grid>
                  <Grid item xs={6}>
                    <a href="#/dashboard" onClick={this.handleModal}>
                      <Typography variant="body2" align="right">
                        Acessar
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          }
        </Paper>
        <div>
          <Modal open={this.state.openModal} onClose={this.handleModal} className="modalPaper" >
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper">
                  <Grid container style={{paddingBottom: '20px'}}>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="h6" align="left">
                        Borderôs Abertos
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleModal}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <Paper style={{margin: '-20px'}}>
                    <ListaRecebiveis quickAcess={true}/>
                  </Paper>
                </div>
              </Paper>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(DashboardBox);
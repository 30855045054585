import React, { Component } from 'react';
import '../Style.css';
import { forwardRef } from 'react';
import ReactDOMServer from "react-dom/server";
import '../Style.css';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, Modal, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
// react-redux
import { connect } from 'react-redux';
import { updateOperationsList } from '../../../../redux/actions/OperationsActions';
// @material-ui/icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ViewListIcon from '@material-ui/icons/ViewList';
// material-table icons
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
// react-toastify
import { toast } from 'react-toastify';
// core-components
import ItensList from './ItensList';
import PendenciesList from '../Home/Pendencies/PendenciesList';
// import Wijmo styles and components
import 'wijmo/styles/wijmo.css';
import { CollectionView } from 'wijmo/wijmo';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { FlexChart, FlexChartSeries } from 'wijmo/wijmo.react.chart';
import * as wjcCore from "wijmo/wijmo";

// apply Wijmo license key
import { setLicenseKey } from 'wijmo/wijmo';
import BilletList from './BilletList';
import { getCulture } from '../Culture';

setLicenseKey(
  "portal.nobelbanco.com.br|nobelbanco.com.br,456644433637969#B0ijOklkIs4nIxYHOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZpl6bCR7QBtyN0NWcHhGSClkVapWdNNGOlhzaslFRrs6LFZjWrtyZwpFRr56aUl6SulkNLR7KqpHTUJ7dFZzV9I5RulUO7JXMndUVlREWZ3EbYBjdiJ5bktUc74UM7BHMoNmUyIVMtR5bxYnRRFGdER5N5o7QLNET7MjcoNGbEV5d4kXdBVDZYJHMX3kNytWO5oGTttkaxgkTYRGTQhmV7QkZDdmaTZlbXl6U6g6bzFjTjNGVHZTa6Ekdi3mNWBFMUdkTXZWWrE7aRV4TkZTa6N5cudEZn3CeOxEMG94K8kjZ73EN6EDTZJmYiJzLOhkaLhTc9N5TPBnYw8We7xWM5YWR8l5Ukh4aw54YN5GVjV7KRBHawYWTvcUcyIXevtWWEpke4cUZIVEb05EV7YjaBh4KillcHdlcF94aGp5cjllRDtkVsxWQRBlewdGTDlVRwYzb6QmI0IyUiwiIyY4QBVUOEZjI0ICSiwyMzQzMxMDO4MTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzQDOxcDMgUDM5ATMyAjMiojI4J7QiwiIyJmLt36Yu26YuFmYsVmYv9GLyJmLt36Yu26YuFmYsVmYv9mLsFGdy3GciojIz5GRiwiIhRGdMBSYjlGdhOcby3mZulEItVEIhl6Zvx6buNWZUBSZgEWay3GdsV7cu36QgQmbp5mblB7TiojIh94QiwiI9YTO7MjNzMDN4QjN6nDui"
);

// material-table icons
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />)
};

class operation {
  id = 0;
  bordero = 0;
  dataCriacao = null; 
  estadoBordero = ''; 
  sel = false;
  // itens = null;
  total = '';
  totalExport = 0;
  pendencias = 0;
  boleto = null;
};

class pendency {
  id = 0;
  name = '';
  type = '';
}

class Operations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operationList: [],
      openedModalIdRemessaItens: '',
      openedModalIdPendencias: '',
      flexGrid: null,
      openDownloadDialog: false,
      openPendenciesDialog: false,
      itemCount: 0,
      downloadID: '',

      loading: false,
    }

    this.handleCloseModalRemessaItens = this.handleCloseModalRemessaItens.bind(this);
    this.handleCloseModalPendencias = this.handleCloseModalPendencias.bind(this);
    this.handleOpenModalRemessaItens = this.handleOpenModalRemessaItens.bind(this);
    this.handleOpenModalPendencias = this.handleOpenModalPendencias.bind(this);

    this.downloadFiles = this.downloadFiles.bind(this);
    this.closeDownloadDialog = this.closeDownloadDialog.bind(this);
    this.closePendenciesDialog = this.closePendenciesDialog.bind(this);
  }

  updateOperationList(){
    this.setState({
      loading: true,
    })

    var obj = {
      qid : 'PAGINA_DASHBOARD:REMESSA',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var operationList = [];
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          let operationObject = Object.create({}, operation);
          
          operationObject.sel = false;
          operationObject.id = entry.ID;
          operationObject.bordero = entry.BORDERO;
      
          var _date = new Date(entry.Data);          

          operationObject.dataCriacao = _date;          

          operationObject.estadoBordero = entry.Status;
          
          let _total = wjcCore.Globalize.format(entry.Total, 'n2');

          operationObject.totalExport = entry.Total
          operationObject.total = _total
          
          operationObject.pendencias = entry.Pendencias;

          if (!this.props.quickAcess || entry.Status != 'Concluído')
            operationList.push(operationObject);
        })
  
        this.setState({
          operationList: operationList,
          loading: false,
        });
      });
  }
  
  componentDidMount() {
    this.updateOperationList();

    wjcCore.culture = getCulture();
  }

  handleOpenModalRemessaItens(modalIndex) {
    let modalId = parseInt(this.state.flexGrid.cells.getCellElement(modalIndex, 1).childNodes[0].data);

    this.setState({
      openedModalIdRemessaItens: modalId
    });
  }

  handleCloseModalRemessaItens() {
    this.setState({
      openedModalIdRemessaItens: null
    });
  }

  handleOpenModalPendencias(modalID) {
    this.setState({
      openedModalIdPendencias: modalID
    });
  }

  handleCloseModalPendencias() {
    this.setState({
      openedModalIdPendencias: null
    });
  }

  handleDownloadFile(file)
  {
    setTimeout(() => {
      const response = {
        file: file,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);

    console.log(file);
  }

  initializeGrid(flex) {
    let self = this;

    flex.formatItem.addHandler((s, e) => {
      if (e.panel == s.columnHeaders) {
        if (s.columns[e.col].binding == 'sel') {
          e.cell.innerHTML = '<input class="select-all" tabindex="-1" type="checkbox">';
          this._updateSelectAllBox(s);
        }
      }
      else if (e.panel == s.cells) {
        wjcCore.setAttribute(e.cell.parentElement, 'aria-selected', s.rows[e.row].dataItem.sel);
      }
    });
    
    // select/de-select all items when user clicks the check box on the header
    flex.hostElement.addEventListener('click', (e) => {
      if (wjcCore.hasClass(e.target, 'select-all') && e.target instanceof HTMLInputElement) {
        flex.deferUpdate(() => {
          flex.collectionView.items.forEach((item) => {
            item.sel = (e.target).checked;
          });
        });
      }
    });

    flex.itemFormatter = function(panel, r, c, cell) {
      var col = panel.columns[c];
      let itensHtml = <div>
                        <ViewListIcon style={{color: '#3f51b5'}}/>
                      </div>
      let pendencyFalseHtml = <span style={{color: '#1dff00'}}>
                                <b>{cell.innerHTML}</b>
                              </span>
      let pendencyTrueHtml =  <span style={{color: 'red'}}>
                                <b>{cell.innerHTML}</b>
                              </span>

      if (col.name == 'sel') {
        cell.onmouseup = () => self.handleSelectedOperations();
        col.width = 50
        
        if (self.props.quickAcess)
          col.width = 1
      }

      if (col.name == 'id') {
        col.width = 75;
        col.align = 'center'; 
      }

      if (col.name == 'dataCriacao') {
        col.header = 'Data de Criação';
      }
      
      if (col.name == 'dataCriacao' && panel.cellType == 1) {
        col.align = 'center'; 
        col.width = 150
      }

      if (col.name == 'bordero') {
        col.header = 'Borderô';
      }
      
      if (col.name == 'bordero' && panel.cellType == 1) {
        col.align = 'center'; 
        col.width = 125
      }

      if (col.name == 'estadoBordero') {
        col.header = 'Estado do Borderô';
      }
      
      if (col.name == 'totalExport') {
        col.width = 1
      }

      if (col.name == 'total') {
        col.header = 'Total (R$)';
        cell.align = 'right'

        // flex.columnFooters.setCellData(0, col.index, flex.collectionView.getAggregate(wjcCore.Aggregate.Sum, 'totalExport'))
      } 

      if (col.name == 'pendencias') {
        col.header = 'Pendências';
      }

      if (col.name == 'pendencias' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 125;

        if (cell.innerHTML > 0) {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<ErrorOutlineIcon style={{color: '#D7AD1B', pointerEvents: "none"}}/>);
          cell.onclick = () => self.openPendenciesDialog(self.state.flexGrid.cells.getCellElement(r, 1).childNodes[0].data); 
        }
        else {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<CheckCircleOutlineIcon style={{color: '#3f51b5', pointerEvents: "none"}}/>);
        }

      }

    } 

    // flex.columnFooters.rows.push(new wjcCore.grid.GroupRow());

    wjcCore.culture = getCulture();

    this.setState({
      flexGrid: flex
    });
  }

  _updateSelectAllBox(grid) {
    let cb = grid.hostElement.querySelector('.select-all');

    if (cb instanceof HTMLInputElement) {
      let items = grid.collectionView.items, last = null, indeterminate = false;
      
      for (let i = 0; i < items.length; i++) {
        if (last != null && items[i].sel != last) {
          indeterminate = true;
          break;
        }
        last = items[i].sel;
      }
      
      if (indeterminate) {
        cb.checked = true;
        cb.indeterminate = true;
      }
      else {
        cb.checked = last;
      }

      this.handleSelectedOperations();
    }
  }

  downloadFiles() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:BOLETO_REMESSA',
      conditions: [
        {
          filterid: 'REMESSA',
          values: [this.state.downloadID]
        }
      ]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',   
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          if (entry.NRO_BOLETO != null){
            window.open(entry.NRO_BOLETO, '_blank');
          }
        });
    });

    this.setState({
      downloadID: ''
    });

    this.closeDownloadDialog();
  }

  closeDownloadDialog() {
    this.setState({
      openDownloadDialog: false,
      downloadID: ''
    });
  }

  openDownloadDialog(downloadID) {
    this.setState({
      downloadID: downloadID,
      openDownloadDialog: true,
    });
  }

  closePendenciesDialog() {
    this.setState({
      openPendenciesDialog: false,
      pendenciesID: ''
    });
  }

  openPendenciesDialog(pendenciesID) {
    this.setState({
      pendenciesID: pendenciesID,
      openPendenciesDialog: true,
    });
  }

  handleSelectedOperations() {
    let SelectedList = [];
    let self = this;

    setTimeout(function(){
      for (var i = 0; i < self.state.flexGrid.cells.rows.length; i++){
        if (self.state.flexGrid.cells.rows[i]._data.sel){
          SelectedList.push(self.state.flexGrid.cells.rows[i]._data.id);
        }
      }
  
      self.props.updateOperationsList(SelectedList); 
    }, 250) // gambiarra
  }

  loadingComponent(props) {
    const loading = props.loading;

    if (loading) {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              Você não possui Borderôs para consultar
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="expansionPanelWrapper" style={{paddingTop: "20px", paddingBottom: "20px" }}>
        <div className="App">
          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
            <Box p={0}>
              {this.state.operationList.length > 0 ? 
                <div className="App-panel">
                  <FlexGrid 
                    alternatingRowStep={0} 
                    headersVisibility="Column" 
                    selectionMode="Cell" 
                    itemsSource={this.state.operationList} 
                    initialized={this.initializeGrid.bind(this)}
                    class="custom-flex-grid"
                  >
                    <FlexGridFilter/>
                  </FlexGrid>   
                </div>
              :
                <this.loadingComponent loading={this.state.loading} />
              }
              
              {/* <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <Box p={0}>
                  <Button variant="contained" color="primary" onClick={this.confirmDownload}>
                    Download
                  </Button>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </div>
        <Dialog open={this.state.openDownloadDialog} onClose={this.closeDownloadDialog} >
          <DialogTitle>
            Download em Lote
          </DialogTitle>
          <DialogContent style={{marginBottom: '20px'}}>
            <div style={{height: '300px'}}>
              <BilletList remessa={this.state.downloadID} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDownloadDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.downloadFiles} color="primary" autoFocus>
              Download de Todos
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.openPendenciesDialog} onClose={this.closePendenciesDialog} >
          <DialogTitle>
            Pendências
          </DialogTitle>
          <DialogContent style={{marginBottom: '20px', width: '500px'}}>
            <div style={{height: '300px'}}>
              <PendenciesList numBorderoID={this.state.pendenciesID} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closePendenciesDialog} color="primary">
              Concluido
            </Button>
          </DialogActions>
        </Dialog>
      </div> 
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
  };
}

export default connect(mapStateToProps, { updateOperationsList } )(Operations);
import React, { Component } from 'react';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Table, TableHead, TableCell, Checkbox, TableRow, TableBody, InputAdornment, Badge, Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Modal } from '@material-ui/core';
// @material-ui/icons
import BackupIcon from '@material-ui/icons/Backup';
import DescriptionIcon from '@material-ui/icons/Description';
// react-redux
import { connect } from 'react-redux';
import { setFileList } from '../../../../redux/actions/NewOperationActions';
import { setItemList } from '../../../../redux/actions/NewOperationActions'
// react-dropzone
import Dropzone from 'react-dropzone';
// react-toastify
import { toast } from 'react-toastify';
// aws-s3
import AWS from 'aws-sdk';
// core-component
import DrawnRegistration from './DrawnRegistration';

class ItemObject {
  tipoItem = 0;
  documento = '';
  nome = '';
  valor = 0;
  vencimento = null;
  emissao = null;
  nroDocumento = '';
  aceite = false;
  tipoDuplicata = 100;
  desconto = 0;
  descontoPorCento = 0;
  urlXML = '';
  documentIndex = 0;

  logradouro = '';
  numero = '';
  bairro = '';
  municipio = '';
  uf = '';
}

class ImportFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      itemCount: 0,
      index: -1,
      openDialog: false,
      userCNPJ: '',

      drawnRegistrationModal: false,
      drawnRegistrationDocumento: '',
      drawnRegistrationNome: '',
    }

    this.parseXMLFile = this.parseXMLFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDrawnRegistrationModal = this.handleDrawnRegistrationModal.bind(this);
  }

  addFile(files, input) {
    const list = this.state.fileList;

    for (var i = 0; i < files.length; i++){
      if (files[i] != null) {
        var blob = files[i].slice(0, files[i].size, 'text/xml'); 
        var newFile = new File([blob], files[i].name.replace(/[^A-Za-z0-9.]+/g, '_'), {type: 'text/xml'});
        
        list.push(newFile);
        this.parseXMLFile(newFile);
      }
    }
    
    if (input)
      input.value = '';

    this.setState({
      fileList: list
    }, () => {this.handleReducer()});
  }

  removeItems() {
    let itemCount = 0;
    let listItem = this.props.itemList;

    for (var i = 0; i < listItem.length; i++){
      if (listItem[i].documentIndex == this.state.index)
        itemCount++
    }

    for (var i = 0; i < listItem.length; i++){
      if (listItem[i].documentIndex == this.state.index){
        listItem.splice(i, 1);
        i--;
      }
    }
    
    this.props.setItemList(listItem);
  }

  confirmDeletion(index) {
    let itemCount = 0;
    let listItem = this.props.itemList;

    for (var i = 0; i < listItem.length; i++){
      if (listItem[i].documentIndex == index)
        itemCount++
    }

    this.setState({
      itemCount: itemCount,
      index: index,
      openDialog: true,
    });
  }

  closeDialog() {
    this.setState({
      openDialog: false,      
      itemCount: 0,
      index: -1,
    });
  }

  removeFile() {
    this.removeItems();

    const list = this.state.fileList;

    list.splice(this.state.index, 1);
  
    this.setState({
      fileList: list,
      itemCount: 0,
      index: -1,
      openDialog: false,
    }, () => {this.handleReducer()});
  }

  handleLinkClick = () => {
    var input = document.getElementsByClassName("inputElement");

    input[0].click();
  }

  handleReducer() {
    this.props.setFileList(this.state.fileList);
  }

  componentWillReceiveProps(newProps) {
    if(newProps.refresh !== this.props.refresh) 
      this.refreshComponent();
  }

  refreshComponent() {
    this.setState({
      fileList: []
    }, () => {this.handleReducer()});
  }

  componentDidMount() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:PAINEL_USUARIO',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        this.setState({
          userCPNJ: responseJson.list[0].CNPJ
        });
    });
  }

  parseXMLFile(file) {
    let reader = new FileReader();
    let self = this;
    
    reader.readAsText(file, "UTF-8");

    reader.onload = function (evt) {
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(evt.target.result, "text/xml");

      let list = self.props.itemList;
      let chequeItem = Object.create({}, ItemObject);

      if (xmlDoc.getElementsByTagName("nfeProc").length == 0){
        toast.warn("O arquivo não é uma nota fiscal valida!");

        self.setState({
          index: self.state.fileList.length - 1
        })

        self.removeFile();
        
        return
      }

      if (self.state.userCPNJ.replace(/[^\d]+/g,'') != xmlDoc.getElementsByTagName("CNPJ")[0].childNodes[0].nodeValue.replace(/[^\d]+/g,'')){
        toast.warn("O arquivo não pertence a esse CNPJ!");
        
        self.setState({
          index: self.state.fileList.length - 1
        })

        self.removeFile();

        return
      }

      // var _drawnList = [];

      // for(var i = 0; i < self.props.drawnList.length; i++){
      //   _drawnList = self.props.drawnList[i].documento.replace(/\D/g, "");
      // }

      // if (_drawnList.indexOf(xmlDoc.getElementsByTagName("CNPJ")[1].childNodes[0].nodeValue) === -1){
      //   toast.error("O sacado dessa nota fiscal não está cadastro.");

      //   self.setState({
      //     drawnRegistrationModal: true,
      //     drawnRegistrationDocumento: xmlDoc.getElementsByTagName("CNPJ")[1].childNodes[0].nodeValue,
      //     drawnRegistrationNome: xmlDoc.getElementsByTagName("xNome")[1].childNodes[0].nodeValue,
      //   });

      //   self.removeFile();

      //   return
      // }

      var urlXML = '';
      
      const s3 = new AWS.S3({
        accessKeyId: 'AKIAIM54H7SBTJOZI6DQ',
        secretAccessKey: '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',
        region: 'us-east-2',
      });

      s3.upload({
        Bucket: 'blue-temp-files',
        Key: file.name.replace(/ /g, '_'),
        Body: file,
        ACL: 'public-read'
      },function(err, data){
        urlXML = data.Location;

        console.log(data.Location);
        console.log('Successfully uploaded package.');

        for (var i = 0; i < xmlDoc.getElementsByTagName("dup").length; i++) {
          let duplicataItem = Object.create({}, ItemObject);

          duplicataItem.tipoItem = 101;
          duplicataItem.documento = xmlDoc.getElementsByTagName("CNPJ")[1].childNodes[0].nodeValue.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
          duplicataItem.nome = xmlDoc.getElementsByTagName("xNome")[1].childNodes[0].nodeValue;
          duplicataItem.emissao = new Date(xmlDoc.getElementsByTagName("dhEmi")[0].childNodes[0].nodeValue.substring(0, 10)).toLocaleDateString();
          duplicataItem.desconto = parseFloat(xmlDoc.getElementsByTagName("vDesc")[0].childNodes[0].nodeValue);
          duplicataItem.valor = parseFloat(xmlDoc.getElementsByTagName("dup")[i].getElementsByTagName("vDup")[0].textContent);
          duplicataItem.descontoPorCento = (duplicataItem.desconto * 100) / duplicataItem.valor;

          duplicataItem.urlXML = urlXML;
          duplicataItem.documentIndex = self.state.fileList.length - 1;
  
          // duplicataItem.nroDocumento = xmlDoc.getElementsByTagName("dup")[i].getElementsByTagName("nDup")[0].textContent;
          duplicataItem.nroDocumento = xmlDoc.getElementsByTagName("nNF")[0].childNodes[0].nodeValue + '/' + self.indexToAlphabet(i);
          duplicataItem.vencimento = new Date(xmlDoc.getElementsByTagName("dup")[i].getElementsByTagName("dVenc")[0].textContent).toLocaleDateString();
          
          duplicataItem.tipoDuplicata = 100;

          duplicataItem.logradouro = xmlDoc.getElementsByTagName("xLgr")[1].childNodes[0].nodeValue; 
          duplicataItem.numero = xmlDoc.getElementsByTagName("nro")[1].childNodes[0].nodeValue;
          duplicataItem.bairro = xmlDoc.getElementsByTagName("xBairro")[1].childNodes[0].nodeValue;
          duplicataItem.municipio = xmlDoc.getElementsByTagName("xMun")[1].childNodes[0].nodeValue;
          duplicataItem.uf = xmlDoc.getElementsByTagName("UF")[1].childNodes[0].nodeValue;

          list.push(duplicataItem);
        }
        
        self.props.setItemList(list);
      });
    }
    reader.onerror = function (evt) {
      console.log("error reading file!");
    }
  }
  
  handleDrawnRegistrationModal() {
    this.setState({
      drawnRegistrationModal: !this.state.drawnRegistrationModal
    });
  }

  indexToAlphabet(i) {
    return (i >= 26 ? this.indexToAlphabet((i / 26 >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
  }

  render() {
    return (
      <div>

        <div style={{paddingTop: "20px", paddingBottom: "20px"}}>
          <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12} style={{width: '100%'}}>
              <Dropzone onDrop={acceptedFiles => this.addFile(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                      <div className="documentItemChecked" {...getRootProps()} style={{color: 'lightgray', height: '100%', width: '100%', borderRadius: '20px', backgroundColor: "#f0f2f8"}}>
                        <BackupIcon style={{ fontSize: 100 }} />
                        <Typography variant="body1">
                          Arraste o(s) Arquivo(s) aqui ou <br /> clique no botão abaixo <br />
                        </Typography>
                        <input className="inputElement" type="file" multiple style={{display: "none"}} onChange={(e) => this.addFile(e.target.files, e.target)} accept="text/xml"/>
                        <Button variant="contained" color="primary" onClick={() => this.handleLinkClick()}>Escolher Arquivo</Button>
                      </div>
                    </section>
                )}
                </Dropzone>
            </Grid>
            <Grid container xs={12} style={{height: "60px"}} >
              {this.state.fileList.map((file, index) => (
                <Grid container xs={2} justify="center">
                  <Grid item style={{paddingTop: "32px"}}>
                    <Badge 
                      badgeContent="X" 
                      color="error" 
                      component="a" 
                      href="#/dashboard"
                      onClick={() => this.confirmDeletion(index)} 
                      style={{position: "absolute", paddingLeft: "32px"}} 
                    />
                    <DescriptionIcon color="primary" fontSize="large"/>
                  </Grid>
                  <Grid item xs zeroMinWidth xs={12}>
                    <Typography noWrap style={{textAlign: 'center'}}>
                      {file.name}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid> 
          </Grid>
        </div>
        <Dialog open={this.state.openDialog} onClose={this.closeDialog} >
          <DialogTitle>{"Deseja remover o arquivo?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Remover este arquivo resultara na remoção de {this.state.itemCount} registros.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.removeFile} color="primary" autoFocus>
              Aceitar
            </Button>
          </DialogActions>
        </Dialog>
        <Modal 
          open={this.state.drawnRegistrationModal} 
          onClose={this.handleDrawnRegistrationModal} 
          className="modalPaper"
        >
          <div>
            <DrawnRegistration 
              documento={this.state.drawnRegistrationDocumento} 
              razaoSocial={this.state.drawnRegistrationNome} 
              handleModal={this.handleDrawnRegistrationModal} 
            />
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    itemList: state.NewOperationReducer.itemList,
    drawnList: state.NewOperationReducer.drawnList,
  };
}

export default connect(mapStateToProps, { setFileList, setItemList } )(ImportFile);
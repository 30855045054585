import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import img from '../../images/step2.png';
import img2 from '../../images/step2_1.png';
import img_upload from '../../images/upload.png';

import { addStep2 } from '../../redux/actions/PreCadastroActions';

import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';


import { connect } from 'react-redux';
import { Grid, Hidden } from '@material-ui/core';


export class Step2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      action: '',
      showingTransporte: false,
      recuperacao: '',
      razaoSocial: '',
      cnpj: '',
      telefoneEmpresa: '',
      emailEcpf: '',
      numeroFuncionarios: '',
      valorMedioTitulos: '',
      faturamento: '',
      prazoMedio: ''
    };
  }

  recuperacaoChange(event) {
    this.setState({ recuperacao: event.target.value });

    var obj = this.state;
    obj.recuperacao = event.target.value;

    this.props.addStep2(obj);
  }


  segmentoChange(event) {
    // if (event.target.value == '100') {
    //   this.setState({ showingTransporte: false });
    // }
    // else if (event.target.value == '101') {
    //   this.setState({ showingTransporte: true });
    // }

    this.setState({ segmento: event.target.value });

    var obj = this.state;
    obj.segmento = event.target.value;

    this.props.addStep2(obj);

  }

  handleRazaoSocial(txt) {
    this.setState({ razaoSocial: txt });

    var obj = this.state;
    obj.razaoSocial = txt;

    this.props.addStep2(obj);
  }

  handleCnpj(txt) {
    this.setState({ cnpj: txt });

    var obj = this.state;
    obj.cnpj = txt;

    this.props.addStep2(obj);
  }

  handleTelefoneEmpresa(txt) {
    this.setState({ telefoneEmpresa: txt });

    var obj = this.state;
    obj.telefoneEmpresa = txt;

    this.props.addStep2(obj);
  }

  handleEmailECPF(txt) {
    this.setState({ emailEcpf: txt });

    var obj = this.state;
    obj.emailEcpf = txt;

    this.props.addStep2(obj);
  }

  handleNumeroFuncionarios(txt) {
    this.setState({ numeroFuncionarios: txt });

    var obj = this.state;
    obj.numeroFuncionarios = txt;

    this.props.addStep2(obj);
  }

  handleValorMedioTitulos(txt) {
    this.setState({ valorMedioTitulos: txt.floatValue });

    var obj = this.state;
    obj.valorMedioTitulos = txt.floatValue;

    this.props.addStep2(obj);
  }

  handleFaturamento(txt) {
    this.setState({ faturamento: txt });

    var obj = this.state;
    obj.faturamento = txt;

    this.props.addStep2(obj);
  }

  handlePrazoMedio(txt) {
    this.setState({ prazoMedio: txt });

    var obj = this.state;
    obj.prazoMedio = txt;

    this.props.addStep2(obj);
  }

  handleLogisticaEntrega(txt) {
    if (txt.floatValue < 0)
      txt.floatValue = 0;

    if (txt.floatValue > 100)
      txt.floatValue = 100;

    this.setState({ logisticaEntrega: txt.floatValue });

    var obj = this.state;
    obj.cheques = txt.floatValue;

    this.props.addStep2(obj);
  }

  handleLogisticaEmbarcada(txt) {
    if (txt.floatValue < 0)
      txt.floatValue = 0;

    if (txt.floatValue > 100)
      txt.floatValue = 100;

    this.setState({ logisticaEmbarcada: txt.floatValue });

    var obj = this.state;
    obj.logisticaEmbarcada = txt;

    this.props.addStep2(obj);
  }

  handleLogisticaPreFaturamento(txt) {
    if (txt.floatValue < 0)
      txt.floatValue = 0;

    if (txt.floatValue > 100)
      txt.floatValue = 100;

    this.setState({ logisticaPreFaturamento: txt.floatValue });

    var obj = this.state;
    obj.logisticaPreFaturamento = txt;

    this.props.addStep2(obj);
  }

  componentDidMount() {
    this.setState({
      valorMedioTitulos: this.props.valorMedioTitulos, faturamento: this.props.faturamento,
      razaoSocial: this.props.razaoSocial, cnpj: this.props.cnpj, telefoneEmpresa: this.props.telefoneEmpresa,
      emailEcpf: this.props.emailEcpf, numeroFuncionarios: this.props.numeroFuncionarios,
      prazoMedio: this.props.prazoMedio, segmento: this.props.segmento, recuperacao: this.props.recuperacao,
    });

    this.props.handleBack();
  }

  telefoneMask() {
    if (this.props.telefoneEmpresa && this.props.telefoneEmpresa.match(/\d/g) && this.props.telefoneEmpresa.match(/\d/g).length > 10)
      return "(99) 99999 9999"

    return "(99) 9999 99999"
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ justifyContent: 'center', marginBottom: 20 }}>
              <img src={img} alt="car" style={{ width: 45, height: 45, marginRight: 15 }} />
              <a className="step-title">Dados da Empresa</a>
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <div className="form-fields">
                  <a className="form-labels">*Razão Social:</a>
                  <input value={this.props.razaoSocial} onChange={(event) => this.handleRazaoSocial(event.target.value)} class="form-control" type='text' />
                </div>
                <div className="form-fields">
                  <a className="form-labels">*CNPJ (Número de Inscrição):</a>
                  <InputMask mask="99.999.999/9999-99" value={this.props.cnpj} onChange={(event) => this.handleCnpj(event.target.value)} class="form-control" type='text' />
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Telefone (Fixo / Celular):</a>
                  <InputMask mask={this.telefoneMask()} value={this.props.telefoneEmpresa} onChange={(event) => this.handleTelefoneEmpresa(event.target.value)} class="form-control" type='text' />
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Segmento</a>
                  <div className="form-options" style={{ width: '100%', marginTop: 10 }}>
                    <ul onChange={this.segmentoChange.bind(this)}>
                      <li style={{ float: 'left', marginRight: 7 }} ><input checked={(this.state.segmento == 100 ? true : false)} class="radioBtn" type="radio" value="100" name="btnSegmento" /> Serviço</li>
                      <li style={{ float: 'left' }} ><input checked={(this.state.segmento == 101 ? true : false)} class="radioBtn" type="radio" value="101" name="btnSegmento" /> Produtos</li>
                    </ul>
                  </div>
                </div>
                <Hidden lgUp>
                  {this.state.showingTransporte ?
                    <div>
                      <Grid item xs={12} lg={4}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item xs={12}>
                            <div style={{ justifyContent: 'center', marginBottom: 20 }}>
                              <img src={img2} alt="car" style={{ width: 60, height: 40, marginRight: 15 }} />
                              <a className="step-title">Dados da Transporte</a>
                            </div>
                            <div>
                              <a className="form-labels">Mercadorias:</a>
                              <ul>
                                <li><input class="radioBtn" type="radio" value="socio" name="logistica" /> Transportadora</li>
                                <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Carro Próprio</li>
                                <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Autônomo</li>
                                <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Sacado Retira</li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item xs={12}>
                            <div>
                              <a className="form-labels">Logistica:</a>
                              <ul>
                                <li>
                                  <div style={{ marginTop: 10 }}>
                                    <a className="form-labels">% Entrega</a>
                                    <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaEntrega} onValueChange={(event) => this.handleLogisticaEntrega(event)} class="form-control" type='text' style={{ width: 150 }} />
                                  </div>
                                </li>
                                <li>
                                  <div style={{ marginTop: 10 }}>
                                    <a className="form-labels">% Embarcada</a>
                                    <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaEmbarcada} onValueChange={(event) => this.handleLogisticaEmbarcada(event)} class="form-control" type='text' style={{ width: 150 }} />
                                  </div>
                                </li>
                                <li>
                                  <div style={{ marginTop: 10 }}>
                                    <a className="form-labels">% Pré-faturamento</a>
                                    <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaPreFaturamento} onValueChange={(event) => this.handleLogisticaPreFaturamento(event)} class="form-control" type='text' style={{ width: 150 }} />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item xs={12}>
                            <div>
                              <a className="form-labels">Praça de Venda:</a>
                              <ul>
                                <li><input class="radioBtn" type="checkbox" value="socio" name="praca" /> Sudeste</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Centro-Oeste</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Nordeste</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Norte</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Sul</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Todo Território Nacional</li>
                                <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Global</li>
                              </ul>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    :
                    <div />
                  }
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <div className="form-fields">
                  <a className="form-labels">*E-mail do e-cpf</a>
                  <input value={this.props.emailEcpf} onChange={(event) => this.handleEmailECPF(event.target.value)} class="form-control" type='text' />
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Número de Funcionários:</a>
                  <NumberFormat decimalSeparator={','} thousandSeparator={'.'} decimalScale={0} fixedDecimalScale={true} value={this.props.numeroFuncionarios} onChange={(event) => this.handleNumeroFuncionarios(event.target.value)} class="form-control" type='text' maxLength={7} />
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Valor Médio de Títulos:</a>
                  <NumberFormat decimalSeparator={','} thousandSeparator={'.'} decimalScale={2} fixedDecimalScale={true} value={this.state.valorMedioTitulos} onValueChange={(event) => this.handleValorMedioTitulos(event)} class="form-control" type='text' />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <div className="form-fields">
                  <a className="form-labels">*Em Rec. Judicial:</a>
                  <div className="form-options" style={{ width: '100%', marginTop: 10 }}>
                    <ul onChange={this.recuperacaoChange.bind(this)}>
                      <li style={{ float: 'left' }} ><input checked={(this.state.recuperacao == 1 ? true : false)} class="radioBtn" type="radio" value="1" name="recjudicial" /> Sim</li>
                      <li style={{ float: 'left' }} ><input checked={(this.state.recuperacao == 0 ? true : false)} class="radioBtn" type="radio" value="0" name="recjudicial" /> Não</li>
                    </ul>
                  </div>
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Faturamento (Médio Mensal):</a>
                  <NumberFormat decimalSeparator={','} thousandSeparator={'.'} decimalScale={2} fixedDecimalScale={true} value={this.props.faturamento} onChange={(event) => this.handleFaturamento(event.target.value)} class="form-control" type='text'/>
                </div>
                <div className="form-fields">
                  <a className="form-labels">*Prazo Médio:</a>
                  <NumberFormat placeholder={"em dias"} decimalSeparator={','} thousandSeparator={'.'} decimalScale={0} fixedDecimalScale={true} value={this.props.prazoMedio} onChange={(event) => this.handlePrazoMedio(event.target.value)} class="form-control" type='text' maxLength={5} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          {this.state.showingTransporte ?
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ justifyContent: 'center', marginBottom: 20 }}>
                  <img src={img2} alt="car" style={{ width: 60, height: 40, marginRight: 15 }} />
                  <a className="step-title">Dados da Transporte</a>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <a className="form-labels">Mercadorias:</a>
                    <ul>
                      <li><input class="radioBtn" type="radio" value="socio" name="logistica" /> Transportadora</li>
                      <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Carro Próprio</li>
                      <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Autônomo</li>
                      <li><input class="radioBtn" type="radio" value="gerente" name="logistica" /> Sacado Retira</li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <a className="form-labels">Logistica:</a>
                    <ul>
                      <li>
                        <div style={{ marginTop: 10 }}>
                          <a className="form-labels">% Entrega</a>
                          <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaEntrega} onValueChange={(event) => this.handleLogisticaEntrega(event)} class="form-control" type='text' style={{ width: 150 }} />
                        </div>
                      </li>
                      <li>
                        <div style={{ marginTop: 10 }}>
                          <a className="form-labels">% Embarcada</a>
                          <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaEmbarcada} onValueChange={(event) => this.handleLogisticaEmbarcada(event)} class="form-control" type='text' style={{ width: 150 }} />
                        </div>
                      </li>
                      <li>
                        <div style={{ marginTop: 10 }}>
                          <a className="form-labels">% Pré-faturamento</a>
                          <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true} value={this.state.logisticaPreFaturamento} onValueChange={(event) => this.handleLogisticaPreFaturamento(event)} class="form-control" type='text' style={{ width: 150 }} />
                        </div>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <a className="form-labels">Praça de Venda:</a>
                    <ul>
                      <li><input class="radioBtn" type="checkbox" value="socio" name="praca" /> Sudeste</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Centro-Oeste</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Nordeste</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Norte</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Sul</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Todo Território Nacional</li>
                      <li><input class="radioBtn" type="checkbox" value="gerente" name="praca" /> Global</li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <div />
          }
        </Hidden>
      </div>
    )
  }
}

function mapStateToProps(state) {

  console.log(state);

  return {
    nome: state.PreCadastroReducer.nome,
    telefone: state.PreCadastroReducer.telefone,
    email: state.PreCadastroReducer.email,
    celular: state.PreCadastroReducer.celular,

    razaoSocial: state.PreCadastroReducer.razaoSocial,
    cnpj: state.PreCadastroReducer.cnpj,
    telefoneEmpresa: state.PreCadastroReducer.telefoneEmpresa,
    emailEcpf: state.PreCadastroReducer.emailEcpf,
    numeroFuncionarios: state.PreCadastroReducer.numeroFuncionarios,
    valorMedioTitulos: state.PreCadastroReducer.valorMedioTitulos,
    faturamento: state.PreCadastroReducer.faturamento,
    prazoMedio: state.PreCadastroReducer.prazoMedio,

    segmento: state.PreCadastroReducer.segmento,
    recuperacao: state.PreCadastroReducer.recuperacao,
  };
}

export default connect(mapStateToProps, { addStep2 })(Step2);
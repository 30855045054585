import React, { Component } from 'react';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider } from '@material-ui/core';
import MaterialTable from 'material-table';
// react-redux
import { connect } from 'react-redux';
// core-components
import OperationsList from './OperationsList';
import ItensList from './ItensList';

class Operations extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <div style={{display: 'flex'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left" ref="Title" style={{fontFamily: 'lovelo'}}>
              Consultar Operações
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={this.state.loading ? "loadingPaper" : "normalPaper" }>
              <div className="paperWrapper" style={{paddingLeft: "20px", paddingRight: "20px", color: '#32438B'}}>
                <Typography variant="h6" style={{padding: "20px"}}>
                  Borderôs
                </Typography>
                <Divider />
                <OperationsList />
                {this.props.operationList.length > 0 ? 
                <div>
                  <Typography variant="h6" style={{padding: "20px"}}>
                    Lançamentos
                  </Typography>
                  <Divider />
                  <ItensList />
                </div>
                :
                <div />
                }
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,

    operationList: state.OperationsReducer.operationList
  };
}

export default connect(mapStateToProps, {  } )(Operations);
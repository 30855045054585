import React, { Component } from 'react';
import { Container, Col, Row, Button } from "react-bootstrap";
import Header from './components/Header';
import Formulario from './components/Formulario';

import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';

export default class PreCadastro extends Component {

    constructor(props) {
      super(props);
      this.state = {action: '', selectedIndex: 0, values: new Array() };
  
      //this.handleChange = this.handleChange.bind(this);
      //this.handleSubmit = this.handleSubmit.bind(this);
    }

    toStatus() {
      this.setState({action: 'toStatus'});
    }
    

      
    render() {
      if(this.state.action == 'toStatus') {
        return <Redirect to={'/status'} />;
      }

      return (
        <div>
        <Header />
          <Grid container style={{paddingLeft: '10%', paddingRight: '10%', paddingTop: '5%'}}>
            <Grid item xs={12}>
              <div style={{textAlign: 'left', marginTop: 50}}>
                <a className="pre_title" style={{fontFamily: 'Futura'}}>Pré-Cadastro e Informações Comerciais</a>
                <br />
                <div style={{height: '20px'}} />
                <a className="pre_description" style={{fontFamily: 'Futura'}}>Seja um cliente Nobel e acelere sua empresa!</a>
              </div>  
            </Grid>
            <Grid item xs={12}>
              <Formulario toStatus={() => this.toStatus()} />
            </Grid>
          </Grid>
        </div>
      )
  }
}
import React, { Component } from 'react';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Table, TableHead, TableCell, Checkbox, TableRow, TableBody, InputAdornment, FormControlLabel, Radio, RadioGroup, IconButton, Modal, Button } from '@material-ui/core';
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { setFavored } from '../../../../redux/actions/NewOperationActions';
// react-input-mask
import InputMask from "react-input-mask";
import FavoredModal from './FavoredModal';

class favorecidoObject {
  id = '';
  favorecido = '';
  documento = '';
  nro_agencia = '';
  nro_banco = '';
  nro_conta = '';
  percentual = 0;
}

class FavoredList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favorecido: [],
      selected: null,
      openedModal: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.refreshComponent = this.refreshComponent.bind(this);
  }

  getFavorecido() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:FAVORECIDOS',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var favorecidoList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          let favorecidoItem = Object.create({}, favorecidoObject);
          
          favorecidoItem.id = entry.ID;
          favorecidoItem.favorecido = entry.FAVORECIDO;
          favorecidoItem.documento = entry.DOCUMENTO;
          favorecidoItem.nro_agencia = entry.NRO_AGENCIA;
          favorecidoItem.nro_banco = entry.NRO_BANCO;
          favorecidoItem.nro_conta = entry.NRO_CONTA;

          favorecidoList.push(favorecidoItem);
        });

        this.setState({
          favorecido: favorecidoList,
        });
    });
  }

  componentDidMount() {
    this.getFavorecido();
  }

  handleReducer() {
    this.props.setFavored(this.state.selected);
  }  

  componentWillReceiveProps() {
    this.refreshComponent();
  }

  refreshComponent() {
    this.setState({
      selected: null,
      favorecido: []
    });

    this.getFavorecido();
    this.handleReducer();
  }

  handleChange = ev => {
    this.setState({ 
      selected: ev.target.value 
    }, () => {this.handleReducer()});

    this.props.changeUpperTabProp();
  };

  handleOpenModal() {
    this.setState({
      openedModal: true
    })
  }

  handleCloseModal() {
    this.setState({
      openedModal: false
    })
  }

  render() {
    return (
      <div style={{paddingTop: "20px", paddingBottom: "40px"}}>
        <Grid container>
          <Grid item xs={6} style={{ paddingBottom: "20px" }}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" p={0} style={{height: '100%'}}>
              <Box p={0}>
                <Typography variant="h5">
                  Lista de Favorecidos
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} style={{ paddingBottom: "20px" }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" p={0} style={{height: '100%'}}>
              <Box p={0}>
                <IconButton >
                  <AddIcon fontSize="large" color="primary" onClick={this.handleOpenModal} />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <FormControl component="fieldset" style={{width: '100%'}}>
                <RadioGroup onChange={this.handleChange} value={this.state.selected}>
                  <Table style={{ boxShadow: "1px 1px 5px lightGray"}}>
                    <TableHead style={{backgroundColor: "#f5f6fa"}}>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">Favorecido</TableCell>
                        <TableCell align="center">CNPJ</TableCell>
                        <TableCell align="center">Agência</TableCell>
                        <TableCell align="center">Banco</TableCell>
                        <TableCell align="center">Conta</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.favorecido.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            <FormControlLabel
                              value={row.id.toString()}
                              control={<Radio color="primary" checked={(this.state.selected == row.id)}/>}
                            />
                          </TableCell>
                          <TableCell align="center">{row.favorecido}</TableCell>
                          <TableCell align="center">{row.documento}</TableCell>
                          <TableCell align="center">{row.nro_agencia}</TableCell>
                          <TableCell align="center">{row.nro_banco}</TableCell>
                          <TableCell align="center">{row.nro_conta}</TableCell>
                        </TableRow>
                      ))
                      }
                    </TableBody>
                  </Table>
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
          <Modal open={this.state.openedModal} onClose={this.handleCloseModal} className="modalPaper">
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper">
                  <Grid container>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="subtitle1" align="left">
                        Adicionar Favorecido
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleCloseModal}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <FavoredModal closeModal={this.handleCloseModal} refreshComponent={this.refreshComponent} />
                </div>
              </Paper>
            </div>
          </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { setFavored } )(FavoredList);
import React, { Component } from 'react';
import { Container, Col, Row, Button, Modal, Badge } from "react-bootstrap";
import Header from './components/Header';
import Formulario from './components/Formulario';

import { connect } from 'react-redux';

import img_upload from '../images/upload.png';
import problem from '../images/problem.png';

import { Redirect } from "react-router-dom";

import Dropzone from 'react-dropzone'
// aws-s3
import AWS from 'aws-sdk';

import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';

var self = null;

export class Status extends Component {

  constructor(props) {
    super(props);
    self = this;

    this.state = {
      action: '', 
      emAnalise: false, 
      upload: false, 
      selectedIndex: 0, 
      values: new Array(), 
      etapas: new Array(), 
      etapa: '-', 
      status: '-', 
      alteracao: '-', 
      idEtapa: 0, 
      action: '', 
      documentos: [], 
      files: [], 
      showModal: false, 
      loading: true, 
      showSolicitacoes: false, 
      listSolicitacoes: [], 
      showModalSolicitacoes: false
    };

    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /*https://apps.blueprojects.com.br/nobel/Integration/Query

    {
        "qid":"EXEMPLO:ITEM",
        "conditions":[
            {
            "id":"user",
            "values":["admin"]
            }
        ]
    }*/

    if(this.props.credencials == null) {
      this.setState({action: 'toLogin'});
      return;
    }

    var obj = {
      qid : 'PAINEL_ACOMPANHAMENTO:PAINEL_ACOMPANHAMENTO',
      conditions: [{
        filterid: 'USUARIO',
        values: [this.props.credencials.user_id]
      }]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        self.setState({
          etapas: responseJson.list
        });

        if(responseJson.list.length == 0) {
          self.setState({
            emAnalise: true, 
            loading: false
          });
        }

        for(var i = 0; i < responseJson.list.length; i ++) {
          if(responseJson.list[i].COR_DO_PAINEL == 'PINTA DE AZUL') {
            self.setState({
              loading: false, 
              etapa: responseJson.list[i]['Nome Etapa'], 
              status: responseJson.list[i]['Status'], 
              alteracao : responseJson.list[i]['DATA_INICIO'], 
              idEtapa : responseJson.list[i]['ID'] 
            });
            
            self.etapa = responseJson.list[i]['ID'];

            if(responseJson.list[i]['Status'] == 'Inclusão') {
              self.setState({emAnalise: true, loading: false});
              return;
            }
            
            if(responseJson.list[i]['Status'] != 'Reprovado') {
              self.getDocumentos(responseJson.list[i]['ID']);
              self.getSolicitacoes(responseJson.list[i]['ID']);
            }
            
            return;
          }
        } 
    });
  }


      getDocumentos(etapa)
      {

        //this.props.credencials.user_id
        console.log('etapa: ' + etapa);

        var obj = {
            qid : 'DOCUMENTOS:DOCUMENTOS',
            conditions: [
                {
                    filterid: 'USUARIO',
                    values: [this.props.credencials.user_id.toString()]
                },
                {
                    filterid: 'Etapa',
                    values: [etapa.toString()]
                }
            ]
        }

        fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
                }).then((response) => response.json())
                .then((responseJson) => {
    
                  console.log(responseJson);
                  self.setState({documentos: responseJson.list});

                  var need = false;

                  for(var i = 0; i < responseJson.list.length; i++)
                  {
                    if(responseJson.list[i].FLG_CHECK == 0)
                    {
                        need = true;
                        break;
                    }    
                  }

                  if(!need)
                  {
                      self.setState({upload: false});
                  }
                  else
                  {
                      self.setState({upload: true});
                  }

                  
                });
      }


      getSolicitacoes(etapa)
      {

        //this.props.credencials.user_id
        console.log('etapa: ' + etapa);

        var obj = {
            qid : 'SOLICITACOES:SOLICITACAO',
            conditions: [
                {
                    filterid: 'Etapa',
                    values: [etapa.toString()]
                }
            ]
        }

        fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
                }).then((response) => response.json())
                .then((responseJson) => {
    
                  console.log(responseJson);
                  
                  if(responseJson.list.length > 0)
                  {

                      self.setState({showSolicitacoes: true, listSolicitacoes: responseJson.list});
                  }

                  
                });
      }


      updateList()
      {

        this.setState({showModalSolicitacoes: false})

        for(var i = 0; i < this.state.listSolicitacoes.length; i++)
        {
            var obj = {
                tid: 'VF9TT0xJQ0lUQUNPRVM6MTA3Nzcx',
                fid: 124,
                key: {
                    id: this.state.listSolicitacoes[i].ID,
                },
                data: {
                    RESPOSTA: this.state.listSolicitacoes[i].RESPOSTA,
                },
                files: []
              }

            this.updateItem(obj);
        }

        

      }

      updateItem(obj)
      {
        var token = this.props.credencials.auth_ticket;

        fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth' : token
            },
            body: JSON.stringify(obj)
            }).then((response) => response.json())
            .then((responseJson) => {

                console.log(responseJson);
        
              
        });
      }


      saveData(file, table_id)
      {
        var token = this.props.credencials.auth_ticket;

        var obj = {
            tid: 'VF9DTElFTlRFX0VUQVBBX0RPQ1VNRU5UT1M6MjAxNzIz',
            fid: 114,
            data: null,
            files: [file],
            type: 1,
            key: {
                id: table_id
            }
          }

          fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth' : token,
                
            },
            body: JSON.stringify(obj)
            }).then((response) => response.json())
            .then((responseJson) => {

              console.log('saveData', responseJson);
              self.getDocumentos(self.etapa);

              var rr = self.state.documentos;

            for(let i = 0; i < rr.length; i++)
            {
                if(rr[i].ID == table_id)
                {
                    rr[i]['loading'] = false;
                    break;
                }
            }

            self.setState({documentos: rr});
              
            });
      }

      
      uploadFile(file, id) {

          console.log(file);


          var rr = this.state.documentos;

          for(let i = 0; i < rr.length; i++)
          {
              if(rr[i].ID == id)
              {
                  rr[i]['loading'] = true;
                  break;
              }
          }

          this.setState({documentos: rr});
          
          
          if (file) {
            let self = this;
      
            const s3 = new AWS.S3({
              accessKeyId: 'AKIAIM54H7SBTJOZI6DQ',
              secretAccessKey: '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',
              region: 'us-east-2',
            });
      
            s3.upload({
              Bucket: 'blue-temp-files',
              Key: file.name.replace(/ /g, '_'),
              Body: file,
              ACL: 'public-read'
            },function(err, data){
              self.saveData(data.Location, id);

              console.log(data.Location);
              console.log('Successfully uploaded package.');
            });
          }
      }

      formatDate(value)
      {
          try
          {
            var rr = value.split('T');

            if(rr.length > 1)
            {
              return rr[0].split('-')[2] + '/' + rr[0].split('-')[1] + '/' + rr[0].split('-')[0];
            }
            
          }
          catch(err)
          {
              return '';
          }
          
      }

      onLogout()
      {
        sessionStorage.clear();
        this.setState({action: 'toLogin'});
      }

      removeFile(item)
      {
          var id = item.ID_ARQUIVO;

          var obj = {
              id: id
          }

          var token = this.props.credencials.auth_ticket;

            fetch('https://apps.blueprojects.com.br/nobel/Runtime/DeleteFile', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'auth' : token
                },
                body: JSON.stringify(obj)
                }).then((response) => response.json())
                .then((responseJson) => {

                    console.log(responseJson);
                    self.getDocumentos(self.etapa);
                
            });


      }

      
      handleLinkClick = (itemID) => {
        var input = document.getElementsByClassName("inputElement" + itemID);
    
        input[0].click();
      }

  render() {
    if(this.props.credencials == null)
    {
      return <Redirect to={'/'} />;
    }
    if(this.state.action == 'toLogin')
    {
      return <Redirect to={'/'} />;
    }
    return (
      <div>
        <Header onLogout={() => this.onLogout()} showLogout={true} />
        <Modal
          backdrop="static"
          show={this.state.showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.setState({showModal: false})}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Lista de Documentos Pendentes
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{fontSize: 14, fontWeight: '100' }}>
              Arraste os documentos correspondentes para realizar o upload
            </h4>
            <div style={{minHeight: 500 }}>
              <Row>
                {
                  this.state.documentos.map((item, index) => {
                    if(item.FLG_CHECK == 1) {
                      return(
                        <Col key={index.toString()} lg="3">
                          <Dropzone onDrop={acceptedFiles => this.uploadFile(acceptedFiles[0], item.ID)}>
                            {({getRootProps, getInputProps}) => (   
                              <section>
                                <div className='removeFile' onClick={() => {
                                  console.log('remove');
                                  self.removeFile(item);
                                }}>
                                  x
                                </div>
                                <div className="documentItemChecked" {...getRootProps()}>
                                  <img src={img_upload} alt="car" style={{width: 40, height: 30, marginBottom: 10}}/>
                                  <p style={{fontSize: 13, fontWeight: 'bold'  ,color: 'rgb(161, 167, 189)'}}>{item['Documento Necessario']}</p>
                                  <input className={"inputElement" + item.ID} type="file" multiple style={{display: "none"}} onChange={(e) => this.uploadFile(e.target.files[0], item.ID)}/>
                                  <Button color="primary" onClick={() => this.handleLinkClick(item.ID)}>Escolher Arquivo</Button>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </Col>
                      )
                    }
                    else {
                      if(item.loading) {
                        return (
                        <Col key={index.toString()} lg="3">
                          <div className="documentItem">
                            <PulseLoader 
                              size={10}
                              color={'#B6CBC2'}
                              loading={true}
                            />
                          </div>  
                        </Col>
                        )
                      }
                      else {
                        return(
                          <Col key={index.toString()} lg="3"> 
                            <Dropzone onDrop={acceptedFiles => this.uploadFile(acceptedFiles[0], item.ID)}>
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div className="documentItem" {...getRootProps()}>
                                    <img src={img_upload} alt="car" style={{width: 40, height: 30, marginBottom: 10}}/>
                                    <p style={{fontSize: 13, fontWeight: 'bold'  ,color: 'rgb(161, 167, 189)'}}>{item['Documento Necessario']}</p>
                                    <input className={"inputElement" + item.ID} type="file" multiple style={{display: "none"}} onChange={(e) => this.uploadFile(e.target.files[0], item.ID)} />
                                    <Button color="primary" onClick={() => this.handleLinkClick(item.ID)}>Escolher Arquivo</Button>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </Col>
                        )
                      }   
                    }
                  })
                }
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalSolicitacoes}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.setState({showModalSolicitacoes: false})}
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Lista de Solicitações Pendentes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{fontSize: 14, fontWeight: '100' }}>
            Preenchas as solicitações enviadas pelo Banco Nobel
          </h4>
          <div style={{minHeight: 500 }}>
            <Row style={{padding: 20}}>
              {
                this.state.listSolicitacoes.map((item, index) => {
                  return(
                    <div key={index.toString()} style={{width: '100%'}} className="form-fields">
                      <a className="form-labels">{item.SOLICITACAO}</a>
                      <input 
                        onChange={(event) => {
                          var list = self.state.listSolicitacoes;
                          list[index]['RESPOSTA'] = event.target.value;

                          self.setState({listSolicitacoes: list});
                        }} 
                        class="form-control" 
                        type='text' 
                      />
                    </div>
                  )
                })
              }
            </Row>
          </div>
        </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({showModalSolicitacoes: false})} variant="secondary">Fechar</Button>
            <Button onClick={() => this.updateList()} variant="primary">Salvar</Button>
          </Modal.Footer>
        </Modal>

        <Container>
          <div style={{textAlign: 'left', marginTop: 50}}>
            <a className="pre_title">
              Pré-cadastro: Painel de Acompanhamento
            </a>
            <br />
            <a className="pre_description">
              Olá {this.props.credencials.full_name}, seus dados ainda estão em análise. Acompanhe nosso processo por aqui:
            </a>
          </div>
          <div className="outBox2">
            <div className="mainForm2">
              {!! this.state.loading &&
                <div>
                  <PulseLoader         
                    size={10}
                    color={'#2a396a'}
                    loading={true}
                  />
                </div>
              }
              {! this.state.loading &&
                <div>
                  {!! (this.state.status == 'Reprovado') &&
                    <div>
                      <p style={{color: '#314289', fontSize: 17, fontWeight: '600', margin: 30}}>
                        Muito obrigado pelo voto de confiança que você nos deu nesta nossa trajetória de análise cadastral, 
                        no entanto, infelizmente neste momento o seu cadastro não foi aprovado. Existem alguns perfis que ainda não podemos atender, 
                        porém entraremos em contato assim que nos adequarmos para poder te atender da melhor forma possível.
                      </p>
                      <p style={{color: '#314289', fontSize: 17, fontWeight: '600', margin: 30}}>
                        Obrigado novamente pelo interesse em nossos serviços.
                      </p>
                      <p style={{color: '#314289', fontSize: 17, fontWeight: '600', margin: 30}}>
                        Atenciosamente,
                      </p>
                      <p style={{color: '#314289', fontSize: 17, fontWeight: '600', margin: 30}}>
                        Equipe Nobel Bancos.
                      </p>
                    </div>
                  }
                  {! (this.state.status == 'Reprovado') &&
                    <div>         
                      {!! this.state.emAnalise &&
                        <div>
                          <p style={{color: '#314289', fontSize: 28, fontWeight: '600', margin: 30}}>
                            Parabéns! Pré-Cadastro enviado com sucesso. 
                            Você receberá um e-mail com as instruções para continuar
                          </p>
                        </div>
                      }  
                      {! this.state.emAnalise &&
                        <div>
                          <Row>
                            <Col lg="2" style={{padding: 0}}>
                              <p style={{textAlign: 'left', marginBottom: 2, fontWeight: 'bold', color: '#808495' }}>
                                Etapa
                              </p>
                              <p style={{textAlign: 'left', color: '#808495'}}>
                                {this.state.etapa}
                              </p>
                            </Col>
                            <Col lg="2" style={{padding: 0}}>
                              <p style={{textAlign: 'left', marginBottom: 2, fontWeight: 'bold', color: '#808495'}}>
                                Status
                              </p>
                              <p style={{textAlign: 'left', color: '#808495'}}>
                                {this.state.status}
                              </p>
                            </Col>
                            <Col lg="6" style={{padding: 0}}>
                            </Col>
                            <Col lg="2" style={{padding: 0, paddingRight: 15}}>
                              <p style={{textAlign: 'left', marginBottom: 2, fontWeight: 'bold', color: '#808495'}}>
                                Ultima Alteração
                              </p>
                              <p style={{textAlign: 'left', color: '#808495'}}>
                                {this.formatDate(this.state.alteracao)}
                              </p>
                            </Col>
                          </Row>
                          {/*<Row>
                              { !! this.state.upload &&
                                  <Col style={{textAlign: 'right', borderTopWidth: 1, borderTopColor: 'rgb(220, 220, 220)', borderTopStyle: 'solid', paddingTop: 30}}>
                                      <Button onClick={() => this.setState({showModal: true })} className="btnUpload" variant="success">Upload de Arquivos</Button>
                                  </Col>
                              }
                              
                          </Row>*/}
                          <Row style={{marginTop: 30}}>
                            {
                              this.state.etapas.map((item, index) => {
                                if(item['COR_DO_PAINEL'] == 'DEIXA BRANCO') {
                                  return (
                                    <Col key={index.toString()}  style={{padding: 0}}>
                                      <div className={'lineBox'}>
                                        <div className={'linePointer'}></div>
                                      </div>
                                    </Col>
                                  )
                                }
                                else
                                {
                                  return (
                                    <Col key={index.toString()}  style={{padding: 0}}>
                                      <div className={'lineBox'}>
                                        <div className={'linePointerFull'}></div>
                                      </div>
                                    </Col>
                                  )
                                }
                              })
                            }
                          </Row>
                          <Row>
                            {
                              this.state.etapas.map((item, index) => {
                                if(item['COR_DO_PAINEL'] == 'DEIXA BRANCO') {
                                  return (
                                    <Col style={{padding: 0}} key={index.toString()}>
                                      <div className={'arrow_box2'}>
                                        <div style={{width: '100%', padding: 10, minHeight: 200}}>
                                          <p style={{textAlign: 'left', color: '#808495', fontWeight: 'bold' }}>
                                            {item['Nome Etapa']}
                                          </p>
                                          <p style={{textAlign: 'left', color: '#808495', fontSize: 13 }}>
                                            {item['Detalhe da Etapa']}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                }
                                else {
                                  return (
                                    <Col  style={{padding: 0}} key={index.toString()}>
                                      <div className={'arrow_box'}>
                                        <div style={{width: '100%', padding: 10, minHeight: 200}}>
                                          <p style={{textAlign: 'left', color: '#FFF', fontWeight: 'bold'}}>{item['Nome Etapa']}</p>
                                          {!! (this.state.showSolicitacoes || this.state.upload) &&
                                            <img src={problem} alt="car" style={{width: 20, height: 20, position: 'absolute', right: 10, top: 10}}/>
                                          }
                                          <p style={{textAlign: 'left', color: '#FFF', fontSize: 13 }}>
                                            {item['Detalhe da Etapa']}
                                          </p>
                                          <div className="divSolicitacoes">
                                            {!! this.state.showSolicitacoes &&
                                              <Button className="btn-solicitacoes" onClick={() => self.setState({showModalSolicitacoes: true})} style={{width: '100%', marginTop: 5, marginBottom: 5}}>Solicitacoes
                                                <Badge style={{float: 'right', marginTop: 4}} variant="light">
                                                  {this.state.listSolicitacoes.length}
                                                </Badge>
                                              </Button>
                                            }
                                            { !! this.state.upload &&
                                              <Button onClick={() => this.setState({showModal: true })} className="btn-solicitacoes" variant="success" style={{width: '100%', marginTop: 5, marginBottom: 5}}>Arquivos
                                                <Badge style={{float: 'right', marginTop: 4}} variant="light">
                                                  {this.state.documentos.length}
                                                </Badge>
                                              </Button>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                }
                              })
                            }
                          </Row>
                          <Row>
                            <Col>
                              <p style={{marginTop: 40, fontSize: 13, color: '#1D8FEB'}}>
                                Se você chegou nesta tela mas não recebeu nenhum e-mail, entre em contato: atendimento@nobelbanco.com.br.
                              </p>
                            </Col>
                          </Row>  
                        </div>
                      }
                    </div>
                  }
                </div>
              }            
            </div>          
          </div>    
        </Container>
      </div>
    )
  }
}


function mapStateToProps(state){
  return{
    credencials: state.PreCadastroReducer.credencials,
  };
}
  
export default connect(mapStateToProps, {  } )(Status);
import React, { Component } from "react";
import { ProgressBar, Row, Col, Button } from "react-bootstrap";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { addUser, addCreated } from "../../redux/actions/PreCadastroActions";

import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { PulseLoader } from "react-spinners";

var self = null;

export class Formulario extends Component {
  constructor(props) {
    super(props);

    toast.configure();

    self = this;

    this.state = {
      step: 0,
      progressValue: 33.33,
      variant: "none",
      showBack: false,
      loading: false,
      emailList: [],
    };
  }

  doLogin(user, password) {
    var obj = {
      username: user,
      password: password,
    };

    fetch("https://apps.blueprojects.com.br/nobel/Security/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if (responseJson.data == null) {
          toast.error(responseJson.error.message);
          console.log("ERRO: ", responseJson);
        } else {
          responseJson.data["expiration"] = new Date();

          sessionStorage.setItem(
            "credentials",
            JSON.stringify(responseJson.data)
          );

          self.props.addUser(responseJson.data);
        }
      });
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;

      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  createUser() {
    this.setState({ loading: true });

    var obj = {
      login: this.props.email,
      email: this.props.email,
      full_name: this.props.nome,
      password: this.props.senha,
    };

    fetch("https://apps.blueprojects.com.br/nobel/Security/CreateUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("createUser", responseJson);

        if (responseJson.data != null) {
          if (responseJson.data.success) {
            self.props.addCreated(responseJson.data.usr.id);
            self.saveData(responseJson.data.usr.id);
            self.addEmail();
            self.doLogin(this.props.email, this.props.senha);
          }
        } else {
          if (responseJson.error.message == "Email already exists") {
            toast.error("Usuário já existe na base");
            self.setState({ loading: false });
          } else {
            toast.error(responseJson.error.message);
            console.log("ERRO: ", responseJson);
            self.setState({ loading: false });
          }
        }
      });
  }

  saveData(user_id) {
    var files = new Array();

    if (this.props.fileFaturamento != "") {
      files.push(this.props.fileFaturamento);
    }

    if (this.props.fileEndividamento != "") {
      files.push(this.props.fileEndividamento);
    }

    var valorMedio = this.props.valorMedioTitulos.toString();
    var faturamento = this.props.faturamento.toString();

    var obj = {
      tid: "VF9DTElFTlRFOjA2OTU3NQ==",
      fid: 107,
      data: {
        NOME: this.props.nome,
        CELULAR: this.props.celular,
        EMAIL: this.props.email,
        TELEFONE: this.props.telefone,
        FK_CARGO: this.props.relacaoEmpresa,

        RAZAO_SOCIAL: this.props.razaoSocial,
        EMAIL_CPF: this.props.emailEcpf,
        CNPJ: this.props.cnpj,
        TELEFONE_EMPRESA: this.props.telefoneEmpresa,
        NRO_FUNCIONARIOS: this.props.numeroFuncionarios,
        VALOR_MEDIO_TITULOS: valorMedio.replace(".", "").replace(",", "."),
        FATURAMENTO_MEDIO_MENSAL: faturamento
          .replace(".", "")
          .replace(",", "."),
        PRAZO_MEDIO_DIAS: this.props.prazoMedio,
        DIAS_TRANSPORTE: this.props.prazoMedio,
        FK_SEGMENTO: this.props.segmento,
        FLG_RECUPERACAO_JUDICIAL: this.props.recuperacao,

        BANCO_PARCEIRO: this.props.banco,
        FUNDO_PARCEIRO: this.props.fundo,
        SECURITIZADORA_PARCEIRA: this.props.securitizadora,
        PERC_DUPLICATAS: this.props.duplicatas,
        PERC_CHEQUES: this.props.cheques,
        PERC_ESCROW: this.props.escrow,
        //PERC_OUTROS: this.props.outros,
        OBS_RESTRICAO: this.props.restricoes,
        OBS_OUTRAS_OPERACOES: this.props.outrasOperacoes,

        PERC_EMBARCADA: this.props.percEmbarcado,
        PERC_ENTREGA: this.props.percEntrega,
        PERC_PRE_FATURAMENTO: this.props.percPreFaturamento,

        FK_USUARIO: user_id,
      },
      files: files,
    };

    fetch("https://apps.blueprojects.com.br/nobel/Integration/Save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        self.setState({
          loading: false,
        });

        if (responseJson.error != null) {
          toast.error(responseJson.error.message);
          return;
        } else {
          var st = self.state.step;
          st = st + 1;

          var progressValue = (st + 1) * 33.33;

          self.generateScript(responseJson.data.result.id);

          self.setState({
            step: st,
            progressValue: progressValue,
          });
        }
      });
  }

  addEmail() {
    var obj = {
      tid: "VF9FTUFJTF9DQURBU1RSTzoxMjI3ODg=",
      fid: 155,
      data: {
        MAIL_TO: this.props.email,
        // MAIL_FROM: 'Teste',
        SUBJECT: "Teste",
        BODY: "Teste " + this.props.nome,
      },
    };

    fetch("https://apps.blueprojects.com.br/nobel/Integration/Save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
      });
  }

  generateScript(clientID) {
    var celular = "";

    if (this.props.celular != null) {
      celular = this.props.celular.replace(/ /g, "");
    }

    let script =
      `
      IF NOT EXISTS (SELECT * FROM [SECURIT]..sigcad WHERE cgc = '` + this.props.cnpj +`')
      BEGIN
      INSERT INTO [SECURIT]..sigcad (codigo, nome, cgc, tipo, registro, tipopessoa, raizcnpj, celular, email, compl)
        SELECT MAX(codigo) + 1 AS codigo,'` + this.props.nome + `', '` + this.props.cnpj + `',
               'Cedente', GETDATE(), 'J', '` + this.props.cnpj.substring(0, 10) + `', 
               '` + celular + `', '` + this.props.email + `','' 
        FROM [SECURIT]..sigcad
      END
      `;

    var obj = {
      tid: "VF9DTElFTlRFX1NDUklQVDoxMjU5MTQ=",
      fid: 150,
      data: {
        FK_CLIENTE: clientID,
        SCRIPT: script,
      },
    };

    fetch("https://apps.blueprojects.com.br/nobel/Integration/Save", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("generateScript", responseJson);
      });
  }

  next() {
    var st = this.state.step;
    st = st + 1;

    if (st == 1) {
      console.log(this.props.senha);

      if (this.props.nome == "") {
        toast.error("Preencha o campo nome!!");
        return;
      }

      if (this.props.email == "") {
        toast.error("Preencha o campo e-mail!!");
        return;
      } else {
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            this.props.email
          )
        ) {
          toast.error("O campo de e-mail está invalido!");
          return;
        }
      }

      if (this.state.emailList.includes(this.props.email)) {
        toast.error("Já existe uma conta cadastrada com este Email");
        return;
      }

      if (this.props.senha == null || this.props.senha == "") {
        toast.error("Preencha o campo de senha!!");
        return;
      }
      
      if (this.props.senha.length < 8) {
        toast.error("A senha não pode ter menos de 8 caracteres");
        return;
      }

      var hasNumber = /\d/;

      if (hasNumber.test(this.props.senha) == false) {
        toast.error("A senha deve conter ao menos um número");
        return;
      }

      var hasLetters = /.*[a-zA-Z]+.*/;

      if (hasLetters.test(this.props.senha) == false) {
        toast.error("A senha deve conter ao menos uma letra");
        return;
      }

      if (this.props.confirmacaoSenha == "") {
        toast.error("Preencha o campo confirmação de senha");
        return;
      }

      if (this.props.senha != this.props.confirmacaoSenha) {
        toast.error("Confirmação de Senha não confere!!");
        return;
      }

      if (this.props.relacaoEmpresa == "") {
        toast.error("Preencha o campo Relação com a Empresa!!");
        return;
      }

      if (this.props.celular == "") {
        toast.error("Preencha o campo celular!!");
        return;
      }
    }

    if (st == 2) {
      if (this.props.razaoSocial == "" || this.props.razaoSocial == null) {
        toast.error("Preencha o campo Razão Social!!");
        return;
      }

      if (this.props.cnpj == "" || this.props.cnpj == null) {
        toast.error("Preencha o campo CNPJ!!");
        return;
      } else {
        if (!this.validateCNPJ(this.props.cnpj)) {
          toast.error("CNPJ Invalido.");
          return;
        }
      }

      if (
        this.props.telefoneEmpresa == "" ||
        this.props.telefoneEmpresa == null
      ) {
        toast.error("Preencha o campo Telefone!!");
        return;
      }

      if (this.props.segmento == null || this.props.segmento == "") {
        toast.error("Preencha o segmento");
        return;
      }

      if (this.props.emailEcpf == "" || this.props.emailEcpf == null) {
        toast.error("Preencha o campo E-mail e-cpf!!");
        return;
      } else {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.props.emailEcpf)
        ) {
          toast.error("O campo de e-mail está invalido!");
          return;
        }
      }

      if (this.props.numeroFuncionarios == "" || this.props.numeroFuncionarios == null) {
        toast.error("Preencha o campo Relação com a Número Funcionários!!");
        return;
      }

      if (this.props.valorMedioTitulos == "" || this.props.valorMedioTitulos == null) {
        toast.error("Preencha o campo de Valor Médio Títulos!!");
        return;
      }

      if (this.props.recuperacao == null || this.props.recuperacao == '') {
        toast.error("Preencha o campo de recuperação judicial");
        return;
      }

      if (this.props.faturamento == "" || this.props.faturamento == null) {
        toast.error("Preencha o campo de Faturamento!!");
        return;
      }

      if (this.props.prazoMedio == "" || this.props.prazoMedio == null) {
        toast.error("Preencha o campo Prazo Médio!!");
        return;
      }
    }

    if (st == 3) {
      if ((this.props.fundo == null || this.props.fundo == '') &&
          (this.props.banco == null || this.props.banco == '') &&
          (this.props.securitizadora == null || this.props.securitizadora == '')) {
        toast.error("Pelo menos um parceiro deve ser preenchido");
        return;
      }

      if (this.props.duplicatas == null || this.props.duplicatas == '') {
        toast.error("A porcentagem das duplicatas deve ser preenchido");
        return;
      }

      if (this.props.cheques == null || this.props.cheques == '') {
        toast.error("A porcentagem dos cheques deve ser preenchido");
        return;
      }
    }

    if (st == 2 || st == 1) {
      var progressValue = (st + 1) * 33.33;

      this.setState({ step: st, progressValue: progressValue });
    } else {
      if (st == 3) {
        if (this.props.userCreated == 0 || this.props.userCreated == null) {
          this.createUser();
        } else {
          this.saveData(this.props.userCreated);
        }
      }
    }
  }

  componentDidMount() {
    this.getEmails();
  }

  getEmails() {
    var obj = {
      qid: "PAGINA_DASHBOARD:CLIENTES",
    };

    let emailList = [];

    fetch("https://apps.blueprojects.com.br/nobel/Integration/Query", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.list.map((entry) => {
          emailList.push(entry.EMAIL);
        });

        this.setState({
          emailList: emailList,
        });
      });
  }

  back() {
    var st = this.state.step;
    st = st - 1;

    var progressValue = (st + 1) * 33.33;

    this.setState({ step: st, progressValue: progressValue });
  }

  renderStep() {
    switch (this.state.step) {
      case 0:
        if (this.state.variant != "none") this.setState({ variant: "none" });
        return <Step1 handleBack={() => this.setState({ showBack: false })} />;
      case 1:
        if (this.state.variant != "none") this.setState({ variant: "none" });
        return <Step2 handleBack={() => this.setState({ showBack: true })} />;
      case 2:
        if (this.state.variant != "none") this.setState({ variant: "none" });
        return (
          <Step3
            loading={this.state.loading}
            handleBack={() => this.setState({ showBack: true })}
          />
        );
      case 3:
        if (this.state.variant != "success")
          this.setState({ variant: "success" });
        return (
          <Step4
            toStatus={() => this.props.toStatus()}
            handleBack={() => this.setState({ showBack: false })}
          />
        );
    }
  }

  render() {
    return (
      <div className="outBox">
        <div className="mainForm">
          <div style={{ marginBottom: 20 }}>
            <ProgressBar
              striped
              variant={this.state.variant}
              now={this.state.progressValue}
            />
          </div>
          {this.renderStep()}
        </div>
        <div className="form-footer">
          <div>
            <div style={{ float: "left", padding: 10 }}>
              <PulseLoader
                size={10}
                color={"#ced3e5"}
                loading={this.state.loading}
              />
            </div>
            {!!this.state.showBack && (
              <Button
                onClick={() => this.back()}
                variant="secondary"
                style={{ borderRadius: 0 }}
              >
                Voltar
              </Button>
            )}
            <Button
              onClick={() => this.next()}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 10,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              Próximo
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    nome: state.PreCadastroReducer.nome,
    telefone: state.PreCadastroReducer.telefone,
    email: state.PreCadastroReducer.email,
    celular: state.PreCadastroReducer.celular,
    relacaoEmpresa: state.PreCadastroReducer.relacaoEmpresa,
    senha: state.PreCadastroReducer.senha,
    confirmacaoSenha: state.PreCadastroReducer.confirmacaoSenha,

    razaoSocial: state.PreCadastroReducer.razaoSocial,
    cnpj: state.PreCadastroReducer.cnpj,
    telefoneEmpresa: state.PreCadastroReducer.telefoneEmpresa,
    emailEcpf: state.PreCadastroReducer.emailEcpf,
    numeroFuncionarios: state.PreCadastroReducer.numeroFuncionarios,
    valorMedioTitulos: state.PreCadastroReducer.valorMedioTitulos,
    faturamento: state.PreCadastroReducer.faturamento,
    prazoMedio: state.PreCadastroReducer.prazoMedio,
    segmento: state.PreCadastroReducer.segmento,
    recuperacao: state.PreCadastroReducer.recuperacao,

    fundo: state.PreCadastroReducer.fundo,
    banco: state.PreCadastroReducer.banco,
    securitizadora: state.PreCadastroReducer.securitizadora,

    duplicatas: state.PreCadastroReducer.duplicatas,
    cheques: state.PreCadastroReducer.cheques,
    escrow: state.PreCadastroReducer.escrow,

    outrasOperacoes: state.PreCadastroReducer.outrasOperacoes,
    restricoes: state.PreCadastroReducer.restricoes,

    fileFaturamento: state.PreCadastroReducer.fileFaturamento,
    fileEndividamento: state.PreCadastroReducer.fileEndividamento,

    userCreated: state.PreCadastroReducer.userCreated,
  };
}

export default connect(mapStateToProps, { addUser, addCreated })(Formulario);

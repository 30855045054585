import { SET_INFORMATION, SET_DUPLICATE, SET_BANKCHECK, SET_FAVORED, SET_FILE_LIST, SET_ITEM_LIST, SET_DRAWN_LIST } from '../constants/NewOperationConstants';

const INITIAL_STATE = {
  carteiraID: null,
  favored: null,

  documentoCheque: null,
  nomeCheque: null,
  valorCheque: null,
  vencimentoCheque: null,
  emissaoCheque: null,
  nroDocumentoCheque: null,
  
  codigoWBACheque: null,

  documentoDuplicata: null,
  nomeDuplicata: null,
  valorDuplicata: null,
  vencimentoDuplicata: null,
  emissaoDuplicata: null,
  nroDocumentoDuplicata: null,
  tipoDuplicata: null,

  desconto: null,
  descontoPorCento: null,

  codigoWBADuplicata: null,

  fileList: [],
  itemList: [],
  drawnList: [],
}

export default (state = INITIAL_STATE, action) => {

  console.log(action.type);
  switch(action.type) {
    case SET_INFORMATION:
      console.log(action.data);
      return {
        ...state,
        carteiraID: action.data
      }
    case SET_DUPLICATE:
      console.log(action.data);
      return {
        ...state,
        documentoDuplicata: action.data.documentoDuplicata,
        nomeDuplicata: action.data.nomeDuplicata,
        valorDuplicata: action.data.valorDuplicata,
        vencimentoDuplicata: action.data.vencimentoDuplicata,
        emissaoDuplicata: action.data.emissaoDuplicata,
        nroDocumentoDuplicata: action.data.nroDocumentoDuplicata,
        tipoDuplicata: action.data.tipoDuplicata,
        desconto: action.data.desconto,
        descontoPorCento: action.data.descontoPorCento,
        codigoWBADuplicata: action.data.codigoWBA,
        logradouroDuplicata: action.data.logradouroDuplicata,
        numeroDuplicata: action.data.numeroDuplicata,
        bairroDuplicata: action.data.bairroDuplicata,
        municipioDuplicata: action.data.municipioDuplicata,
        ufDuplicata: action.data.ufDuplicata,
      }
    case SET_BANKCHECK:
      console.log(action.data);
      return {
        ...state,
        documentoCheque: action.data.documentoCheque,
        nomeCheque: action.data.nomeCheque,
        valorCheque: action.data.valorCheque,
        vencimentoCheque: action.data.vencimentoCheque,
        emissaoCheque: action.data.emissaoCheque,
        nroDocumentoCheque: action.data.nroDocumentoCheque,
        codigoWBACheque: action.data.codigoWBA,
        logradouroCheque: action.data.logradouroCheque,
        numeroCheque: action.data.numeroCheque,
        bairroCheque: action.data.bairroCheque,
        municipioCheque: action.data.municipioCheque,
        ufCheque: action.data.ufCheque,
      }
    case SET_FAVORED:
      console.log(action.data);
      return {
        ...state,
        favored: action.data
      }
    case SET_FILE_LIST:
      console.log(action.data);
      return {
        ...state,
        fileList: action.data
      }
    case SET_ITEM_LIST:
      console.log(action.data);
      return {
        ...state,
        itemList: action.data
      }
    case SET_DRAWN_LIST:
      console.log(action.data);
      return {
        ...state,
        drawnList: action.data
      }
  }

  return state;
}
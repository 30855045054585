import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment } from '@material-ui/core';
// @material-ui/icons
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { updateSelectedList } from '../../../../redux/actions/OperationsActions'

class BoletoObject {
  vencimento = null;
  valor = 0;
  nroParcelos = 0;
  link = '';
}

class NewOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      boletoList: []
    }
  }

  getBoletos() {
    var boletoList = [];
    var count = 0;

    this.props.selectedList.map((item, index) => {
      var obj = {
        qid : 'PAGINA_DASHBOARD:BOLETOS',
        conditions: [
          {
            filterid: 'REMESSA_ITENS',
            values: [item]
          }
        ]
      }
      
      fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj)
        }).then((response) => response.json()).then((responseJson) => {
          responseJson.list.map((entry, index) => {
            let boleto = Object.create({}, BoletoObject);

            boleto.vencimento = entry.DATA_VENCIMENTO;
            boleto.valor = entry.VALOR;
            boleto.nroParcelos = entry.NRO_PARCELAS;
            boleto.link = entry.URL_BOLETO;
  
            boletoList.push(boleto);
          });
      
        count = count + 1;

        if (count == this.props.selectedList.length){
          this.setState({
            boletoList: boletoList,
            loading: false,
          }, () => {this.handleReducer()});
        }
      });
    })
  }

  handleReducer() {
    this.props.updateSelectedList(this.state.boletoList);
  }

  componentDidMount(){
    this.getBoletos();
  }
  
  formatDate(date) {
    if (date != null){
      var _date = date.split('T')[0].split('-');
    
      return _date[2] + '/' + _date[1] + '/' + _date[0];
    }
    else
      return null
  }

  formatMoney(number) {
    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }

  render() {
    var loadingIcon;

    if(this.state.loading == true){
      loadingIcon = <CircularProgress /> 
    }
    else {
      loadingIcon = <div style={{color: 'gray'}}>
                      <Typography variant="body2">
                        Esse item não possui boletos.
                      </Typography>
                    </div>
    }

    return (
      <div>
        {this.state.boletoList.length == 0 ? 
          <div>
            <Grid container justify="center" alignItems="center" xs={12} style={{height: '300px', width: '400px'}}>
              <Grid item>
                { loadingIcon }
              </Grid>
            </Grid>
          </div>
        : 
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={{paddingBottom: "20px", paddingTop: "20px"}}>
                <Paper style={{ boxShadow: "1px 1px 5px lightGray"}}>
                  <Table>
                    <TableHead style={{backgroundColor: "#f5f6fa"}}>
                      <TableRow>
                        <TableCell align="center">Vencimento</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Nro. Parcelas</TableCell>
                        <TableCell align="center">Boleto</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.boletoList.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{this.formatDate(row.vencimento)}</TableCell>
                          <TableCell align="center">{this.formatMoney(row.valor)}</TableCell>
                          <TableCell align="center">{row.nroParcelos}</TableCell>
                          <TableCell align="center"><a href={row.link} target="_blank"><LinkIcon /></a></TableCell>
                        </TableRow>
                      ))
                      }
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </Grid>
          </Grid>
        }
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials
  };
}

export default connect(mapStateToProps, { updateSelectedList } )(NewOperation);
import React, { Component } from 'react';
import '../Style.css';
import { forwardRef } from 'react';
import ReactDOMServer from "react-dom/server";
import '../Style.css';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, Modal, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Select, MenuItem, FormControl, InputAdornment, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
// react-redux
import { connect } from 'react-redux';
import { updateOperationsList } from '../../../../redux/actions/OperationsActions';
// @material-ui/icons
import GetAppIcon from '@material-ui/icons/GetApp';
// react-toastify
import { toast } from 'react-toastify';
// import Wijmo styles and components
import 'wijmo/styles/wijmo.css';
import { CollectionView } from 'wijmo/wijmo';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { FlexChart, FlexChartSeries } from 'wijmo/wijmo.react.chart';
import * as wjcCore from "wijmo/wijmo";

// apply Wijmo license key
import { setLicenseKey } from 'wijmo/wijmo';
import InputMask from "react-input-mask";
import { setDrawnList } from '../../../../redux/actions/NewOperationActions'
import ReactExport from "react-export-excel";
import { getCulture } from '../Culture';

setLicenseKey(
  "portal.nobelbanco.com.br|nobelbanco.com.br,456644433637969#B0ijOklkIs4nIxYHOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZpl6bCR7QBtyN0NWcHhGSClkVapWdNNGOlhzaslFRrs6LFZjWrtyZwpFRr56aUl6SulkNLR7KqpHTUJ7dFZzV9I5RulUO7JXMndUVlREWZ3EbYBjdiJ5bktUc74UM7BHMoNmUyIVMtR5bxYnRRFGdER5N5o7QLNET7MjcoNGbEV5d4kXdBVDZYJHMX3kNytWO5oGTttkaxgkTYRGTQhmV7QkZDdmaTZlbXl6U6g6bzFjTjNGVHZTa6Ekdi3mNWBFMUdkTXZWWrE7aRV4TkZTa6N5cudEZn3CeOxEMG94K8kjZ73EN6EDTZJmYiJzLOhkaLhTc9N5TPBnYw8We7xWM5YWR8l5Ukh4aw54YN5GVjV7KRBHawYWTvcUcyIXevtWWEpke4cUZIVEb05EV7YjaBh4KillcHdlcF94aGp5cjllRDtkVsxWQRBlewdGTDlVRwYzb6QmI0IyUiwiIyY4QBVUOEZjI0ICSiwyMzQzMxMDO4MTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzQDOxcDMgUDM5ATMyAjMiojI4J7QiwiIyJmLt36Yu26YuFmYsVmYv9GLyJmLt36Yu26YuFmYsVmYv9mLsFGdy3GciojIz5GRiwiIhRGdMBSYjlGdhOcby3mZulEItVEIhl6Zvx6buNWZUBSZgEWay3GdsV7cu36QgQmbp5mblB7TiojIh94QiwiI9YTO7MjNzMDN4QjN6nDui"  
);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class operation {
  dataReferencia = null;
  carteira = '';
  nomeCedente = '';
  banco = '';
  quantidadeOcorrencias = 0;
  link = '';
  button = '';
};

class carteiraObject {
  id = 0;
  carteira = '';
  fkCarteira = 0;
};

class ContaGrafica extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operationList: [],
      flexGrid: null,
      openDownloadDialog: false,

      dataInicial: null,
      dataFinal: null,
      carteira: [],
      carteiraNome: 0,

      loading: false,
    }
  }

  getCarteira() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:CARTEIRA',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var carteiraList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log("responseJsonCARTEIRA", responseJson)
        
        responseJson.list.map((entry, index) => {
          let carteiraItem = Object.create({}, carteiraObject);
          
          carteiraItem.id = entry.ID;
          carteiraItem.fkCarteira = entry.FK_CARTEIRA;
          carteiraItem.carteira = entry.CARTEIRA;
        
          carteiraList.push(carteiraItem);
        });

        this.setState({
          carteira: carteiraList,
        }, () => {
          if (this.state.carteira.length > 0){
            this.setState({
              carteiraNome: this.state.carteira[0].carteira
            });
          }
          else
            toast.warn('Você não possui carteiras cadastradas, por favor, entre em contato com o suporte.');
        });
    });
  }

  updateOperationList(){
    this.setState({
      loading: true,
    })

    var obj = {
      qid : 'PAGINA_DASHBOARD:ARQUIVO_REMOTO',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var operationList = [];
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          let operationObject = Object.create({}, operation);
          
          if (entry.Link == null)
            operationObject.link = 'null' 
          else
            operationObject.link = entry.Link;
          operationObject.carteira = entry.Carteira;
          operationObject.nomeCedente = entry.NomeCedente;
          operationObject.banco = entry.Banco;
          operationObject.quantidadeOcorrencias = entry.QuantidadeOcorrencias;
          
          var _dataReferencia = null;
          
          if (entry.DataReferencia != null)
            _dataReferencia = new Date(entry.DataReferencia.substring(0, 10).replace('-', '/')).toLocaleDateString();

          operationObject.dataReferencia = _dataReferencia;

          operationObject.button = 'Button';

          operationList.push(operationObject);
        })
        
        console.log('updateOperationList', responseJson);

        this.setState({
          operationList: operationList,
          loading: false,
        });
      });
  }

  handleCarteiraChange = (event) => {
    this.setState({
      carteiraNome: event.target.value,
    });
  }
  
  componentDidMount() {
    this.updateOperationList();
    this.getCarteira();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeTab)
      this.updateOperationList();
  }

  initializeGrid(flex) {
    this.flex = flex;
    let self = this;

    flex.formatItem.addHandler((s, e) => {
      if (e.panel == s.columnHeaders) {
        if (s.columns[e.col].binding == 'sel') {
          e.cell.innerHTML = '<input class="select-all" tabindex="-1" type="checkbox">';
          this._updateSelectAllBox(s);
        }
      }
      else if (e.panel == s.cells) {
        wjcCore.setAttribute(e.cell.parentElement, 'aria-selected', s.rows[e.row].dataItem.sel);
      }
    });
    
    // select/de-select all items when user clicks the check box on the header
    flex.hostElement.addEventListener('click', (e) => {
      if (wjcCore.hasClass(e.target, 'select-all') && e.target instanceof HTMLInputElement) {
        flex.deferUpdate(() => {
          flex.collectionView.items.forEach((item) => {
            item.sel = (e.target).checked;
          });
        });
      }
    });

    flex.itemFormatter = function(panel, r, c, cell) {
      var col = panel.columns[c];

      if (col.name == 'link' && panel.cellType == 1) {
        col.width = 2
      }

      if (col.name == 'button' && panel.cellType == 1) {

        col.align = 'center';
        col.width = 125
        
        if (self.state.flexGrid.cells.getCellElement(r, 0).childNodes[0].data != 'null')
          cell.onclick = () => window.open(self.state.flexGrid.cells.getCellElement(r, 0).childNodes[0].data); 
        
        cell.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <Button 
            color='primary' 
            disabled={self.state.flexGrid.cells.getCellElement(r, 0).childNodes[0].data == null}
          >
            <GetAppIcon/>
          </Button>
        );
      }

      if (col.name == 'dataReferencia') {
        col.header = 'Data Referência';
      }

      if (col.name == 'quantidadeOcorrencias') {
        col.header = 'Quantidade Ocorrências';
      }
    }
    
    wjcCore.culture = getCulture();
    
    this.setState({
      flexGrid: flex
    });
  }

  _updateSelectAllBox(grid) {
    let cb = grid.hostElement.querySelector('.select-all');

    if (cb instanceof HTMLInputElement) {
      let items = grid.collectionView.items, last = null, indeterminate = false;
      
      for (let i = 0; i < items.length; i++) {
        if (last != null && items[i].sel != last) {
          indeterminate = true;
          break;
        }
        last = items[i].sel;
      }
      
      if (indeterminate) {
        cb.checked = true;
        cb.indeterminate = true;
      }
      else {
        cb.checked = last;
      }

      this.handleSelectedOperations();
    }
  }

  handleSelectedOperations() {
    let SelectedList = [];
    let self = this;

    setTimeout(function(){
      for (var i = 0; i < self.state.flexGrid.cells.rows.length; i++){
        if (self.state.flexGrid.cells.rows[i]._data.sel){
          SelectedList.push(self.state.flexGrid.cells.rows[i]._data);
        }
      }
  
      self.setState({
        selectedList: SelectedList
      })
    }, 250) // gambiarra
  }

  handleDataInicialChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      dataInicial: event.target.value,
    }, () => {this.initCustomFilterFun()});
  }

  handleDataFinalChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      dataFinal: event.target.value,
    }, () => {this.initCustomFilterFun()});
  }

  filterNome(e) {
    let filter = e.target.value.toLowerCase();
    
    this.flex.collectionView.filter = item => {
      return (filter.length == 0 || item.NomeCedente.toLowerCase().indexOf(filter) > -1);
    };
  }

  filterData(initialDate, finalDate) {
    let filterInitialDateText;
    let filterFinalDateText;

    if (initialDate != null)
      filterInitialDateText = initialDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

    if (finalDate != null)
      filterFinalDateText = finalDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

    let filterInitialDate = new Date(filterInitialDateText);
    let filterFinalDate = new Date(filterFinalDateText);

    this.flex.collectionView.filter = item => {
      let date = new Date(item.Vencimento)
      
      return ((date >= filterInitialDate && (finalDate == null || finalDate == '')) || 
              (date <= filterFinalDate && (initialDate == null || initialDate == '')) || 
              (date >= filterInitialDate && date <= filterFinalDate) ||
              (initialDate == '' && finalDate == ''))
    };
  }

  initCustomFilterFun() {
    let filterFun = (item) => {
      let filCols = this.filter.filterColumns;
      
      if(!filCols){
        filCols = this.filter.grid.columns.map(col=>col.binding);
      }
      
      for(let i = 0;i < filCols.length;i++){
        let colBinding = filCols[i];
        
        let colFil = this.filter.getColumnFilter(colBinding);
        
        if(!colFil.apply(item)){
          return false;
        }
      }

      let initialDate = this.state.dataInicial;
      let finalDate = this.state.dataFinal;

      let filterInitialDateText;
      let filterFinalDateText;

      if (initialDate != null)
        filterInitialDateText = initialDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

      if (finalDate != null)
        filterFinalDateText = finalDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

      let filterInitialDate = new Date(filterInitialDateText);
      let filterFinalDate = new Date(filterFinalDateText);

      let date = new Date(item.dataReferencia)

      if ((date >= filterInitialDate && (finalDate == null || finalDate == '')) || 
          (date <= filterFinalDate && (initialDate == null || initialDate == '')) || 
          (date >= filterInitialDate && date <= filterFinalDate) ||
          ((initialDate == '' || initialDate == null) && (finalDate == '' || finalDate == null))){
        return true;
      }

      return false
    }

    if(this.filter.grid.collectionView){
      this.filter.grid.collectionView.filter = filterFun;
    }
  }

  initializeFilter(filter) {
    this.filter = filter;
  }

  loadingComponent(props) {
    const loading = props.loading;

    if (loading) {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              Você não possui Arquivos Remotos para consultar
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="expansionPanelWrapper" style={{paddingTop: "20px"}}>
        <div className="App">
          <div>
            <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item xs={12} style={{width: '100%'}}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Carteira</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <Select 
                            native 
                            value={this.state.carteiraNome} 
                            onChange={this.handleCarteiraChange} 
                            style={{height: "45px"}}
                            disabled={this.state.operationList.length == 0}
                          >
                            {this.state.carteira.map((entry, index) => (
                              <option key={index} value={entry.fkCarteira}>{entry.carteira}</option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Data de Vencimento (Inicial)</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            type="date" 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { height: "45px" } 
                            }} 
                            value={this.state.dataInicial} 
                            onChange={this.handleDataInicialChange}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Data de Vencimento (Final)</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            type="date" 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { height: "45px" } 
                            }} 
                            value={this.state.dataFinal} 
                            onChange={this.handleDataFinalChange}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
            </Grid>
          </div>
          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
            <Box p={0}>
              {this.state.operationList.length > 0 ? 
                <div className="App-panel">
                  <FlexGrid 
                    alternatingRowStep={0} 
                    headersVisibility="Column" 
                    selectionMode="Cell" 
                    itemsSource={this.state.operationList} 
                    initialized={this.initializeGrid.bind(this)}
                    class="custom-flex-grid"
                  >
                    <FlexGridFilter
                      filterApplied={this.initCustomFilterFun.bind(this)}
                      initialized={this.initializeFilter.bind(this)}
                    />
                  </FlexGrid>   
                </div>
              :
                <this.loadingComponent loading={this.state.loading} />
              }
            </Box>
          </Box>
          {/* <Grid item xs={12} style={{width: '100%'}}>
            <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{paddingBottom: "20px"}}>
                <Box p={1}>
                  <ExcelFile 
                    element={
                      <Button 
                        variant="contained" 
                        color="primary"
                      >
                        <GetAppIcon />
                        <div style={{width: '10px'}} />
                        Exportar
                      </Button>
                    }
                  >
                    <ExcelSheet data={this.state.selectedList} name="Employees">
                      <ExcelColumn label="Data Historico" value="dataHistorico"/>
                      <ExcelColumn label="Descricao" value="descricao"/>
                      <ExcelColumn label="Numero Documento" value="numeroDocumento"/>
                      <ExcelColumn label="Historico Lancamento" value="historicoLancamento"/>
                      <ExcelColumn label="Valor" value="Valor"/>
                    </ExcelSheet>
                  </ExcelFile>
                </Box>
              </Box>
            </Grid>
          </Grid> */}
        </div>
        <Dialog open={this.state.openDownloadDialog} onClose={() => {}} >
          <DialogTitle>
            Title
          </DialogTitle>
          <DialogContent style={{marginBottom: '20px'}}>
            <div style={{height: '300px'}}>
              a
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {}} color="primary" autoFocus>
              Botao
            </Button>
          </DialogActions>
        </Dialog>
      </div> 
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
  };
}

export default connect(mapStateToProps, { updateOperationsList } )(ContaGrafica);
import React, { Component } from 'react';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider } from '@material-ui/core';
// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// react-redux
import { connect } from 'react-redux';

class item{
  id = 0;
  pergunta = '';
  resposta = '';
}

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      FAQList: []
    }
  }

  getFAQ() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:FAQ',
    }
    
    let FAQList = [];
    
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',   
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log('getFAQ', responseJson);

        responseJson.list.map((entry, index) => {
          let FAQObject = Object.create({}, item);
  
          FAQObject.id = entry.ID;
          FAQObject.pergunta = entry.PERGUNTA;
          FAQObject.resposta = entry.RESPOSTA;
  
          FAQList.push(FAQObject);
        });

        this.setState({
          FAQList: FAQList
        });
    });
  }
  
  componentDidMount() {
    this.getFAQ();
  }

  render() {
    return (
      <div style={{display: 'flex'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left" ref="Title" style={{fontFamily: 'lovelo'}}>
              FAQ
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={this.state.loading ? "loadingPaper" : "normalPaper" }>
              <div className="paperWrapper" style={{paddingLeft: "20px", paddingRight: "20px", color: '#32438B'}}>
                <Typography variant="h6" style={{padding: "20px"}}>
                  Perguntas Frequentes
                </Typography>
                <Divider />
                { this.state.FAQList.map((value, index) => (
                <div className="expansionPanelWrapper" style={{paddingTop: "20px", paddingBottom: "20px" }} key={index}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography>
                        <b>{value.pergunta}</b>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        {value.resposta}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div> 
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials
  };
}

export default connect(mapStateToProps, { } )(FAQ);
import React, { Component } from 'react';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, AppBar, Tabs, Tab } from '@material-ui/core';
// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// react-redux
import { connect } from 'react-redux';
import ListaRecebiveis from './ListaRecebiveis';
import ContaGrafica from './ContaGrafica';
import ArquivosRemotos from './ArquivosRemotos';

class item{
  id = 0;
  pergunta = '';
  resposta = '';
}

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    }
  }

  componentDidMount() {
    this.getUserAcessLevel();
  }

  getUserAcessLevel(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:CLIENTE_POR_ID',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log('getUserAcessLevel', responseJson);

        if (responseJson.list[0].NIVEL != null) {
          this.setState({
            RelatArquivoRemoto: responseJson.list[0].RelatArquivoRemoto,
            RelatContaGrafica: responseJson.list[0].RelatContaGrafica,
            RelatListaRecebiveis: responseJson.list[0].RelatListaRecebiveis,
          })
        }
    });
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  }

  render() {
    return (
      <div style={{display: 'flex'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left" ref="Title" style={{fontFamily: 'lovelo'}}>
              Relatórios
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={this.state.loading ? "loadingPaper" : "normalPaper" }>
              <div className="paperWrapper" style={{paddingLeft: "20px", paddingRight: "20px", color: '#32438B'}}>
                <div className="tabWrapper" style={{paddingTop: "20px"}} ref="Info">
                  <AppBar position="static" style={{backgroundColor: '#D0D0D0', borderRadius:"50px"}}>
                    <Tabs centered variant="fullWidth" value={this.state.tab} onChange={this.handleChangeTab} TabIndicatorProps={{style: {background:'#3B86FF', height: '100%', borderRadius:"50px",zIndex: -100}}}>
                      { this.state.RelatListaRecebiveis == true ? 
                        <Tab label="Lista de Recebíveis" className={this.state.tab == 0 ? "tabItemSelected" : "tabItem" }/>
                      : 
                        <div />
                      }
                      { this.state.RelatContaGrafica == true ? 
                        <Tab label="Conta Gráfica" className={this.state.tab == 1 ? "tabItemSelected" : "tabItem" }/>
                      : 
                        <div />
                      }
                      { this.state.RelatArquivoRemoto == true ? 
                        <Tab label="Arquivos Remotos" className={this.state.tab == 2 ? "tabItemSelected" : "tabItem" }/>
                      : 
                        <div />
                      }
                    </Tabs>
                  </AppBar>
                  <div value={this.state.tab} hidden={this.state.tab != 0}>
                    <ListaRecebiveis activeTab={this.state.tab == 0} />
                  </div>
                  <div value={this.state.tab} hidden={this.state.tab != 1}>
                    <ContaGrafica activeTab={this.state.tab == 1} />
                  </div>
                  <div value={this.state.tab} hidden={this.state.tab != 2}>
                    <ArquivosRemotos activeTab={this.state.tab == 2} />
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials
  };
}

export default connect(mapStateToProps, { } )(Reports);
import React, { Component } from 'react';
import { Container, Col, Row, Button, Form } from "react-bootstrap";
import HeaderLogin from './components/HeaderLogin';
import Header from './components/Header';
import Formulario from './components/Formulario';

import { addUser } from '../redux/actions/PreCadastroActions';

import { connect } from 'react-redux';

import { Redirect } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Paper, Grid, Typography, Box, CssBaseline, AppBar, Toolbar, CircularProgress } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import AppbarUserPanel from './dashboard/components/Appbar/AppbarUserPanel';
import logoImage from '../images/logo_home.png';

var self = null;

export class Home extends Component {
  constructor(props) {
    super(props);

    self = this;

    toast.configure();

    this.state = {
      action: '', 
      selectedIndex: 0, 
      values: new Array(), 
      user: '', 
      password: '',
      openedModal: false,
      recoverUser: '',

      loading: false,
      loadingRecover: false,
    };

    this.handleModal = this.handleModal.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }


  getStatus(credentials) {
       
    //this.toStatus();
    //return;
    console.log(credentials);

    var obj = {
      qid : 'PAINEL_ACOMPANHAMENTO:PAINEL_ACOMPANHAMENTO',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [credentials.user_id]
        }
      ]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log('getStatus', responseJson);

        self.setState({
          etapas: responseJson.list
        });
        
        if(responseJson.list.length == 0) {
          self.toStatus();
          return;
        }

        for(var i = 0; i < responseJson.list.length; i ++) {
          if(responseJson.list[i].COR_DO_PAINEL == 'PINTA DE AZUL') {
              /*self.setState({loading: false, etapa: responseJson.list[i]['Nome Etapa'], status: responseJson.list[i]['Status'], alteracao : responseJson.list[i]['DATA_INICIO'], idEtapa : responseJson.list[i]['ID'] });

              //alert(responseJson.list[i]['Status']);
              
              self.etapa = responseJson.list[i]['ID'];

              if(responseJson.list[i]['Status'] == 'Inclusão')
              {
                self.setState({emAnalise: true, loading: false});
                return;
              }
              
              if(responseJson.list[i]['Status'] != 'Reprovado')
              {
                self.getDocumentos(responseJson.list[i]['ID']);
                self.getSolicitacoes(responseJson.list[i]['ID']);
              }*/
              
            if(responseJson.list[i]['Status'] == 'Cliente') {
              self.toDashboard();
              return;
            }

            if(responseJson.list[i]['Status'] != 'Cliente') {
              self.toStatus();
              return;
            }
          }
        }
    });
  }


  componentWillMount(){
    //Recupera dados da sessão guardado no sessionStorage
    let data = sessionStorage.getItem('credentials');

    //Verifica se existe algum dado guardo no sessionStorage
    if(data != null) {
      var d = JSON.parse(data);
      var dd = new Date(d.expiration)

      //Se caso a data de expiração da sessão for maior agora, vamos utilizar a sessão (token) armazenado no starage -> vou jogar de volta para o Reducer
      if(new Date() < dd) {
        self.props.addUser(JSON.parse(data));
        
        /*
          Aqui estava jogando direto para a tela de status (método self.toStatus()), ou seja... se o cara esta com sessão aberta (logado)... automaticamente 
          eu jogava para a tela de status. Neste ponto, só existem 3 estados:

          - nao logado: jogo para a tela de login
          - nao logado e sem cadastro: ai direcionameos para que o cara faça o pré-cadastro (nesse processo ele vai gerar um usuário no EPM)
          - logado: mando pra tela de status


          A ideia agora, é que a gente crie uma condição nova... que vai ser algo como:

          - logado, mas com status no EPM de "pré cadastro em análise pelo Backoffice".... ai jogamos para a tela de status onde o cara acompanha esse processo
          - logado, e com conta já validada pelo backoffice (Ai jogamos o cara para a sua tela de dashboard).

          Por hora, você vai ver em baixo que estou colocando o metodo "self.toDashboard()", que vai mandar para o seu dash...

          Depois a gente vai implementar uma chamada no EPM com a ajuda do andre para verificarmos o status do cara na base...
          ai faremos um request... no callback a gente faz um if.... e joga para a tela de status OU joga para a tela de dashboard.
          
        */
        self.getStatus(d);
        // self.toStatus();
        //self.toDashboard();
      }
    }
  }

  toDashboard() {
    this.setState({action: 'toDashboard'});
  }

  toStatus() {
    this.setState({
      action: 'toStatus'
    });
  }
  
  doLogin() {
    var obj = {
      username : this.state.user,
      password: this.state.password
    }

    if (this.state.user.length == 0 || this.state.password.length == 0) {
      toast.error('Usuário e Senha precisam ser preenchido.');
      return
    }

    this.setState({
      loading: true,
    })

    fetch('https://apps.blueprojects.com.br/nobel/Security/login', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if(responseJson.data == null) {
          toast.error('A Combinação Usuário e Senha está inválida');
          console.log('ERRO: ', responseJson);

          this.setState({
            loading: false,
          })
        }
        else {
          var now = new Date();

          now.setHours(now.getHours() + 24); // timestamp
          now = new Date(now); // Date object

          responseJson.data['expiration'] = now;
  
          sessionStorage.setItem('credentials', JSON.stringify(responseJson.data));

          self.props.addUser(responseJson.data);
          
          
          self.getStatus(responseJson.data);
          
          // mesmo if que vamos colocar lá em baixo
          //self.toDashboard();
          // self.toStatus();
        } 
    });
  }

  
  toCadastro() {
    this.setState({action: 'toCadastro' });
  }

  handleModal() {
    this.setState({
      openedModal: !this.state.openedModal
    })
  }

  getEmails() {
    if (this.state.recoverUser == ''){
      toast.warn('Preencha o campo de E-mail');
      return
    }

    this.setState({
      loadingRecover: true,
    })

    var obj = {
      qid : 'PAGINA_DASHBOARD:USER_EMAIL',
      conditions: [
        {
          filterid: 'EMAIL',
          values: [this.state.recoverUser]
        }
      ]
    }
    
    let emailList = [];
    
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',   
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.list.length > 0){
          fetch('https://qsqvorsafi.execute-api.us-east-2.amazonaws.com/Prod/api/ChangePassword/ChangePassword?email=' + this.state.recoverUser, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          });

          toast.success('Pronto! Verifique seu E-mail.');
        }
        else{
          toast.error('Opa! Este E-mail não esta cadastrado.');
        }

        this.setState({
          loadingRecover: false,
        })
    });
  }

  render() {
    if(this.state.action == 'toStatus') {
      return <Redirect to={'/status'} />;
    }
    else if(this.state.action == 'toCadastro') {
      return <Redirect to={'/cadastro'} />;
    }
    else if(this.state.action == 'toDashboard') {
      return <Redirect to={'/dashboard'} />;
    }
    return (
      <div style={{backgroundColor: '#F9F9F9', height: '100%'}}>
          <Header />
          <div className="leftcontainter">
            <div style={{paddingLeft: 60, paddingRight: 60, textAlign: 'left', width: '100%'}}>
              <span style={{fontFamily: 'Futura', fontSize: 22,}}>
                Acessar Conta
              </span>
                <div style={{marginTop: 30, marginBottom: 230}}>
                  <div className="form-fields">
                    <a className="form-labels">
                      *Usuário
                    </a>
                    <input className="form-control" type='text' placeholder="Digite seu E-mail" onChange={(event) => this.setState({user: event.target.value})} value={this.user} />
                  </div>  
                  <div className="form-fields">
                    <a className="form-labels">*Confirme a Senha</a>
                    <input className="form-control" type='password' placeholder="Senha de Acesso" onChange={(event) => this.setState({password: event.target.value})} value={this.password} />
                    <div style={{height: '5px'}} />
                    <a href="#" onClick={this.handleModal}>Esqueceu sua senha?</a>
                  </div>
                  <div style={{height: '25px'}} />
                  {this.state.loading == true ? 
                    <div>
                      <Grid container justify="center" alignItems="center" xs={12}>
                        <Grid item>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    </div>
                  :
                    <Button style={{width: '100%', marginTop: 10, fontFamily: 'Futura', fontWeight: '500'}} onClick={this.doLogin.bind(this)}>
                      Entrar
                    </Button>
                  }
                  <div onClick={() => this.toCadastro()} style={{fontFamily: 'Futura', marginTop: 20, textAlign: 'center', color: '#007bfe', cursor: 'pointer'}}>
                    Criar Conta
                  </div>          
                </div>
              </div>
          </div>
          <div className="rightcontainer">
            <div className="imgBackground"></div>
          </div>
          <Modal open={this.state.openedModal} onClose={this.handleModal} className="modalPaper">
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper" style={{width: '500px'}}>
                  <Grid container style={{paddingBottom: '20px'}}>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="subtitle1" align="left">
                        Esqueceu a Senha?
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleModal}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} style={{paddingBottom: '10px'}}>
                      <Typography variant="body2" align="left">
                        Não tem problema nos podemos te ajudar! Coloque o seu E-mail abaixo que enviaremos a sua nova senha.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom: '20px'}}>
                      <a className="form-labels">
                        E-mail
                      </a>
                      <input className="form-control" type='text' placeholder="Digite seu E-mail" onChange={(event) => this.setState({recoverUser: event.target.value})} value={this.recoverUser} />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                        <Box p={0}>
                        {this.state.loadingRecover == true ? 
                          <div style={{marginRight: "24px"}}>
                            <Grid container justify="center" alignItems="center" xs={12}>
                              <Grid item>
                                <CircularProgress />
                              </Grid>
                            </Grid>
                          </div>
                        :
                          <Button style={{fontFamily: 'Futura', fontWeight: '500'}} onClick={this.getEmails.bind(this)}>Enviar E-mail</Button> 
                        }
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
    return{
        nome: state.PreCadastroReducer.nome,
        telefone: state.PreCadastroReducer.telefone,
        email: state.PreCadastroReducer.email,
        celular: state.PreCadastroReducer.celular,
    };
  }
  
  export default connect(mapStateToProps, { addUser } )(Home);
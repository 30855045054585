import React, { Component } from 'react';
import '../../../components/Style.css';
// @material-ui/core
import { Paper, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Grid, Button, Badge, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';
// react-redux
import { connect } from 'react-redux';
import { updateInformation } from '../../../../../redux/actions/WelcomeCardAction'
import { updateList } from '../../../../../redux/actions/PendenciesListActions'
// aws-s3
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';

class pendencyFile {
  id = 0;
  description = ''; 
  drawnName = null; 
  drawnValue = 0; 
  expiringDate = null;
  type = '';
  ampulheta = false;
};

class PendenciesFilesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendencyFileList:[],
      files: []
    };

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updatePendencyFileList = this.updatePendencyFileList.bind(this);
    this.removePendencyRow = this.removePendencyRow.bind(this);
  }

  updatePendencyFileList(){
    this.setState({
      pendencyFileList: []
    });
    
    var obj = {
      qid : 'PAGINA_DASHBOARD:PENDENCIAS_DOCUMENTOS',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        },
        {
          filterid: 'PENDENCIA',
          values: [this.props.pendencyID]
        }
      ]
    }
    
    var pendencyFileList = [];
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          let pendencyFileObject = Object.create({}, pendencyFile);
          
          pendencyFileObject.ID = entry.ID;
          pendencyFileObject.description = entry.PENDENCIA_DOCUMENTO;
          // pendencyFileObject.drawnName = entry.NOME_SACADO;
          // pendencyFileObject.drawnValue = entry.VALOR_SACADO;
          // pendencyFileObject.expiringDate = entry.DATA_VENCIMENTO;
          pendencyFileObject.type = entry.PENDENCIA;
  
          pendencyFileList.push(pendencyFileObject);
        })
  
        this.setState({
          pendencyFileList: pendencyFileList,
        }, () => {
          if (this.state.pendencyFileList.length == 0)
            this.props.handleOpenModal();
          });
    });
  }

  componentDidMount(){
    this.updatePendencyFileList();
  }

  handleLinkClick = (index) => {
    var input = document.getElementsByClassName("inputElement");

    input[index].click();
  }

  onFileChange(e, pendencyID) {
    const file = e.target.files[0];

    if (file == null) {
      return
    }

    file['pendencyID'] = pendencyID;

    const list = this.state.files;

    for (var i = 0; i < list.length; i++) {
      if (list[i].pendencyID == pendencyID)
        return
    }

    list.push(file);

    this.setState({
      files: list
    });
  }
  
  removePendencyRow(index) {
    const list = this.state.pendencyFileList;

    list.splice(index, 1);
  
    this.setState({
      pendencyFileList: list
    });
  }

  removeFile(index) {
    const list = this.state.files;

    list.splice(index, 1);
  
    this.setState({
      files: list
    });
  }

  uploadFile(){
    let self = this;

    this.setState({
      ampulheta: true,
    });
    
    const s3 = new AWS.S3({
      accessKeyId: 'AKIAIM54H7SBTJOZI6DQ',
      secretAccessKey: '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',
      region: 'us-east-2',
    });

    this.state.files.map((file, index) => {
      s3.upload({
        Bucket: 'blue-temp-files',
        Key: file.name.replace(/ /g, '_'),
        Body: file,
        ACL: 'public-read'
      },function(err, data){
        if (!err) {
          console.log(data.Location);
          console.log('Successfully uploaded package.');
  
          self.removeFile(index);
          self.saveFile(data.Location, file.pendencyID);
        }
        else {
          self.setState({
            ampulheta: false,
          });

          toast.error('Erro no Envio do Arquivo');
        }
      });
    })
  }

  saveFile(file, itemID){
    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9DTElFTlRFX1BFTkRFTkNJQVNfRE9DVU1FTlRPUzoyMzg0Njk=',
      fid: 128,
      data: {
        DOCUMENTO: file
      },
      // Salvar no EPM
      // files: [file],
      type: 1,
      key: {
        id: itemID
      }
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        this.updatePendencyFileList();
        this.props.updateList(this.props.credencials.user_id);
        this.props.updateInformation(this.props.credencials);

        if(responseJson.error == null) {
          toast.success("Arquivo enviado com sucesso!");

          this.setState({
            ampulheta: false,
          });
        }
        else {
          toast.error(responseJson.error.message);
          console.log('ERRO: ', responseJson);
        }
    });
  }

  formatDate(date) {
    return (new Date(date)).toLocaleDateString('pt-BR');
  }

  formatMoney(number) {
    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }

  render() {
    return (
      <div className="tableWrapper" style={{minWidth: "500px"}}>
        <Paper>
          {this.state.pendencyFileList == 0 ? 
          <div>
            <Table style={{borderRadius: "20px"}}>
              <TableHead className="tableHeader" style={{borderRadius: "20px"}}>
                <TableRow>
                <TableCell align="center">Pendência</TableCell>
                  {/* <TableCell align="center">Sacado</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Vencimento</TableCell> */}
                  <TableCell align="center">Arquivo</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Grid container justify="center" alignItems="center" xs={12} style={{height: '100px'}}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </div>
          :
          <div>
            <Table style={{borderRadius: "20px"}}>
              <TableHead className="tableHeader" style={{borderRadius: "20px"}}>
                <TableRow>
                  <TableCell align="center">Pendência</TableCell>
                  {/* <TableCell align="center">Sacado</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Vencimento</TableCell> */}
                  <TableCell align="center">Arquivo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.pendencyFileList.map((row, index) => (
                  <TableRow>
                    <TableCell align="center">{row.description}</TableCell>
                    {/* <TableCell align="center">{row.drawnName}</TableCell>
                    <TableCell align="center">{this.formatMoney(row.drawnValue)}</TableCell>
                    <TableCell align="center">{this.formatDate(row.expiringDate)}</TableCell> */}
                    <TableCell align="center">
                    <input className="inputElement" type="file" style={{display: "none"}} onChange={(e) => this.onFileChange(e, row.ID)} />
                    {/* <a href="#!" onClick={() => this.handleLinkClick(index)}>
                      <Typography variant="subtitle2">
                        {row.type}
                      </Typography>
                    </a> */}
                      <Tooltip title={row.type}>
                        <IconButton edge="end" aria-label="delete" style={{color: "#3B86FF"}} onClick={() => this.handleLinkClick(index)}>
                          <PublishIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          }
        </Paper>
        <Grid container style={{paddingTop: "32px"}}>
          <Grid container xs={8} style={{height: "60px"}} >
            {this.state.files.map((file, index) => (
              <Grid container xs={2} justify="center">
                <Grid item>
                  <Badge 
                    badgeContent="X" 
                    color="error" 
                    component="a" 
                    href="#/dashboard" 
                    onClick={() => this.removeFile(index)} 
                    style={{position: "absolute", paddingLeft: "32px"}} />
                  <DescriptionIcon color="primary" fontSize="large"/>
                </Grid>
                <Grid item xs zeroMinWidth xs={12}>
                  <Typography noWrap>
                    {file.name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container justify="flex-end" alignItems="center" xs={4}>
            <Grid item>
              {this.state.ampulheta ?
                <div style={{paddingRight: "50px"}}>
                  <CircularProgress />
                </div> 
                :
                <Button variant="contained" color="primary" disabled={this.state.files.length != 0 ? false : true} onClick={this.uploadFile}>
                  Enviar Arquivos
                </Button>
              }
            </Grid>
          </Grid> 
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials, 
    pendencyID: state.DashboardHomeReducer.pendencyID,
  };
}

export default connect(mapStateToProps, { updateInformation, updateList } )(PendenciesFilesTable);
import { ADD_STEP1, ADD_STEP2, ADD_STEP3, ADD_USER, USER_CREATED } from '../constants/PreCadastroConstants';

const INITIAL_STATE = {
  nome: '',
  email: '',
  telefone: '',
  celular: '',
  senha: '',
  confirmacaoSenha: '',
  
  relacaoEmpresa: '',
  razaoSocial: '',
  cnpj: '',
  telefoneEmpresa: '',
  emailEcpf: '',
  numeroFuncionarios: '',
  valorMedioTitulos: '',
  faturamento: '',
  prazoMedio: '',
  segmento: '',

  credencials: null,

  fileEndividamento: '',
  fileFaturamento: '',
  userCreated: 0,
}


export default (state = INITIAL_STATE, action) => {

  console.log(action.type);
  switch(action.type) {
    case ADD_STEP1:
      console.log(action.data);
      return {
        ...state,
        nome: action.data.nome,
        email: action.data.email,
        telefone: action.data.telefone,
        celular: action.data.celular,
        senha: action.data.senha,
        confirmacaoSenha: action.data.confirmacaoSenha,
        relacaoEmpresa: action.data.relacaoEmpresa,
        fileEndividamento: action.data.fileEndividamento,
        fileFaturamento: action.data.fileFaturamento,
      }
    case ADD_STEP2:
      console.log(action.data);
      return {
        ...state,
        razaoSocial: action.data.razaoSocial,
        cnpj: action.data.cnpj,
        telefoneEmpresa: action.data.telefoneEmpresa,  
        emailEcpf: action.data.emailEcpf,
        numeroFuncionarios: action.data.numeroFuncionarios,
        valorMedioTitulos: action.data.valorMedioTitulos,   
        faturamento: action.data.faturamento,
        prazoMedio: action.data.prazoMedio, 
        segmento: action.data.segmento, 
        recuperacao: action.data.recuperacao,
          
      }
    case ADD_STEP3:
      console.log(action.data);
      return {
        ...state,
        banco: action.data.banco,
        fundo: action.data.fundo,
        securitizadora: action.data.securitizadora,

        banco: action.data.banco,
        duplicatas: action.data.duplicatas,
        cheques: action.data.cheques,
        escrow: action.data.escrow, 

        outrasOperacoes: action.data.outrasOperacoes,
        restricoes: action.data.restricoes, 

        percEmbarcado: action.data.percEmbarcado,
        percEntrega: action.data.percEntrega,
        percPreFaturamento: action.data.percPreFaturamento,
      }
    case ADD_USER:
      console.log(action.data);
      return {
        ...state,
        credencials: action.data,
      }
    case USER_CREATED:
      console.log(action.data);
      return {
        ...state,
        userCreated: action.data,
      }
  }

  return state;
}
import React, { Component } from 'react';
import '../../components/Style.css';
// react-redux
import { connect } from 'react-redux';
import { Typography, Grid, Paper, CircularProgress, Hidden, Box } from '@material-ui/core';
// images
import assistenceImage from '../../../images/nobel-assistence.png';
import { updateInformation } from '../../../../redux/actions/WelcomeCardAction'

class WelcomeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount(){
    this.props.updateInformation(this.props.credencials);
  }

  formatMoney(number) {
    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }

  formatDate(date) {
    if (date != null){
      var d = new Date(date);

      var monthNames = [
        "Janeiro", "Fevereiro", "Março",
        "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro",
        "Novembro", "Dezembro"
      ];
      
      var day = d.getDate();
      var monthIndex = d.getMonth();
      var year = d.getFullYear();
    
      return day + ' de ' + monthNames[monthIndex] + ' de ' + year;
    }
      // return new Date(date.substring(0, 10)).toLocaleDateString()
    else
      return null
  }

  render() {
    return (
      <Paper style={{backgroundColor: "#3f51b5"}}>
        {this.props.userName == '' ? 
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{ height: '300px' }}>
            <Grid item>
              <CircularProgress />  
            </Grid>
          </Grid>
        </div>
        :
        <div>
          <Grid container spacing={2}>
            <Grid item md={6} xl={10}>
              <div className="paperText">
                <Typography className="paperTitle" component="h5" variant="h5" align="left">
                  Olá {this.props.userName}, seja bem-vindo!  
                </Typography>
                <Typography style={{fontWeight:"normal"}} component="h6" variant="h6" align="left">
                  Você {this.props.pendencyNumber > 0 ? 'tem ' + this.props.pendencyNumber : 'não tem ' } assuntos em aberto para serem resolvidos. 
                  <br />
                  {this.props.valueToRedeem > 0 ? 'Parabéns, você pode resgatar ' + this.formatMoney(this.props.valueToRedeem) + ' da sua carteira.': ''}
                  <br />
                  {this.props.pendencyDate != null ? 'E o seu próximo vencimento será em ' + this.formatDate(this.props.pendencyDate) + ' ' : ''} 
                  {this.props.nextValueToReedem > 0 && this.props.pendencyDate != null ? 'no valor de ' + this.formatMoney(this.props.nextValueToReedem) : ''}
                  <br />Tenha um ótimo dia <i>- Nobel Banco.</i>  
                </Typography>
              </div>  
            </Grid>
            <Grid item md={6} xl={2}>
            <Box display="flex" justifyContent="flex-end" m={1} p={1}>
              <Box p={1}>
                <img src={assistenceImage} />
              </Box>
            </Box>
            </Grid>
          </Grid>
        </div>
        }
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    
    pendencyNumber: state.DashboardHomeReducer.pendencyNumber, 
    valueToRedeem: state.DashboardHomeReducer.valueToRedeem,
    nextValueToReedem: state.DashboardHomeReducer.nextValueToReedem,
    pendencyDate: state.DashboardHomeReducer.pendencyDate,
    userName: state.DashboardHomeReducer.userName,
  };
}

export default connect(mapStateToProps, { updateInformation } )(WelcomeCard);
import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Divider, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, Avatar, Box } from '@material-ui/core';
// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';

class Notification {
  icon = null;
  iconColor = '';
  primaryText = '';
  secondaryText = '';
}

export default class AppbarNotification extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      notificationList: []
    };
  }

  componentDidMount() {
    this.getNotificationList();
  }

  getNotificationList() {
    let _notificationList = []; 
    
    this.props.notificationList.map((entry) => {
      let notificationObject = Object.create({}, Notification);

      if (entry.FK_TIPO_PENDENCIA == 100)
        notificationObject.icon = DescriptionIcon;
      else
        notificationObject.icon = EditIcon;

      notificationObject.iconColor = 'primary';
      notificationObject.primaryText = entry.PENDENCIA;
      notificationObject.secondaryText = this.formatDate(entry.LAST_UPDATE);

      _notificationList.push(notificationObject);
    })

    console.log('_notificationList', _notificationList);

    this.setState({
      notificationList: _notificationList
    })
  }

  formatDate(date) {
    if (date != null){
      var d = new Date(date);

      var monthNames = [
        "Janeiro", "Fevereiro", "Março",
        "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro",
        "Novembro", "Dezembro"
      ];
      
      var day = d.getDate();
      var monthIndex = d.getMonth();
      var year = d.getFullYear();
    
      return day + ' de ' + monthNames[monthIndex] + ' de ' + year;
    }
      // return new Date(date.substring(0, 10)).toLocaleDateString()
    else
      return null
  }


  render() {
    return (
      <div>
        <h5 className="notificationTitle">Notificações</h5>
        <Divider />
        {this.state.notificationList.length > 0 ? 
          <List>
            {this.state.notificationList.map((entry) => (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <entry.icon color={entry.iconColor}  />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={entry.primaryText} secondary={entry.secondaryText} />
              </ListItem>
            ))}
          </List>
        :
          <div style={{height: '100px'}}>
            <Box display="flex" justifyContent="center" alignItems="center" p={0} style={{height: '100%'}}>
              <Box p={0}>
                Nada para ver aqui..
              </Box>
            </Box>
          </div>
        }
        <Divider />
      </div>
    )
  }
}
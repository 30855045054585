import React, { Component } from 'react';
import '../../components/Style.css';
// @material-ui/core
import { Typography, Grid, Paper, Divider, CircularProgress } from '@material-ui/core';
// @material-ui/icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// recharts
import { PieChart, Pie, Cell, ResponsiveContainer, Label} from 'recharts';
// react-redux
import { connect } from 'react-redux';
import color from '@material-ui/core/colors/amber';

const COLORS = ['#5DFC62', '#FF6663'];

class chartPerCent{
  name = '';
  value = 0;
}

class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalValue: 0,
      valueToExpire: 0,
      expiredValue: 0,
      loadingTimer: false
    };
  }

  componentDidMount(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:GRAFICO_VALORES',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.list.length > 0) {
          this.setState({
            totalValue: responseJson.list[0].SALDO,
            valueToExpire: responseJson.list[0].VALORES_VENCER,
            expiredValue: responseJson.list[0].VALORES_VENCIDOS,
            loadingTimer: true
          });
        }
    });
  }

  calculatePerCent(){
    var listPerCent = [];
    let totalValue = 0;
    
    totalValue = this.state.valueToExpire + this.state.expiredValue;

    let chartToExpire = Object.create({}, chartPerCent);

    chartToExpire.name = "A vencer";

    if (this.state.valueToExpire > 0)
      chartToExpire.value = (this.state.valueToExpire * 100) / totalValue;  
    else
      chartToExpire.value = 0;

    listPerCent.push(chartToExpire);

    let chartExpired = Object.create({}, chartPerCent);

    chartExpired.name = "Vencido";

    if (this.state.expiredValue > 0) {
      chartExpired.value = (this.state.expiredValue * 100) / totalValue;  

      listPerCent.push(chartExpired);
    }
    // else {
    //   chartExpired.value = 1;
    // }

    
    return listPerCent;
  }

  formatMoney(number) {
    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }

  render() {
    var listPerCent = this.calculatePerCent();

    return (
      <Paper>
        {this.state.loadingTimer == false ? 
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '400px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
        :
        <div className="listWrapper">
          <Grid container style={{paddingTop: '20px'}}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className="paperSubTitle paperSubTitleTop" align="left">
                A Vencer
              </Typography>
            </Grid>
            <Grid item xs={8} justify="flex-end">
              <Typography variant="subtitle2" className="charTotalValue chatRightSide" align="right" style={{color: '#5DFC62'}}>
                {this.formatMoney(this.state.valueToExpire)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className="paperSubTitle paperSubTitleTop" align="left">
                Vencido
              </Typography>
            </Grid>
            <Grid item xs={8} justify="flex-end">
              <Typography variant="subtitle2" className="charTotalValue chatRightSide" align="right" style={{color: '#FF6663'}}>
                {this.formatMoney(this.state.expiredValue)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <ResponsiveContainer width="95%" height={400}>
                <PieChart onMouseEnter={this.onPieEnter}>
                  <Pie
                    data={listPerCent}
                    innerRadius="55%" outerRadius="65%"
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {
                      listPerCent.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                    <Label value={listPerCent[0].value.toFixed(2) + '% A vencer'} position="center" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2" className="paperSubTitle" align="left">
                <FiberManualRecordIcon style={{fontSize:"20px", color:"#5DFC62"}} /> A vencer 
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className="chatRightSide" align="right">
                <FiberManualRecordIcon style={{fontSize:"20px", color:"#FF6663"}} /> Vencido 
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" className="paperBottomValue paperSubTitleTop" align="left" style={{color:"#d3d3d3"}} >
                {this.formatMoney(this.state.valueToExpire)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" className="chatRightSide" align="right" style={{color:"#d3d3d3"}} >
                {this.formatMoney(this.state.expiredValue)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        }
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(DoughnutChart);
import React, { Component } from 'react';
import { Container, Col, Row, Navbar, InputGroup, Form, FormControl, Button, Nav, } from "react-bootstrap";
import { Hidden, AppBar, Toolbar, Typography, OutlinedInput, Grid, CssBaseline, Box } from '@material-ui/core';
import logoImage from '../../images/logo_home.png';


export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {action: '' };
      }

      render() {
        return (
          <Hidden mdDown>
            <CssBaseline />
            <AppBar position="fixed" className="appBarHome" elevation={0} style={{boxShadow: '1px 1px 10px 1px rgba(0,0,0,0.1)'}}>
              <Toolbar style={{paddingLeft: '0px'}}>
                <a href='http://nobelbanco.com.br/' style={{color: 'gray', fontFamily: 'lovelo', paddingRight: '20px', fontSize: '12.5px'}}>
                  <div className="toolbar">
                    <span style={{display: 'inline-block', height: '100%', verticalAlign: 'middle'}}/>
                    <img className="drawerImageHome" src={logoImage} />
                  </div>
                </a>
                <Box display="flex" alignItems="center" p={1} m={1} css={{ height: 100, width: '100%' }} />
                <div className="appbarRightSide">
                  <a href='http://nobelbanco.com.br/' style={{color: 'gray', fontFamily: 'lovelo', paddingRight: '20px', fontSize: '12.5px'}}>
                    Home
                  </a>
                  <a href='http://nobelbanco.com.br/plataforma/' style={{color: 'gray', fontFamily: 'lovelo', paddingRight: '20px', fontSize: '12.5px'}}>
                    Plataforma
                  </a>
                  <a href='http://nobelbanco.com.br/contato/' style={{color: 'gray', fontFamily: 'lovelo', paddingRight: '20px', fontSize: '12.5px'}}>
                    Contato
                  </a>
                </div>
                {/* <Button href='https://iwbarecebiveis.wba.com.br/iwba/pages/index.jsf' style={{fontFamily: 'lovelo', fontSize: '12.5px', width: '285px'}}>
                  Acesse sua conta
                </Button> */}
              </Toolbar>  
            </AppBar>
          </Hidden>
        )
      }

}
import React from 'react';
import logo from './logo.svg';
import './App.css';
import PreCadastro from './views/PreCadastro';
import Status from './views/Status';
import Home from './views/Home';
import Dashboard from './views/dashboard/Dashboard.js';

import {
  HashRouter as Router,
  // BrowserRouter as Router,
  // deploy blue
  Switch,
  Route,
  Link,
} from "react-router-dom";

function App() {
  return (
    <div className="App">

    <Router basename={'/'}>
    {/* <Router basename={'/nobel_hom'}> */}
      {/* deploy blue */}
      <div style={{height: '100%'}}>
        <Switch>
          <Route path="/status">
            <Status />
          </Route>
          <Route path="/cadastro">
            <PreCadastro />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/">
            <Home style={{heigth: '100%'}} />
          </Route>
        </Switch>
      </div>
    </Router>

    </div>
  );
}

export default App;

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// @material-ui/core
import { Grid } from '@material-ui/core';
// react-redux
import { connect } from 'react-redux';
// core components
import Appbar from './components/Appbar/Appbar.js';
import dashboardRoutes from './DashboardRoutes';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      action: '',
    }
  }
  
  componentDidMount(){
    if(this.props.credencials == null){
        this.setState({action: 'toLogin'});
        return;
    }
  }

  render() {
    if(this.props.credencials == null){
        return <Redirect to={'/'} />;
    }

    if(this.state.action == 'toLogin'){
        return <Redirect to={'/'} />;
    }
    
    let currentComponentID = this.props.currentComponent;

    return (
      <Appbar>
        {(() => {
          for (var i = 0; i < dashboardRoutes.length; i++) {
            if (dashboardRoutes[i].componentID == currentComponentID)
              return dashboardRoutes[i].componentObject
          }
        })()}
      </Appbar>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    currentComponent: state.DashboardReducer.currentComponent, 
  };
}

export default connect(mapStateToProps, { } )(Dashboard);
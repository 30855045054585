import { UPDATE_LIST, UPDATE_PENDENCY } from '../constants/PendendiesListConstants';

class pendency{
  id = 0;
  name = '';
  type = '';
}

const updateListEvent = (dispatch, data) => {
  var obj = {
    qid : 'PAGINA_DASHBOARD:PENDENCIAS',
    conditions: [
      {
        filterid: 'USUARIO',
        values: [data]
      }
    ]
  }
  
  let listPendencies = [];
  
  fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',   
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj)
    }).then((response) => response.json()).then((responseJson) => {
      responseJson.list.map((entry, index) => {
        let pendencyObject = Object.create({}, pendency);

        pendencyObject.id = entry.ID;
        pendencyObject.name = entry.PENDENCIA;
        pendencyObject.type = entry.TIPO_PENDENCIA;

        listPendencies.push(pendencyObject);
      });
      dispatch({ type: UPDATE_LIST, data: listPendencies });
    });
}

export const updateList = (userID) => 
{
  return dispatch => {
    updateListEvent(dispatch, userID);
  }
}

const updateListByBorderoEvent = (dispatch, userID, ItemID) => {
  var obj = {
    qid : 'PAGINA_DASHBOARD:PENDENCIAS',
    conditions: [
      {
        filterid: 'USUARIO',
        values: [userID]
      },
      {
        filterid: 'REMESSA',
        values: [ItemID]
      }
    ]
  }
  
  let listPendencies = [];
  
  fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',   
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj)
    }).then((response) => response.json()).then((responseJson) => {
      responseJson.list.map((entry, index) => {
        let pendencyObject = Object.create({}, pendency);

        pendencyObject.id = entry.ID;
        pendencyObject.name = entry.PENDENCIA;
        pendencyObject.type = entry.TIPO_PENDENCIA;

        listPendencies.push(pendencyObject);
      });
      dispatch({ type: UPDATE_LIST, data: listPendencies });
    });
}

export const updateListByBordero = (userID, ItemID) => 
{
  return dispatch => {
    updateListByBorderoEvent(dispatch, userID, ItemID);
  }
}

const updateListByItemEvent = (dispatch, userID, ItemID) => {
  var obj = {
    qid : 'PAGINA_DASHBOARD:PENDENCIAS',
    conditions: [
      {
        filterid: 'USUARIO',
        values: [userID]
      },
      {
        filterid: 'REMESSA_ITENS',
        values: [ItemID]
      }
    ]
  }
  
  let listPendencies = [];
  
  fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',   
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj)
    }).then((response) => response.json()).then((responseJson) => {
      responseJson.list.map((entry, index) => {
        let pendencyObject = Object.create({}, pendency);

        pendencyObject.id = entry.ID;
        pendencyObject.name = entry.PENDENCIA;
        pendencyObject.type = entry.TIPO_PENDENCIA;

        listPendencies.push(pendencyObject);
      });
      dispatch({ type: UPDATE_LIST, data: listPendencies });
    });
}

export const updateListByItem = (userID, ItemID) => 
{
  return dispatch => {
    updateListByItemEvent(dispatch, userID, ItemID);
  }
}

const updatePendencyEvent = (dispatch, data) => {
  let pendencyObject = Object.create({}, pendency);

  pendencyObject.id = data;

  dispatch({ type: UPDATE_PENDENCY, data: pendencyObject });
}

export const updatePendency = (pendencyID) => 
{
  return dispatch => {
    updatePendencyEvent(dispatch, pendencyID);
  }
}




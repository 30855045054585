import React, { Component } from 'react';
import { Container, Col, Row, Navbar, InputGroup, Form, FormControl, Button, Nav, } from "react-bootstrap";
import img from '../../images/logo.png';


export default class HeaderLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {action: '', user: '', password: '' };
      }


      render() {

        return (
            <Navbar className="justify-content-between pageBar">
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={img}
                        width="143"
                        height="75"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>

                <Form inline className="loginBox">
                    
                    <div>
                        <div className="labelTopo">Acesse sua Conta</div>
                        <div style={{flexDirection: 'row' }}>
                            <input value={this.state.user} onChange={(event) => this.setState({user: event.target.value})} style={{marginTop: 0, marginRight: 10, fontSize: 13, opacity: 0.8}} placeholder='Usuário' class="form-control" type='text' />
                            <input value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}  style={{marginTop: 0, marginRight: 10, fontSize: 13, opacity: 0.8}} placeholder='Senha' class="form-control" type='password' />
                            {/* <Button onClick={() => this.props.doLogin(this.state.user, this.state.password)} className="loginBtn">Entrar</Button> */}
                        </div>
                    </div>
                    
                </Form>
          </Navbar>
        )
      }

}
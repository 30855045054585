import { UPDATE_LIST, UPDATE_PENDENCY } from '../constants/PendendiesListConstants';
import { UPDATE_INFORMATION } from '../constants/WelcomeCardConstants'

const INITIAL_STATE = {
  pendenciesList: [],
  pendencyNumber: 0, 
  valueToRedeem: 0, 
  pendencyDate: null,
  nextValueToReedem: 0,
  userName: '',
  pendencyID: 0
}

export default (state = INITIAL_STATE, action) => {

  console.log(action.type);
  switch(action.type) {
    case UPDATE_LIST:
      console.log(action.data);
      return {
        ...state,
        pendenciesList: action.data,
      }
    case UPDATE_PENDENCY :
      console.log(action.data);
      return {
        ...state,
        pendencyID: action.data.id,
      }
    case UPDATE_INFORMATION :
      console.log(action.data);
      return {
        ...state,
        pendencyNumber: action.data.pendencyNumber, 
        valueToRedeem: action.data.valueToRedeem, 
        nextValueToReedem: action.data.nextValueToReedem,
        pendencyDate: action.data.pendencyDate,
        userName: action.data.userName
      }
  }

  return state;
}
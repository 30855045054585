import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment, MenuItem } from '@material-ui/core';
// @material-ui/icons
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
// react-toastify
import { toast } from 'react-toastify';

class tipoInfoObject {
  id = '';
  campo = '';
}

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tipoInfoList: [],
      tipoInfo: '',
      info: '',
    }
  }
  
  getTipoInfo() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:TIPO_INFORMACAO',
    }

    var tipoInfoList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log("responseJsonTipoDuplicata", responseJson);

        responseJson.list.map((entry, index) => {
          let tipoInfoItem = Object.create({}, tipoInfoObject);
          
          tipoInfoItem.id = entry.ID;
          tipoInfoItem.campo = entry.CAMPO;

          tipoInfoList.push(tipoInfoItem);
        });

        this.setState({
          tipoInfoList: tipoInfoList
        }, () => {
          this.setState({
            tipoInfo: this.state.tipoInfoList[0].id
          })
        });
    });
  }

  componentDidMount() {
    this.getTipoInfo();
  }

  handleTipoInfoChange = (event) => {
    this.setState({
      tipoInfo: event.target.value,
    })
  }

  handleInfoChange = (event) => {
    this.setState({
      info: event.target.value,
    })
  }

  addInfo() {
    var token = this.props.credencials.auth_ticket;

    alert(this.props.itemID);

    var obj = {
      tid: 'VF9SRU1FU1NBX0lURU5TX0lORk9STUFDQU86MjAxOTg4',
      fid: 159,
      data: {
        FK_REMESSA_ITENS: this.props.itemID,
        FK_TIPO_INFORMACAO: this.state.tipoInfo,
        INFORMACAO: this.state.info
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("addInfo", responseJson);

        toast.success("Informação enviada com sucesso!");

        this.props.handleCloseModalInfo();
    });
  }

  render() {
    return (
      <div>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12} style={{paddingBottom: "20px", paddingTop: "20px", width: '100%'}}>
            <Grid item xs={12} style={{width: '100%'}}>
              <Grid container>
                <Grid item xs={12} style={{paddingBottom: "10px"}}>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                    <Box p={0}>
                      <Typography variant="body2">
                        <b>Campo</b>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{width: '100%'}}>
                    <Select
                      value={this.state.tipoInfo}
                      onChange={this.handleTipoInfoChange}
                      variant="outlined"
                      style={{height: '45px'}}
                      fullWidth
                    >
                      {this.state.tipoInfoList.map((entry, index) => (
                        <MenuItem key={index} value={entry.id}>{entry.campo}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>  
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingTop: '10px'}}>
              <Grid container>
                <Grid item xs={12} style={{paddingBottom: "10px"}}>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                    <Box p={0}>
                      <Typography variant="body2">
                        <b>Informação</b>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    style={{width: '100%'}} 
                    variant="outlined" 
                    multiline
                    rows={4}
                    value={this.state.info}
                    onChange={this.handleInfoChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{width: '100%', paddingTop: '20px'}}>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                    <Box p={0}>
                      <Button variant="contained" color="primary" onClick={() => this.addInfo()}>
                        Enviar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials
  };
}

export default connect(mapStateToProps, { } )(Information);
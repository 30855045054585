import React, { Component } from 'react';
import '../../components/Style.css';
// @material-ui/core
import { Typography, Grid, Paper, Divider, CircularProgress, Box } from '@material-ui/core';
// react-redux
import { connect } from 'react-redux';

var self = null;

class DashboardBoxMensage extends Component {
  constructor(props) {
    super(props);

    self = this;

    this.state = {
      mensage: ''
    };
  }

  componentDidMount() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:HOME_MENSAGEM_BOAS_VINDAS',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log('componentDidMount', responseJson);
        if (responseJson.list.length > 0){
          this.setState({
            mensage: responseJson.list[0].MENSAGEM
          })
        }
    });
  }

  render() {
    return (
      <Paper>
        <div>
          <Grid container direction="column" justify="center" alignItems="center" xs={12} spacing={3} style={{height: '150px'}}>
            <Grid item style={{width: '90%'}}>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" xs={12} style={{width: '100%'}}>
                <Grid item>
                  <Typography variant='h5'>
                    {this.state.mensage != null && this.state.mensage != '' ? this.state.mensage : 'Tenha um bom dia!' }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{width: '100%'}}>
              <Grid container direction="column" justify="flex-end" alignItems="flex-end" xs={12}>
                <Grid item>
                  <Typography variant='overline'>
                    <i>- Equipe Nobel</i>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(DashboardBoxMensage);
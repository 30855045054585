import { UPDATE_INFORMATION } from '../constants/WelcomeCardConstants';

class welcomeCardObject {
  pendencyNumber = 0; 
  valueToRedeem = 0;
  nextValueToReedem = 0;
  pendencyDate = null;
  userName = '';
}

const updateInformationEvent = (dispatch, data) => {
  var obj = {
    qid : 'PAGINA_DASHBOARD:HOME_MENSAGEM_BOAS_VINDAS',
    conditions: [
      {
        filterid: 'USUARIO',
        values: [data.user_id]
      }
    ]
  }

  fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj)
    }).then((response) => response.json()).then((responseJson) => {
      
      let welcomeCard = Object.create({}, welcomeCardObject);

      if (responseJson.list.length > 0) {
        welcomeCard.valueToRedeem = responseJson.list[0].SALDO;
        welcomeCard.nextValueToReedem = responseJson.list[0].PROX_VALOR;
        welcomeCard.pendencyNumber = responseJson.list[0].QTD_PENDENCIAS;
        welcomeCard.pendencyDate = responseJson.list[0].PROX_VENCIMENTO;
        welcomeCard.userName = responseJson.list[0].RAZAO_SOCIAL;
      }
      else {
        welcomeCard.valueToRedeem = 0;
        welcomeCard.nextValueToReedem = 0;
        welcomeCard.pendencyNumber = 0;
        welcomeCard.pendencyDate = null;
        welcomeCard.userName = data.full_name;
      }

      dispatch({ type: UPDATE_INFORMATION, data: welcomeCard });
  });
}

export const updateInformation = (credencials) => 
{
  return dispatch => {
    updateInformationEvent(dispatch, credencials);
  }
}



import { UPDATE_OPERATIONS_LIST, UPDATE_SELECTED_LIST } from '../constants/OperationsConstants';

const INITIAL_STATE = {
  operationList: [],
  selectedList: [],
}

export default (state = INITIAL_STATE, action) => {

  console.log(action.type);
  switch(action.type) {
    case UPDATE_OPERATIONS_LIST:
      console.log(action.data);
      return {
        ...state,
        operationList: action.data
      }
    case UPDATE_SELECTED_LIST:
      console.log(action.data);
      return {
        ...state,
        selectedList: action.data
      }
  }

  return state;
}
import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment } from '@material-ui/core';
// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { setItemList, setDrawnList } from '../../../../redux/actions/NewOperationActions'
// react-toastify
import { toast } from 'react-toastify';
// core components
import Information from './Information';
import FavoredList from './FavoredList';
import BankCheck from './BankCheck';
import Duplicate from './Duplicate';
import ImportFile from './ImportFile';
import EditItemModal from './EditItemModal';
import { now } from 'moment';


class ItemObject {
  tipoItem = 0;
  documento = '';
  nome = '';
  valor = 0;
  vencimento = null;
  emissao = null;
  nroDocumento = '';
  tipoDuplicata = 100;
  desconto = 0;
  descontoPorCento = 0;
  documentIndex = 0;
  codigoWBA = '';

  logradouro = '';
  numero = '';
  bairro = '';
  municipio = '';
  uf = '';
}

class NewOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientID: 0,
      wbaID: 0,
      fatorCompra: 0,
      fatorFidic: 0,
      fatorFidic2: 0,

      upperTab: 0,
      bottomTab: 1,
      refresh: false,
      refreshCheque: false,
      refreshDuplicata: false,
      loading: false,

      documentosList: []
    }

    this.addOperation = this.addOperation.bind(this);
    this.addToItemList = this.addToItemList.bind(this);
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseItemEditModal = this.handleCloseItemEditModal.bind(this);

    this.refreshPage = this.refreshPage.bind(this);
    this.changeUpperTabProp = this.changeUpperTabProp.bind(this);
  }
  
  getClientID(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:CLIENTE_POR_ID',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          this.setState({
            clientID: entry.ID,
            wbaID: entry.CODIGO_WBA,
            fatorCompra: entry.FATORCOMPRA,
            fatorFidic: entry.FATOR_FIDIC,
            fatorFidic2: entry.FATOR_FIDIC2
          });
        })
    });
  }

  componentDidMount() {
    this.getClientID();
    this.props.setDrawnList(this.props.credencials.user_id);
  }

  handleChangeUpperTab = (event, upperTab) => {
    this.setState({ upperTab });
  };

  changeUpperTabProp() {
    setTimeout(() => {
      this.setState({ upperTab: 0 });
    }, 250);
  }

  handleChangeBottomTab = (event, bottomTab) => {
    this.setState({ bottomTab });
  };

  addFileList(operationID) {
    var token = this.props.credencials.auth_ticket;

    this.props.fileList.map((entry, index) => {
      var obj = {
        tid: 'VF9SRU1FU1NBX0FSUVVJVk9TOjE0MzY1MQ==',
        fid: 142,
        data: {
          DOCUMENTO: entry,
          FK_REMESSA: operationID
        },
        type: 1,
      }
  
      fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'auth' : token,
        },
        body: JSON.stringify(obj)
        }).then((response) => response.json().then((responseJson) => {console.log("addFileList", responseJson)}));
    });
  }

  addItemList(operationID) {
    var token = this.props.credencials.auth_ticket;

    var itemTotal = 0;

    for (var i = 0; i < this.props.itemList.length; i++) {
      itemTotal = itemTotal + this.props.itemList[i].valor;
    }

    this.props.itemList.map((entry, index) => {
      console.log('entry.vencimento', entry.vencimento);
      console.log('entry.emissao', entry.emissao);
      
      var vencimento = entry.vencimento;
      var vencimentoArray = vencimento.split("/");

      var _vencimento = vencimentoArray[1] + '/' + vencimentoArray[0] + '/' + vencimentoArray[2];

      var emissao = entry.emissao;
      var emissaoArray = emissao.split("/");

      var _emissao = emissaoArray[1] + '/' + emissaoArray[0] + '/' + emissaoArray[2];

      if (entry.tipoItem == 100){
        var obj = {
          tid: 'VF9SRU1FU1NBX0lURU5TOjExODkwMQ==',
          fid: 132,
          data: {
            FK_REMESSA: operationID,
            FK_TIPO_OPERACAO: entry.tipoItem,
            DOCUMENTO_SACADO: entry.documento,
            NOME_SACADO: entry.nome,
            NRO_DOCUMENTO: entry.nroDocumento,
            VALOR: entry.valor,
            DATA_EMISSAO: new Date(_emissao),
            DATA_VENCIMENTO: new Date(_vencimento),
            FK_TIPO_DUPLICATA: entry.tipoDuplicata,
            DESCONTO_VALOR: entry.desconto,
            DESCONTO_PERCENTUAL: entry.descontoPorCento,
            URL_XML: entry.urlXML,
            STATUS: 100,
            SACADO_DOCUMENTO: entry.documento,
            SACADO_NOME: entry.nome,
            SACADO_LOGRADOURO: entry.logradouro,
            SACADO_BAIRRO: entry.bairro,
            SACADO_NUMERO: entry.numero,
            SACADO_MUNICIPIO: entry.municipio,
            SACADO_UF: entry.uf,
            TOTAL_REMESSA: itemTotal,
          },
          type: 1,
        }
      }
      else {
        var obj = {
          tid: 'VF9SRU1FU1NBX0lURU5TOjExODkwMQ==',
          fid: 132,
          data: {
            FK_REMESSA: operationID,
            FK_TIPO_OPERACAO: entry.tipoItem,
            DOCUMENTO_SACADO: entry.documento,
            NOME_SACADO: entry.nome,
            NRO_DOCUMENTO: entry.nroDocumento,
            VALOR: entry.valor,
            DATA_EMISSAO: new Date(_emissao),
            DATA_VENCIMENTO: new Date(_vencimento),
            FK_TIPO_DUPLICATA: entry.tipoDuplicata,
            DESCONTO_VALOR: entry.desconto,
            DESCONTO_PERCENTUAL: entry.descontoPorCento,
            URL_XML: entry.urlXML,
            STATUS: 100,
            SACADO_DOCUMENTO: entry.documento,
            SACADO_NOME: entry.nome,
            SACADO_LOGRADOURO: entry.logradouro,
            SACADO_BAIRRO: entry.bairro,
            SACADO_NUMERO: entry.numero,
            SACADO_MUNICIPIO: entry.municipio,
            SACADO_UF: entry.uf,
            TOTAL_REMESSA: itemTotal,
          },
          type: 1,
        }
      }

      fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'auth' : token,
        },
        body: JSON.stringify(obj)
        }).then((response) => response.json().then((responseJson) => {
          console.log("addItemList", responseJson);
          // this.addItemScript(operationID, responseJson.data.result.id, entry);
        }));
    });
  }

  addItemScript(operationID, ItemID, Item) {
    var token = this.props.credencials.auth_ticket;
    var script = '';
    var emissao = '';
    var vencimento = '';
    var codigoWBA = '';

    var valor = Item.valor
    
    if(Item.valor != null) {
      valor = valor.toString().replace('.', '');
      valor = valor.toString().replace(',', '.');
    }
    
    if (Item.emissao != null) {
      emissao = (Item.emissao.substring(6,10) + Item.emissao.substring(3,5) + Item.emissao.substring(0,2) + " 00:00:00");
    }

    if (Item.vencimento != null) {
      vencimento = (Item.vencimento.substring(6,10) + Item.vencimento.substring(3,5) + Item.vencimento.substring(0,2) + " 00:00:00");
    }

    if (Item.codigoWBA != null) {
      codigoWBA = Item.codigoWBA
    }

    if(Item.tipoItem == 100){
      script = `insert into [SECURIT]..sigfls (  
                DATA,  DTBORDERO,  HISTORICO,  DCTO,  CODIGO,   VALOR,  CLIFOR,  SACADO,  
                BORDERO,  EMISSAO,  FLOT,  JUROS,  PRAZO,  CMC7,  CHBANCO,  CHAGENCIA,  
                CHCONTA,  CHNUMERO,  CHPRACA,  ALTERADO,  NOTAFISCAL,  DATANFISCAL,  aceite,  
                rejeitado,  ctrl_id,   temnota,   temcanhoto,    dtnotafiscal,     ownernf,  
                dtcanhoto,  ownercanhoto,  dtduplicata,  ownerdp,  canhotobom,  ownercanhotobom,  
                confirmado,  obs,  responsavel,  ownerconfirmado,   dtconfirmado,  temduplicata,  
                dtcanhotobom,   floatdnu,  fd0,   valorface,   desconto,  limitedesconto,  
                descontop,   tipodcto,  compraCS,  endossadopor,   CHC1,    CHC2,  CHC3,   CHLINHA,   
                ModoDeCobranca_ID,   SelModoDeCobranca,  SeConsulta,   IR,  CSLL,  COFINS,  PIS,  
                gerente,  gerentecomis,  gerentegeral,  gerentegeralcomis,  superintendente,  
                superintendentecomis,  gerenteflot,  basedeterminada,  numero_port,    cobbanco,   
                cobservico,    cobtarifa,    jurosajustado,   controleparticipante,  lote,   
                anolote,   prazodu,  temcheque,   dtcheque,  ownerch,   trusteeParaFIDC,  
                trusteeParaFIDC2,   controleAR,   ownercontroleAR,  numerocontrato,  performado,   
                deducao,  grupo,   valornota,  departamento,   monitorapreconfirmado,  preconfirmado,  
                obspre,  responsavelpre,  ownerpreconfirmado,  dtpreconfirmado,  consultarcentraisderisco,  
                dtaceite,  owneraceite,  dctoimport_,  tarifa,  msgboleto,  msgboleto2,  msgboleto3,  
                msgboleto4,  msgboleto5,  msgboleto6,  msgboleto7,  msgboleto8,  serienota,  
                apresentacaodocumento,  parcela,  quantidadeparcelas,  carteira_id,  EstadoConfirmacaoTitulo_id,  
                SelEstadoConfirmacaoTitulo,  ControlaCartaDeCessao,  CartaDeCessao,  DtCartaDeCessao,  
                OwnerCartaDeCessao,  DataPerformado,  EstadoPreConfirmacaoTitulo_ID,  SelEstadoPreConfirmacaoTitulo,  
                SelDuplicata,  SelNotaFiscal,  SelCanhoto,  SelCanhotoBom,  SelCheque,  SelAR) 
              SELECT  
                '` + vencimento + `', '` + emissao + `',NULL,'000055',
                '040',` + valor + `,'` + this.state.wbaID + `','` + codigoWBA + `',(SELECT MAX(BORDERO) FROM [SECURIT]..SIGBORS),dateadd(DD,-1,'` + emissao + `'),
                0,0.0,46,'` + Item.nroDocumento + 'cMC7' + `','','','','','','T','',
                dateadd(DD,-1,'` + emissao + `'),NULL,'X',MAX(ctrl_id) + 1,'','',dateadd(DD,-1,'` + emissao + `'),'026',NULL,NULL,NULL,NULL,NULL,
                NULL,NULL,'{FK_REMESSA_ITENS:` + ItemID + `}',NULL,NULL,NULL,NULL,NULL,1,1,` + valor + `,NULL,NULL,NULL,'CH',NULL,NULL,
                '*','*','*',NULL,0,NULL,'N',0.0,0.0,0.0,0.0,'024',3.0,'011',0.0,'111',0.0,0,0.0,'',NULL,NULL,NULL,0.0,
                '                         ',0,0,0,NULL,NULL,NULL,'S','S',NULL,NULL,NULL,'S',0.0,NULL,25773.0,NULL,
                'N',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,'000055',3.8,
                '` + Item.logradouro.substring(0, 38) + `','` + Item.numero.substring(0, 38) + `','
                ` + Item.bairro.substring(0, 38) + `','` + Item.municipio.substring(0, 38) + `','
                ` + Item.uf + `','` + this.state.wbaID + `','` + Item.nome.substring(0, 38) + `','
                ` + Item.documento + `','1',1,
                NULL,NULL,0,1,NULL,'N',NULL,NULL,NULL,NULL,1,NULL,NULL,NULL,NULL,NULL,NULL,NULL
              FROM [SECURIT]..sigfls`
    }
    else {
      script = `insert into [SECURIT]..sigfls (  
                DATA,  DTBORDERO,  HISTORICO,  DCTO,  CODIGO,   VALOR,  CLIFOR,  SACADO,  
                BORDERO,  EMISSAO,  FLOT,  JUROS,  PRAZO,  CMC7,  CHBANCO,  CHAGENCIA,  
                CHCONTA,  CHNUMERO,  CHPRACA,  ALTERADO,  NOTAFISCAL,  DATANFISCAL,  aceite,  
                rejeitado,  ctrl_id,   temnota,   temcanhoto,    dtnotafiscal,     ownernf,  
                dtcanhoto,  ownercanhoto,  dtduplicata,  ownerdp,  canhotobom,  ownercanhotobom,  
                confirmado,  obs,  responsavel,  ownerconfirmado,   dtconfirmado,  temduplicata,  
                dtcanhotobom,   floatdnu,  fd0,   valorface,   desconto,  limitedesconto,  
                descontop,   tipodcto,  compraCS,  endossadopor,   CHC1,    CHC2,  CHC3,   CHLINHA,   
                ModoDeCobranca_ID,   SelModoDeCobranca,  SeConsulta,   IR,  CSLL,  COFINS,  PIS,  
                gerente,  gerentecomis,  gerentegeral,  gerentegeralcomis,  superintendente,  
                superintendentecomis,  gerenteflot,  basedeterminada,  numero_port,    cobbanco,   
                cobservico,    cobtarifa,    jurosajustado,   controleparticipante,  lote,   
                anolote,   prazodu,  temcheque,   dtcheque,  ownerch,   trusteeParaFIDC,  
                trusteeParaFIDC2,   controleAR,   ownercontroleAR,  numerocontrato,  performado,   
                deducao,  grupo,   valornota,  departamento,   monitorapreconfirmado,  preconfirmado,  
                obspre,  responsavelpre,  ownerpreconfirmado,  dtpreconfirmado,  consultarcentraisderisco,  
                dtaceite,  owneraceite,  dctoimport_,  tarifa,  msgboleto,  msgboleto2,  msgboleto3,  
                msgboleto4,  msgboleto5,  msgboleto6,  msgboleto7,  msgboleto8,  serienota,  
                apresentacaodocumento,  parcela,  quantidadeparcelas,  carteira_id,  EstadoConfirmacaoTitulo_id,  
                SelEstadoConfirmacaoTitulo,  ControlaCartaDeCessao,  CartaDeCessao,  DtCartaDeCessao,  
                OwnerCartaDeCessao,  DataPerformado,  EstadoPreConfirmacaoTitulo_ID,  SelEstadoPreConfirmacaoTitulo,  
                SelDuplicata,  SelNotaFiscal,  SelCanhoto,  SelCanhotoBom,  SelCheque,  SelAR) 
              SELECT  
                '` + vencimento + `', '` + emissao + `',NULL,'` + Item.nroDocumento + `',
                '040',` + valor + `, '` + this.state.wbaID + `','` + codigoWBA + `',(SELECT MAX(BORDERO) FROM [SECURIT]..SIGBORS),dateadd(DD,-1,'` + emissao + `'),
                0,0.0,46,'','','','','','','T','` + Item.nroDocumento + `',
                dateadd(DD,-1,'` + emissao + `'),NULL,'X',MAX(ctrl_id) + 1,'S','S',dateadd(DD,-1,'` + emissao + `'),'026',NULL,NULL,NULL,NULL,NULL,
                NULL,NULL,'{FK_REMESSA_ITENS:` + ItemID + `}',NULL,NULL,NULL,NULL,NULL,1,1,` + valor + `,NULL,NULL,NULL,'DM',NULL,NULL,
                '*','*','*',NULL,0,NULL,'N',0.0,0.0,0.0,0.0,'024',3.0,'011',0.0,'111',0.0,0,0.0,'',NULL,NULL,NULL,0.0,
                '                         ',0,0,0,NULL,NULL,NULL,'S','S',NULL,NULL,NULL,'S',0.0,NULL,25773.0,NULL,
                'N',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,'` + Item.nroDocumento + `',5,
                '` + Item.logradouro.substring(0, 38) + `','` + Item.numero.substring(0, 38) + `','
                ` + Item.bairro.substring(0, 38) + `','` + Item.municipio.substring(0, 38) + `','
                ` + Item.uf + `','` + this.state.wbaID + `','` + Item.nome.substring(0, 38) + `','
                ` + Item.documento + `','1',1,
                NULL,NULL,0,1,NULL,'N',NULL,NULL,NULL,NULL,1,NULL,NULL,NULL,NULL,NULL,NULL,NULL
              FROM [SECURIT]..sigfls`
    }

    var obj = {
      tid: 'VF9SRU1FU1NBX1NDUklQVDoxMjcxNjc=',
      fid: 149,
      data: {
        FK_REMESSA_ITENS: ItemID,
        SCRIPT: script,
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("addItemScript", responseJson);
        this.addToWBA('item', ItemID);
    });
  }

  addOperationTitle(operationID) {
    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9SRU1FU1NBOjA3MDcwNg==',
      fid: 131,
      data: {
        TITULO: "Bordero " + operationID,
      },
      key: {
        ID: operationID
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("AddOperationTitle", responseJson);
    });
  }

  createBillet(operationID){
    fetch('https://4tpndngnej.execute-api.us-east-2.amazonaws.com/Prod/api/BoletoNobel/RunProc?remessaID=' + operationID, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  }
  
  addOperationScript(operationID) {
    var token = this.props.credencials.auth_ticket; 

    var itemTotal = 0;

    for (var i = 0; i < this.props.itemList.length; i++) {
      itemTotal = itemTotal + this.props.itemList[i].valor
    }

    var fatorCompra = this.state.fatorCompra
    if (this.state.fatorCompra != null) {
      fatorCompra = fatorCompra.toString().replace('.', '');
      fatorCompra = fatorCompra.toString().replace(',', '.');
    }

    var fatorFidic = this.state.fatorFidic
    if (this.state.fatorFidic != null) {
      fatorFidic = fatorFidic.toString().replace('.', '');
      fatorFidic = fatorFidic.toString().replace(',', '.');
    }

    var fatorFidic2 = this.state.fatorFidic2
    if (this.state.fatorFidic2 != null) {
      fatorFidic2 = fatorFidic2.toString().replace('.', '');
      fatorFidic2 = fatorFidic2.toString().replace(',', '.');
    }
    
    var obj = {
      tid: 'VF9SRU1FU1NBX1NDUklQVDoxMjcxNjc=',
      fid: 149,
      data: {
        FK_REMESSA: operationID,
        SCRIPT:`insert into [SECURIT]..sigbors (  
                  BORDERO,  DATA,  CLIFOR,  QTITULOS,  TITULOS,  CUSTO,  COTACAO,  MOEDA,  PRAZO,   
                  TAXA_EF,  FATOR,  TAXA_PE,  TXEFMIN,  FLOT,  LIBERADO,  CHEQUE,  AGENCIA,  VCHEQUE,  
                  OPERADOR,  CALCULO,  TXSERVICO,  TXISS,  SERVICO,  ISS,  CONTROL,  VTARIFA,  TAR_TITULO,  
                  FLOTMEDIO,  IOF,  IPMF,  TXIOF,  TXIPMF,  TAR_EXPOR,  LIQUIDO,  PJURIDICA,  ARREDOND,  ALTERADO,  
                  CAIXA,  owner,  TAR_CHEQUE,  VTARIFACHEQUE,  TAXA_REAL,  comissao,  dtliberacao,  aditivo,  
                  aditivocli,  grcliente,  grfactoring,  INDICANTE,  loteqtd,  lotesoma,  dtnp,  ownernp,  
                  dtaditivo,  owneradt,  reter,  IndiCom,  GteGeral,  GeralCom,  Superintendente,  SuperCom,  
                  AVCFE,  avcefc,  avirr,  avspreadam,  avspreadaa,  avvplr,  avvplus,  avrporus,  issdestacado,  
                  avirrcomfloat,  vctoface,  prazod0,  notafiscal,  qtitulosCS,  titulosCS,  servicoCS,  VTarifaCS,  
                  VTarifaChequeCS,  ISSTr,  txServicoTr,  RetImpostos,  BaseRetIRRF,  RetIRRFav,  RetIRRFdes,  
                  RetCSLLav,  RetCSLLdes,  RetCOFINSav,  RetCOFINSdes,  RetPISav,  RetPISdes,  RetIRRFavtr,  
                  RetCSLLavtr,  RetCOFINSavtr,  RetPISavtr,  PagarRetencoes,  FatorSImpostos,  TxIR,  TxCSLL,  
                  TxCOFINS,  TxPIS,  gerenteflot,  FatorVA,  ControlPagImediato,  cobtarifa,  cobtarifaCS,  
                  EstadoBordero,  AtualmenteCom,  calculoFIDC,  calculoFIDC2,  cobtarifaFIDC,  cobtarifaFIDC2,  
                  custoFIDC,  custoFIDC2,  fatorFIDC,  fatorFIDC2,  fatoraaFIDC,  fatoraaFIDC2,  fatorvaFIDC,  
                  fatorvaFIDC2,  flotmedioFIDC,  flotmedioFIDC2,  liquidoFIDC,  liquidoFIDC2,  prazoFIDC,  
                  prazoFIDC2,  prazod0FIDC,  prazod0FIDC2,  qtitulosFIDC,  qtitulosFIDC2,  reterFIDC,  
                  reterFIDC2,  servicoFIDC,  servicoFIDC2,  tar_chequeFIDC,  tar_chequeFIDC2,  tar_exporFIDC,  
                  tar_exporFIDC2,  tar_tituloFIDC,  tar_tituloFIDC2,  taxa_efFIDC,  taxa_efFIDC2,  taxa_peFIDC,  
                  taxa_peFIDC2,  taxa_realFIDC,  taxa_realFIDC2,  titulosFIDC,  titulosFIDC2,  txefminFIDC,  
                  txefminFIDC2,  txservicoFIDC,  txservicoFIDC2,  vchequeFIDC,  vchequeFIDC2,  vctofaceFIDC,  
                  vctofaceFIDC2,  vtarifachequeFIDC,  vtarifachequeFIDC2,  vtarifaFIDC,  vtarifaFIDC2,  ValorReservado, 
                  ValorReservadoFIDC,  ValorReservadoFIDC2,  datarecalculo,  dtaditivoTr,  owneradtTr,  dtnpFIDC,  
                  dtnpFIDC2,  ownernpFIDC,  ownernpFIDC2,  dtaditivoFIDC,  dtaditivoFIDC2,  owneradtFIDC,  
                  owneradtFIDC2,  grclienteTr,  grfactoringTr,  grclienteFIDC,  grclienteFIDC2,  grfactoringFIDC,  
                  grfactoringFIDC2,  CPMFTr,  recdespesastr,  observacao,  fatorajustado,  custodinheiro,  lote,  
                  anolote,  AVCEFCFIDC,  AVCEFCFIDC2,  AVIRRFIDC,  AVIRRFIDC2,  AVSpreadAMFIDC,  AVSpreadAMFIDC2,  
                  AVSpreadAAFIDC,  AVSpreadAAFIDC2,  AVVPLRFIDC,  AVVPLRFIDC2,  AVVPLUSFIDC,  AVVPLUSFIDC2,  
                  AVIRRComFloatFIDC,  AVIRRComFloatFIDC2,  AVCFEFIDC,  AVCFEFIDC2,  CLASSIFICACAO,  NOMEREPASSE,  
                  VALORREPASSE,  FAVORECIDOREPASSE,  RetemISS,  ContratoSequencia,  ContratoFIDCSequencia,  
                  ContratoFIDC2Sequencia,  ContratotSequencia,  ContratoNumero,  ContratoFIDCNumero,  
                  ContratoFIDC2Numero,  ContratotNumero,  ContratoAditivo,  ContratoFIDCAditivo,  ContratoFIDC2Aditivo,  
                  ContratotAditivo,  Personalizado0,  Personalizado1,  Personalizado2,  Personalizado3,  
                  Personalizado4,  Personalizado5,  Personalizado6,  Personalizado7,  Personalizado8,  Personalizado9,  
                  PercentualAtualizacao,  CotacaoAtualizacao,  IndiceAtualizacao,  CUSTOSECURIT,  SerieNF,  
                  dtbordero,  ownerbordero,  assinaturabordero,  ownerassinaturabordero,  dtborderoTr,  
                  ownerborderoTr,  assinaturaborderoTr,  ownerassinaturaborderoTr,  dtborderoFIDC,  ownerborderoFIDC,  
                  assinaturaborderoFIDC,  ownerassinaturaborderoFIDC,  dtborderoFIDC2,  ownerborderoFIDC2,  
                  assinaturaborderoFIDC2,  ownerassinaturaborderoFIDC2,  custodesagiofidc,  custodesagiofidc2,  
                  NaoCobrarIOF,  taxa_realperiodo,  taxa_realperiodofidc,  taxa_realperiodofidc2,  mododecobranca_id,  
                  bacennatop_idfidc,  bacennatop_idfidc2,  bacenmodop_idfidc,  bacenmodop_idfidc2,  bacencaracesp_idfidc,  
                  bacencaracesp_idfidc2,  txiofadicional,  iofadicional,  ApresentacaoDocumento,  ApresentacaoDocumentoFIDC,  
                  ApresentacaoDocumentoFIDC2,  DataCriacao,  DataConclusao,  FatorMinimo,  FatorMinimoFIDC,  FatorMinimoFIDC2,  
                  UUID,  RegistradoFinanfor,  SelAditivo,  SelAditivoTr,  SelAditivoFIDC,  SelAditivoFIDC2,  SelNP,  
                  SelNPFIDC,  SelNPFIDC2,  SelBordero,  SelBorderoTr,  SelBorderoFIDC,  SelBorderoFIDC2, OBS) 
                SELECT   
                  MAX(BORDERO) + 1,GETDATE(),` + this.state.wbaID + `,` + this.props.itemList.length + `,` + itemTotal +`,NULL,
                  NULL,NULL,NULL,NULL,` + fatorCompra + `,NULL,2.2494887525562373,3,'N',NULL,NULL,
                  NULL,'024',2,0.0,0.0,NULL,NULL,NULL,NULL,7.2000000000000002,NULL,NULL,NULL,NULL,
                  0.0,NULL,NULL,'S',NULL,'T',NULL,'026',3.7999999999999998,NULL,NULL,3.0,NULL,NULL,
                  NULL,1,1,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,'011',0.0,'111',0.0,NULL,
                  NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,
                  NULL,NULL,0.0,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,
                  NULL,'N',NULL,NULL,3.6499999999999999,NULL,0,NULL,NULL,NULL,NULL,
                  'EMANLISE20171016124034987','026',2,2,NULL,NULL,NULL,NULL,` + fatorFidic + `,` + fatorFidic2 + `,NULL,NULL,NULL,NULL,
                  NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,4.2999999999999998,
                  0.0,NULL,NULL,7.7999999999999998,0.0,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,5.2631578947368425,
                  4.166666666666667,0.0,0.0,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,0.0,0.0,0.0,NULL,NULL,NULL,
                  NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,1,1,1,1,1,1,NULL,NULL,'',NULL,NULL,NULL,NULL,NULL,
                  NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,'Padrao',NULL,NULL,
                  0,NULL,1,NULL,NULL,NULL,339,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,
                  NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,
                  NULL,NULL,NULL,NULL,NULL,'',NULL,NULL,'N',NULL,NULL,NULL,0,0,0,0,0,0,0,NULL,NULL,1,1,1,
                  GETDATE(),NULL,NULL,NULL,NULL,NULL,0,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,
                  '{FK_REMESSA: ` + operationID + `}'
                FROM [SECURIT]..SIGBORS`,
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("addOperationScript", responseJson);
        this.addToWBA('bordero', operationID);
    });
  }

  addToWBA(type, ID){
    fetch('https://cbdvwqepk2.execute-api.us-east-2.amazonaws.com/Prod/api/NobelWBA/RunProc?type=' + type + '&id=' + ID, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  }

  addFavoredScript(operationID) {
    var token = this.props.credencials.auth_ticket;

    var itemTotal = 0;
    for (var i = 0; i < this.props.itemList.length; i++) {
      itemTotal = itemTotal + this.props.itemList[i].valor
    }

    var obj = {
      qid : 'PAGINA_DASHBOARD:FAVORECIDOS',
      conditions: [
        {
          filterid: 'FAVORECIDO',
          values: [this.props.favored]
        }
      ]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log('getFavorecido', responseJson);
        
        var codigoWBA = ''
        if (responseJson.list[0].CODIGO_WBA != null)
          codigoWBA = responseJson.list[0].CODIGO_WBA

        var obj = {
          tid: 'VF9SRU1FU1NBX1NDUklQVDoxMjcxNjc=',
          fid: 149,
          data: {
            FK_REMESSA: operationID,
            SCRIPT:  `INSERT INTO [SECURIT]..SIGBDCHQ (
                        BORDERO, CODIGO, VALOR, CMC7, BANCO, AGENCIA, CONTA, 
                        CHEQUE, COMPL, forma, ctrl_id, favorecido, tipooperacao) 
                      SELECT  
                        (SELECT MAX(BORDERO) FROM [SECURIT]..SIGBORS), '05', 
                        '` + itemTotal + `', '', '` + responseJson.list[0].NRO_BANCO + `', '` + responseJson.list[0].NRO_AGENCIA + `', 
                        '` + responseJson.list[0].NRO_CONTA + `', NULL, NULL, 
                        NULL, (SELECT MAX(ctrl_id) + 1 FROM [SECURIT]..SIGBDCHQ), '` + codigoWBA + `', '0'`
            ,
          },
          type: 1,
        }
    
        fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'auth' : token,
          },
          body: JSON.stringify(obj)
          }).then((response) => response.json())
          .then((responseJson) => {
            console.log("addFavoredScript", responseJson);
            this.addToWBA('bordero', operationID);
        });
    });
  
  }

  addOperation() {
    if(this.props.favored == null){
      toast.error("É necessário selecionar um Favorecido");
      
      window.scrollTo(0, this.refs.Info);
   
      this.setState({ upperTab: 1 });

      return
    }

    for(var i = 0; i < this.props.itemList.length; i++) {
      
      var dia  = this.props.itemList[i].emissao.split("/")[0];
      var mes  = this.props.itemList[i].emissao.split("/")[1];
      var ano  = this.props.itemList[i].emissao.split("/")[2];

      var data = ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
      
      if(this.props.itemList[i].emissao == null) {
        toast.error("Um ou mais lançamentos não possuem data de vencimento.");

        return
      }
      else if (new Date(data) > new Date(now())){
        toast.error("Um ou mais lançamentos possuem a data de emissão maior que a data atual.");

        return
      }
    }
    
    for(var i = 0; i < this.props.itemList.length; i++) {
      
      var dia  = this.props.itemList[i].vencimento.split("/")[0];
      var mes  = this.props.itemList[i].vencimento.split("/")[1];
      var ano  = this.props.itemList[i].vencimento.split("/")[2];

      var data = ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
      
      if(this.props.itemList[i].vencimento == null) {
        toast.error("Um ou mais lançamentos não possuem data de vencimento.");

        return
      }
      else if (new Date(data) < new Date(now())){
        toast.error("Um ou mais lançamentos possuem a data de vencimento menor ou igual a data atual.");

        return
      }
    }

    if (this.props.carteiraID == 0){
      toast.error('Carteira não selecionada');      

      return
    }

    this.setState({
      loading: true
    })

    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9SRU1FU1NBOjA3MDcwNg==',
      fid: 131,
      data: {
        FK_CARTEIRA: this.props.carteiraID,
        FK_FAVORECIDO: this.props.favored,
        FK_CLIENTE: this.state.clientID,
      },
      files: this.props.fileList,
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == null) {          
          this.addFavoredScript(responseJson.data.result.id);
          // this.addOperationScript(responseJson.data.result.id);
          this.addOperationTitle(responseJson.data.result.id);
          this.addFileList(responseJson.data.result.id);
          this.createBillet(responseJson.data.result.id);
          this.addItemList(responseJson.data.result.id);

          toast.success("Borderô adicionada com sucesso!");
          console.log('Borderô adicionada com sucesso!');
          console.log('FK_CARTEIRA: ', this.props.carteiraID);
          console.log('FK_FAVORECIDO: ', this.props.favored);
          console.log('FK_CLIENTE: ', this.state.clientID);

          window.scrollTo(0, this.refs.Title);

          this.refreshPage();
        }
        else {
          toast.error("Opa.. Algo deu errado! Por favor, entre em contato com o suporte. Código do Erro: " + responseJson.error.code);
          console.log('Log Erro em addOperation', responseJson.error);
          console.log('FK_CARTEIRA: ', this.props.carteiraID);
          console.log('FK_FAVORECIDO: ', this.props.favored);
          console.log('FK_CLIENTE: ', this.state.clientID);
        }

        this.setState({
          loading: false
        })
    });
  }

  refreshPage() {
    this.setState({
      upperTab: 0,
      bottomTab: 1,
      refresh: !this.state.refresh,
      refreshDuplicata: !this.state.refreshDuplicata,
      refreshCheque: !this.state.refreshCheque
    },() => {this.props.setItemList([])});
  }

  validateCPF(cpf) {	
    cpf = cpf.replace(/[^\d]+/g,'');

    if(cpf == '') 
      return false;	
    
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		

    let add = 0;	
    
    for (var i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
    
    let rev = 11 - (add % 11);	
    
    if (rev == 10 || rev == 11)		
      rev = 0;	
    
    if (rev != parseInt(cpf.charAt(9)))		
      return false;		

    add = 0;	

    for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    
    rev = 11 - (add % 11);	
  
    if (rev == 10 || rev == 11)	
      rev = 0;	
  
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    
    return true;   
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') 
      return false;
     
    if (cnpj.length != 14)
        return false;
 
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      
      if (pos < 2)
        pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(1))
      return false;
           
    return true;
  }

  validateChequeItem() {
    if (!this.props.documentoCheque || !this.props.documentoCheque.match(/\d/g)){
      toast.error('CPF/CNPJ deve ser preenchido.');
      return false
    }

    if (this.props.documentoCheque.match(/\d/g).length > 11){
      if (!this.validateCNPJ(this.props.documentoCheque)){
        toast.error('CNPJ Invalido.');
        return false
      }
    }
    else {
      if (!this.validateCPF(this.props.documentoCheque)){
        toast.error('CPF Invalido.');
        return false
      }
    }

    if(this.props.nomeCheque == '') {
      toast.error('A Razão Social do sacado deve ser preenchida.');
      return false
    }
    
    if(this.props.emissaoCheque == null || this.props.emissaoCheque == '') {
      toast.error('Preencha data de emissão.');
      
      return false
    }
    
    if(this.props.vencimentoCheque == null || this.props.vencimentoCheque == '') {
      toast.error('Preencha data de vencimento.');

      return false
    }

    return true
  }

  validateDuplicataItem() {
    if (!this.props.documentoDuplicata || !this.props.documentoDuplicata.match(/\d/g)){
      toast.error('CPF/CNPJ deve ser preenchido.');
      return false
    }

    if (this.props.documentoDuplicata.match(/\d/g).length > 11){
      if (!this.validateCNPJ(this.props.documentoDuplicata)){
        toast.error('CNPJ Invalido.');
        return false
      }
    }
    else {
      if (!this.validateCPF(this.props.documentoDuplicata)){
        toast.error('CPF Invalido.');
        return false
      }
    }

    if(this.props.nomeDuplicata == '') {
      toast.error('A Razão Social do sacado deve ser preenchida.');
      return false
    }

    if(this.props.vencimentoDuplicata == null || this.props.vencimentoDuplicata == '') {
      toast.error('Preencha data de vencimento.');

      return false
    }

    if(this.props.emissaoDuplicata == null || this.props.emissaoDuplicata == '') {
      toast.error('Preencha data de emissão.');

      return false
    }

    return true
  }

  validadeRegisteredCheque() {
    for (var i = 0; i < this.props.drawnList.length; i++) {
      if (this.props.documentoCheque == this.props.drawnList[i].documento)
        return true 
    }

    toast.error('CPF/CNPJ não cadastrado.');

    return false 
  }

  validadeRegisteredDuplicata() {
    for (var i = 0; i < this.props.drawnList.length; i++) {
      if (this.props.documentoDuplicata == this.props.drawnList[i].documento)
        return true 
    }

    toast.error('CPF/CNPJ não cadastrado.');
    
    return false 
  }

  addToItemList() {
    var list = this.props.itemList;
    let item = Object.create({}, ItemObject);

    if (this.state.bottomTab == 1){
      if (!this.validateChequeItem())
        return
      // if (!this.validadeRegisteredCheque())
      //   return
      item.tipoItem = 100;
      item.documento = this.props.documentoCheque;
      item.nome = this.props.nomeCheque;
      item.valor = this.props.valorCheque;
      item.vencimento = new Date(this.props.vencimentoCheque.replace(/-/g, '\/')).toLocaleDateString();
      item.emissao = new Date(this.props.emissaoCheque.replace(/-/g, '\/')).toLocaleDateString();
      item.nroDocumento = this.props.nroDocumentoCheque;
      item.documentIndex = -1;
      item.codigoWBA = this.props.codigoWBACheque;
      item.logradouro = this.props.logradouroCheque;
      item.numero = this.props.numeroCheque;
      item.bairro = this.props.bairroCheque;
      item.municipio = this.props.municipioCheque;
      item.uf = this.props.ufCheque;

      
      this.setState({ refreshCheque: !this.state.refreshCheque});
    }
    else if (this.state.bottomTab == 2){
      if (!this.validateDuplicataItem())
        return
      // if (!this.validadeRegisteredDuplicata())
      //   return
      item.tipoItem = 101;
      item.documento = this.props.documentoDuplicata;
      item.nome = this.props.nomeDuplicata;
      item.valor = this.props.valorDuplicata;
      item.vencimento = new Date(this.props.vencimentoDuplicata.replace(/-/g, '\/')).toLocaleDateString();
      item.emissao = new Date(this.props.emissaoDuplicata.replace(/-/g, '\/')).toLocaleDateString();
      item.nroDocumento = this.props.nroDocumentoDuplicata;
      item.tipoDuplicata = this.props.tipoDuplicata;
      item.desconto = this.props.desconto;
      item.descontoPorCento = this.props.descontoPorCento;
      item.documentIndex = -1;
      item.codigoWBA = this.props.codigoWBADuplicata;
      item.logradouro = this.props.logradouroDuplicata;
      item.numero = this.props.numeroDuplicata;
      item.bairro = this.props.bairroDuplicata;
      item.municipio = this.props.municipioDuplicata;
      item.uf = this.props.ufDuplicata;

      this.setState({ refreshDuplicata: !this.state.refreshDuplicata});
    }

    this.AddSacado(item);

    list.push(item);
    this.props.setItemList(list);
  }

  AddSacado(Item) {
    for (var i = 0; i < this.props.drawnList.length; i++) {
      if (Item.documento == this.props.drawnList[i].documento)
        return 
    }

    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9TQUNBRE9TOjA2ODkwOA==',
      fid: 148,
      data: {
        FK_CLIENTE: this.state.clientID,

        DOCUMENTO: Item.documento, 
        RAZAO_SOCIAL: Item.nome, 

        LOGRADOURO: Item.logradouro, 
        CIDADE: Item.municipio, 
        NUMERO: Item.numero, 
        BAIRRO: Item.bairro, 
        ESTADO: Item.uf,
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("AddSacado", responseJson);

        this.props.setDrawnList(this.props.credencials.user_id);

        toast.success('Sacado incluído com sucesso!');
    });
  }

  removeItemFromList(index) {
    var list = this.props.itemList;

    list.splice(index, 1);

    this.props.setItemList(list);
  }

  handleOpenModal(event, modalId) {
    this.setState({
      openedModalId: modalId,
    });
  }

  handleCloseItemEditModal() {
    this.setState({
      openedModalId: null,
    });
  }

  formatMoney(number) {
    if (typeof number == 'string')
      number = parseFloat(number.replace('.', '').replace(',', '.'))

    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }

  render() {
    const { openedModalId } = this.state;

    return (
      <div style={{display: 'flex'}}>
        {this.state.loading ? 
          <div style={{width: "calc(100% - 354px)", height: "100%", position: "absolute"}}>
            <div style={{paddingTop: '45%', paddingLeft: '50%', zIndex: "100", position: "absolute"}}>
              <CircularProgress />
            </div>
          </div>
        : 
          <div></div>
        }
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" align="left" ref="Title" style={{fontFamily: 'lovelo'}}>
              Nova Operação
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={this.state.loading ? "loadingPaper" : "normalPaper" }>
              <div className="paperWrapper" style={{paddingLeft: "20px", paddingRight: "20px", color: '#32438B'}}>
                <Typography variant="h6" style={{padding: "20px"}}>
                  Informações da Remessa
                </Typography>
                <Divider />
                <div className="tabWrapper" style={{paddingTop: "20px"}} ref="Info">
                  <AppBar position="static" style={{backgroundColor: '#D0D0D0', borderRadius:"50px"}}>
                    <Tabs centered variant="fullWidth" value={this.state.upperTab} onChange={this.handleChangeUpperTab} TabIndicatorProps={{style: {background:'#3B86FF', height: '100%', borderRadius:"50px",zIndex: -100}}}>
                      <Tab label="Informações" className={this.state.upperTab == 0 ? "tabItemSelected" : "tabItem" }/>
                      <Tab label="Favorecidos" className={this.state.upperTab == 1 ? "tabItemSelected" : "tabItem" }/>
                    </Tabs>
                  </AppBar>
                  <div value={this.state.upperTab} hidden={this.state.upperTab != 0}>
                    <Information refresh={this.state.refresh} />
                  </div>
                  <div value={this.state.upperTab} hidden={this.state.upperTab != 1}>
                    <FavoredList refresh={this.state.refresh} changeUpperTabProp={this.changeUpperTabProp}/>
                  </div>
                </div>
                <AppBar position="static" style={{backgroundColor: '#D0D0D0', borderRadius:"50px"}}>
                  <Tabs centered variant="fullWidth" value={this.state.bottomTab} onChange={this.handleChangeBottomTab} TabIndicatorProps={{style: {background:'#3B86FF', height: '100%', borderRadius:"50px",zIndex: -100}}}>
                    <Tab disabled label="Importação" className={this.state.bottomTab == 0 ? "tabItemSelected" : "tabItem" } />
                    <Tab label="Cheque" className={this.state.bottomTab == 1 ? "tabItemSelected" : "tabItem" }/>
                    <Tab disabled label={"Duplicata"} className={this.state.bottomTab == 2 ? "tabItemSelected" : "tabItem" }/>
                  </Tabs>
                </AppBar>
                <div value={this.state.bottomTab} hidden={this.state.bottomTab != 0}>
                  <ImportFile refresh={this.state.refresh}/>                 
                </div>
                <div value={this.state.bottomTab} hidden={this.state.bottomTab != 1}>
                  <BankCheck refresh={this.state.refreshCheque}/>
                </div>
                <div value={this.state.bottomTab} hidden={this.state.bottomTab != 2}>
                  <Duplicate refresh={this.state.refreshDuplicata}/>
                </div>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{paddingBottom: "20px"}}>
                    <Box p={1}>
                      <Button 
                        hidden={this.state.bottomTab == 0 ? true : false} 
                        // disabled
                        variant="contained" 
                        color="primary" 
                        onClick={this.addToItemList}
                      >
                        Adicionar
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{marginBottom: '20px'}} hidden={this.props.itemList == 0 ? true : false} />
                  <div style={{paddingBottom: "20px"}}>
                    <Paper hidden={this.props.itemList == 0 ? true : false} style={{ boxShadow: "1px 1px 5px lightGray"}}>
                      <Table>
                        <TableHead style={{backgroundColor: "#f5f6fa"}}>
                          <TableRow>
                            <TableCell align="center">Tipo</TableCell>
                            <TableCell align="center">CPF/CNPJ</TableCell>
                            <TableCell align="center">Sacado</TableCell>
                            <TableCell align="center">Nro. Doc.</TableCell>
                            <TableCell align="center">Valor</TableCell>
                            <TableCell align="center">Emissão</TableCell>
                            <TableCell align="center">Vencimento</TableCell>
                            <TableCell align="center">Editar</TableCell>
                            <TableCell align="center">Remover</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.itemList.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{row.tipoItem == 100 ? "Cheque" : "Duplicata"}</TableCell>
                              <TableCell align="center" style={{width: '500px'}}>{row.documento}</TableCell>
                              <TableCell align="center">{row.nome}</TableCell>
                              <TableCell align="center">{row.nroDocumento}</TableCell>
                              <TableCell align="center">{this.formatMoney(row.valor)}</TableCell>
                              <TableCell align="center">{row.emissao}</TableCell>
                              <TableCell align="center">{row.vencimento}</TableCell>
                              <TableCell align="center"><a href="#/dashboard" onClick={(e) => this.handleOpenModal(e, "listItem" + index)}><EditIcon /></a></TableCell>
                              <TableCell align="center"><a href="#/dashboard" onClick={() => this.removeItemFromList(index)} ><CloseIcon color="error" /></a></TableCell>
                            </TableRow>
                          ))
                          }
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{paddingBottom: "20px"}}>
              <Box p={1}>
                <Button variant="contained" onClick={this.refreshPage}>
                  Cancelar
                </Button>
              </Box>
              <Box p={1}>
                <Button variant="contained" color="primary" onClick={this.addOperation}>
                  Enviar
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {this.props.itemList.map((row, index) => (
          <Modal 
            id={"listItem" + index} 
            open={openedModalId === "listItem" + index} 
            onClose={this.handleCloseItemEditModal} 
            className="modalPaper"
          >
            <div>
              <EditItemModal index={index} handleCloseModal={this.handleCloseItemEditModal} isXML={row.urlXML ? true : false} />
            </div>
          </Modal>
        ))
        }
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    favored: state.NewOperationReducer.favored,
    carteiraID: state.NewOperationReducer.carteiraID,
    
    documentoCheque: state.NewOperationReducer.documentoCheque,
    nomeCheque: state.NewOperationReducer.nomeCheque,
    valorCheque: state.NewOperationReducer.valorCheque,
    vencimentoCheque: state.NewOperationReducer.vencimentoCheque,
    nroDocumentoCheque: state.NewOperationReducer.nroDocumentoCheque,
    emissaoCheque: state.NewOperationReducer.emissaoCheque,
    codigoWBACheque: state.NewOperationReducer.codigoWBACheque,
    logradouroCheque: state.NewOperationReducer.logradouroCheque,
    bairroCheque: state.NewOperationReducer.bairroCheque,
    numeroCheque: state.NewOperationReducer.numeroCheque,
    municipioCheque: state.NewOperationReducer.municipioCheque,
    ufCheque: state.NewOperationReducer.ufCheque,
    

    documentoDuplicata: state.NewOperationReducer.documentoDuplicata,
    nomeDuplicata: state.NewOperationReducer.nomeDuplicata,
    valorDuplicata: state.NewOperationReducer.valorDuplicata,
    vencimentoDuplicata: state.NewOperationReducer.vencimentoDuplicata,
    nroDocumentoDuplicata: state.NewOperationReducer.nroDocumentoDuplicata,
    emissaoDuplicata: state.NewOperationReducer.emissaoDuplicata,
    tipoDuplicata: state.NewOperationReducer.tipoDuplicata,
    desconto: state.NewOperationReducer.desconto,
    descontoPorCento: state.NewOperationReducer.descontoPorCento,    
    codigoWBADuplicata: state.NewOperationReducer.codigoWBADuplicata,
    logradouroDuplicata: state.NewOperationReducer.logradouroDuplicata,
    bairroDuplicata: state.NewOperationReducer.bairroDuplicata,
    numeroDuplicata: state.NewOperationReducer.numeroDuplicata,
    municipioDuplicata: state.NewOperationReducer.municipioDuplicata,
    ufDuplicata: state.NewOperationReducer.ufDuplicata,

    fileList: state.NewOperationReducer.fileList,
    itemList: state.NewOperationReducer.itemList,

    drawnList: state.NewOperationReducer.drawnList,
  };
}

export default connect(mapStateToProps, { setItemList, setDrawnList } )(NewOperation);
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '../Style.css';
// @material-ui/core
import { Divider, Typography, Grid, CircularProgress, Paper, Modal, Box } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

class AppbarUserControl extends Component {
  constructor(props) {
    super(props);

    toast.configure();

    this.state = {
      userName: '',
      userCPNJ: '',
      openedModal: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    };

    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:PAINEL_USUARIO',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        this.setState({
          userCPNJ: responseJson.list[0].CNPJ,
          userName: this.props.credencials.full_name
        });
    });
  }

  logOut() {
    sessionStorage.clear();

    window.location.href = "/";
  }

  handleModal() {
    this.setState({
      openedModal: !this.state.openedModal
    })
  }

  changePassword() {
    if (this.state.currentPassword == ''){
      toast.error('A Senha atual deve ser preenchida');

      return
    }

    if (this.state.newPassword == ''){
      toast.error('A Nova Senha deve ser preenchida');

      return
    }

    if (this.state.newPasswordConfirmation == ''){
      toast.error('A Confirmação da Nova Senha deve ser preenchida');

      return
    }

    if (this.state.newPasswordConfirmation != this.state.newPassword){
      toast.error('A Confirmação da Nova Senha está incorreto');

      return
    }

    var token = this.props.credencials.auth_ticket;

    var details = {
      'oldpassword': this.state.currentPassword,
      'newpassword': this.state.newPassword,
    };
    
    var formBody = [];

    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      
      formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");

    fetch('https://apps.blueprojects.com.br/nobel/Security/ChangePasswordJson', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'auth' : token,
      },
      body: formBody
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log('changePassword', responseJson);

        if (responseJson.error == null){
          toast.success('Senha alterada com sucesso!');

          this.setState({
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
          })
        }
        else{
          toast.error(responseJson.error.message);
        }
    });
  }

  render() {
    return (
      <div>
        {this.state.userName == '' ? 
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '175px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
        :
        <div>
          <Typography className="UserPanel" variant="subtitle1" align="center">
            {this.state.userName}
          </Typography>
          <Divider />
          <Typography className="UserPanel" variant="subtitle2" align="center">
            CNPJ: {this.state.userCPNJ}
          </Typography>
          <Divider />
          <a href="#/dashboard" onClick={this.handleModal}>
            <Typography className="UserPanel" variant="body1" align="center">
              Configurações
            </Typography>
          </a>
          <Divider />
          <a href="#/dashboard" onClick={this.logOut}>
            <Typography className="UserPanel" variant="body1" align="center">
              Sair
            </Typography>
          </a>
        </div>  
        }
        <Modal open={this.state.openedModal} onClose={this.handleModal} className="modalPaper">
          <div>
            <Paper className="modalPaper">
              <div className="modalPaperWrapper" style={{width: '500px' }}>
                <Grid container style={{paddingBottom: '20px'}}>
                  <Grid item justify="flex-end" alignItems="center" xs={11}>
                    <Typography variant="subtitle1" align="left">
                      Configurações
                    </Typography>
                  </Grid>
                  <Grid item justify="flex-end" alignItems="center" xs={1}>
                    <a href="#/dashboard" onClick={this.handleModal}>
                      <Box display="flex" flexDirection="row-reverse">
                        <CloseIcon />
                      </Box>
                    </a>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{paddingBottom: '10px'}}>
                    <a style={{fontSize: '22px'}}>
                      Trocar Senha
                    </a>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form-fields">
                      <a className="form-labels">
                        Senha Atual
                      </a>
                      <input className="form-control" type="password" placeholder="Senha Atual" onChange={(event) => this.setState({currentPassword: event.target.value})} value={this.currentPassword} />
                    </div>  
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form-fields">
                      <a className="form-labels">
                        Nova Senha
                      </a>
                      <input className="form-control" type="password" placeholder="Nova Senha" onChange={(event) => this.setState({newPassword: event.target.value})} value={this.newPassword} />
                    </div>  
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form-fields">
                      <a className="form-labels">
                        Confirmar Nova Senha
                      </a>
                      <input className="form-control" type="password"  placeholder="Confirmar Nova Senha" onChange={(event) => this.setState({newPasswordConfirmation: event.target.value})} value={this.newPasswordConfirmation} />
                    </div>  
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                      <Box p={0}>
                        <Button style={{fontFamily: 'Futura', fontWeight: '500'}} onClick={() => this.changePassword()}>Trocar Minha Senha</Button> 
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(AppbarUserControl);
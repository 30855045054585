import React, { Component } from 'react';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select } from '@material-ui/core';
// react-redux
import { connect } from 'react-redux';
import { setInformation } from '../../../../redux/actions/NewOperationActions';
import { toast } from 'react-toastify';

class carteiraObject {
  id = 0;
  carteira = '';
  fkCarteira = 0;
}

class Information extends Component {
  constructor(props) {
    super(props);

    toast.configure();

    this.state = {
      carteira: [],
      carteiraID: 0,

      itemQuantity: 0,
      bankCheckQuantity: 0,
      itemTotal: 0,
      bankCheckTotal: 0,
      duplicateTotal: 0,
    }
  }

  getCarteira() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:CARTEIRA',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var carteiraList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log("responseJsonCARTEIRA", responseJson)
        
        responseJson.list.map((entry, index) => {
          let carteiraItem = Object.create({}, carteiraObject);
          
          carteiraItem.id = entry.ID;
          carteiraItem.fkCarteira = entry.FK_CARTEIRA;
          carteiraItem.carteira = entry.CARTEIRA;
        
          carteiraList.push(carteiraItem);
        });

        this.setState({
          carteira: carteiraList,
        }, () => {
          if (this.state.carteira.length > 0){
            this.setState({
              carteiraID: this.state.carteira[0].fkCarteira
            }, () => {this.handleReducer()});
          }
          else
            toast.warn('Você não possui carteiras cadastradas, por favor, entre em contato com o suporte.');
        });
    });
  }

  componentDidMount() {
    this.getCarteira();
  }

  handleCarteiraChange = (event) => {
    this.setState({
      carteiraID: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleReducer() {
    this.props.setInformation(this.state.carteiraID);
  }

  componentWillReceiveProps(newProps) {
    if(newProps.refresh !== this.props.refresh) 
      this.refreshComponent();    

    this.handleResume(newProps.itemList)
  }

  handleResume(itemList) {
    let itemQuantity = 0;
    let bankCheckQuantity = 0;
    let itemTotal = 0;
    let bankCheckTotal = 0;
    let duplicateTotal = 0;

    itemQuantity = itemList.length;

    for(var i = 0; i < itemList.length; i++) {
      
      if (typeof itemList[i].valor === 'string')
        itemList[i].valor = parseFloat(itemList[i].valor.replace('.', '').replace(',', '.'))
        
      itemTotal = itemList[i].valor + itemTotal;

      if (itemList[i].tipoItem == 100){
        bankCheckTotal = itemList[i].valor + bankCheckTotal;
        bankCheckQuantity++;
      }
      else {
        duplicateTotal = itemList[i].valor + duplicateTotal;
      }
    }
    
    this.setState({
      itemQuantity: itemQuantity,
      bankCheckQuantity: bankCheckQuantity,
      itemTotal: itemTotal,
      bankCheckTotal: bankCheckTotal,
      duplicateTotal: duplicateTotal
    });
  }

  refreshComponent() {
    if (this.state.carteira.length > 0){
      this.setState({
        carteiraID: this.state.carteira[0].fkCarteira
      }, () => {this.handleReducer()});
    }
    else
      toast.warn('Você não possui carteiras cadastradas, por favor, entre em contato com o suporte.');
  }

  render() {
    return (
      <div style={{paddingTop: "50px", paddingBottom: "50px"}}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" p={1}>
                  <Box p={0}>
                    <Typography variant="subtitle1">
                      Carteira:
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <FormControl variant="outlined" fullWidth>
                  <Select native value={this.state.carteiraID} onChange={this.handleCarteiraChange} style={{height: "45px"}}>
                    {this.state.carteira.map((entry, index) => (
                      <option key={index} value={entry.fkCarteira}>{entry.carteira}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Grid container> 
                <Grid item xs style={{paddingTop: "20px", paddingBottom: "10px", paddingLeft: '20px'}}>
                  <Typography variant="h6">
                    Resumo
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{margin: '10px'}} />
                </Grid>
                <Grid item xs style={{paddingBottom: '10px'}}>
                  {this.state.itemQuantity == 0 ?
                  <div style={{paddingTop: "10px", paddingBottom: "10px", paddingLeft: '20px'}}>
                    <Typography variant="subtitle1">
                      Nenhum recebível foi digitado ou importado.
                    </Typography>
                  </div>
                  :
                    <div>
                      <Grid container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                <b>Quantidade de recebíveis:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                {this.state.itemQuantity}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                <b>Quantidade de Cheques:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                {this.state.bankCheckQuantity}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider style={{margin: '10px'}} />
                      <Grid container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                <b>Total Recebíveis:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                R$ {this.state.itemTotal.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                <b>Total Cheques:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                R$ {this.state.bankCheckTotal.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container style={{paddingTop: "10px", paddingBottom: "10px"}}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                <b>Total Duplicatas:</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                            <Box p={0}>
                              <Typography variant="subtitle1">
                                R$ {this.state.duplicateTotal.toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  }
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,

    itemList: state.NewOperationReducer.itemList
  };
}

export default connect(mapStateToProps, { setInformation } )(Information);
import { UPDATE_OPERATIONS_LIST, UPDATE_SELECTED_LIST } from '../constants/OperationsConstants';

const updateOperationsListEvent = (dispatch, data) => {;
  dispatch({ type: UPDATE_OPERATIONS_LIST, data });
}

export const updateOperationsList = (data) => 
{
  return dispatch => {
    updateOperationsListEvent(dispatch, data);
  }
}

const updateSelectedListEvent = (dispatch, data) => {;
  console.log('updateSelectedListEvent');
  
  dispatch({ type: UPDATE_SELECTED_LIST, data });
}

export const updateSelectedList = (data) => 
{
  console.log('updateSelectedList');

  return dispatch => {
    updateSelectedListEvent(dispatch, data);
  }
}


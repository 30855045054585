import React, { Component } from 'react';
import { Row, Col } from "react-bootstrap";
import img from '../../images/step3.png';
import img_upload from '../../images/upload.png';


import { addStep3 } from '../../redux/actions/PreCadastroActions';

import NumberFormat from 'react-number-format';

import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';




export class Step3 extends Component {

    constructor(props) {
        super(props);
        this.state = {action: '', bancoEnabled: false, fundoEnabled: false, securitizadoraEnabled: false,};
      }


      handleBanco(txt)
      {
        this.setState({banco: txt});

        var obj = this.state;
        obj.banco = txt;

        this.props.addStep3(obj);
      }

      handleFundo(txt)
      {
        this.setState({fundo: txt});

        var obj = this.state;
        obj.fundo = txt;

        this.props.addStep3(obj);
      }

      handleSecuritizadora(txt)
      {
        this.setState({securitizadora: txt});

        var obj = this.state;
        obj.securitizadora = txt;

        this.props.addStep3(obj);
      }

      handleDuplicatas(txt)
      {
        if (txt.floatValue < 0)
          txt.floatValue = 0;

        if (txt.floatValue > 100) 
          txt.floatValue = 100;
        
        this.setState({duplicatas: txt.floatValue});

        var obj = this.state;
        obj.duplicatas = txt.floatValue;

        this.props.addStep3(obj);
      }

      handleCheques(txt)
      {
        if (txt.floatValue < 0)
          txt.floatValue = 0;

        if (txt.floatValue > 100) 
          txt.floatValue = 100;

        this.setState({cheques: txt.floatValue});

        var obj = this.state;
        obj.cheques = txt.floatValue;

        this.props.addStep3(obj);
      }

      handleOutros(txt)
      {
        if (txt.floatValue < 0)
          txt.floatValue = 0;

        if (txt.floatValue > 100) 
          txt.floatValue = 100;

        this.setState({outros: txt.floatValue});

        var obj = this.state;
        obj.outros = txt.floatValue;

        this.props.addStep3(obj);
      }

      handleEscrow(txt)
      {
        if (txt.floatValue < 0)
          txt.floatValue = 0;

        if (txt.floatValue > 100) 
          txt.floatValue = 100;

        this.setState({escrow: txt.floatValue});

        var obj = this.state;
        obj.escrow = txt.floatValue;

        this.props.addStep3(obj);
      }

      handleOutrasOperacoes(txt)
      {
        this.setState({outrasOperacoes: txt});

        var obj = this.state;
        obj.outrasOperacoes = txt;

        this.props.addStep3(obj);
      }

      handleRestricoes(txt)
      {
        this.setState({restricoes: txt});

        var obj = this.state;
        obj.restricoes = txt;

        this.props.addStep3(obj);
      }



      handlePercEntrega(txt)
      {
        this.setState({percEntrega: txt});

        var obj = this.state;
        obj.percEntrega = txt;

        this.props.addStep3(obj);
      }

      handlePercEmbarcada(txt)
      {
        this.setState({percEmbarcado: txt});

        var obj = this.state;
        obj.percEmbarcado = txt;

        this.props.addStep3(obj);
      }

      handlePercPreFaturamento(txt)
      {
        this.setState({percPreFaturamento: txt});

        var obj = this.state;
        obj.percPreFaturamento = txt;

        this.props.addStep3(obj);
      }

      componentDidMount()
      {
          this.setState({duplicatas: this.props.duplicatas, cheques: this.props.cheques, escrow: this.props.escrow });
          this.props.handleBack();
      }

      render() {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{justifyContent: 'center', marginBottom: 20 }}>
                <img src={img} alt="car" style={{width: 45, height: 45, marginRight: 15}}/>
                <a className="step-title">Operações</a>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <a className="form-labels">*Seus principais Parceiros (Bancos\Fundos\Securitizadoras):</a>
                  <ul>
                    <li style={{marginBottom: 20, float: 'left', marginRight: 20, width: '100%' }}>
                      Banco 
                      <input class="form-control" type='text' value={this.props.banco} onChange={(event) => this.handleBanco(event.target.value)} />
                    </li>
                    <li style={{marginBottom: 20, float: 'left', marginRight: 20,     width: '100%' }}>
                      Fundo 
                      <input class="form-control" type='text'  value={this.props.fundo} onChange={(event) => this.handleFundo(event.target.value)} />
                    </li>
                    <li style={{marginBottom: 20, float: 'left', marginRight: 20,     width: '100%' }}>
                      Securitizadora 
                      <input class="form-control" type='text'  value={this.props.securitizadora} onChange={(event) => this.handleSecuritizadora(event.target.value)} />
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <a className="form-labels">Tipos de Operações:</a>
                    <ul>
                      <li style={{marginBottom: 20, }}>*Duplicatas <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true}  value={this.state.duplicatas}  onValueChange={(event) => this.handleDuplicatas(event)} class="form-control" type='text' /></li>
                      <li style={{marginBottom: 20,}}>*Cheques: <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true}  value={this.state.cheques}  onValueChange={(event) => this.handleCheques(event)} class="form-control" type='text' /></li>     
                      <li style={{marginBottom: 20, }}>Escrow <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true}  value={this.state.escrow} onValueChange={(event) => this.handleEscrow(event)} class="form-control" type='text' /></li>
                      <li style={{marginBottom: 20, }}>Outras <NumberFormat decimalSeparator={','} placeholder="00,00%" decimalScale={2} fixedDecimalScale={true}  value={this.state.outros} onValueChange={(event) => this.handleOutros(event)} class="form-control" type='text' /></li>
                    </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <a className="form-labels">Descreva outras operações:</a>
                  <textarea value={this.props.outrasOperacoes}  onChange={(event) => this.handleOutrasOperacoes(event.target.value)}  rows="4" class="form-control" type='text' />
                  <a className="form-labels">Esclarecimento sobre eventuais restrições:</a>
                  <textarea value={this.props.restricoes}  onChange={(event) => this.handleRestricoes(event.target.value)}  rows="4" class="form-control" type='text' />
                  <div style={{height: '20px'}} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
}

function mapStateToProps(state){

    console.log(state);
  
    return{
      nome: state.PreCadastroReducer.nome,
      telefone: state.PreCadastroReducer.telefone,
      email: state.PreCadastroReducer.email,
      celular: state.PreCadastroReducer.celular,

      razaoSocial: state.PreCadastroReducer.razaoSocial,
      cnpj: state.PreCadastroReducer.cnpj,
      telefoneEmpresa: state.PreCadastroReducer.telefoneEmpresa,  
      emailEcpf: state.PreCadastroReducer.emailEcpf,
      numeroFuncionarios: state.PreCadastroReducer.numeroFuncionarios,
      valorMedioTitulos: state.PreCadastroReducer.valorMedioTitulos,   
      faturamento: state.PreCadastroReducer.faturamento,
      prazoMedio: state.PreCadastroReducer.prazoMedio,  

      fundo: state.PreCadastroReducer.fundo,
      banco: state.PreCadastroReducer.banco,
      securitizadora: state.PreCadastroReducer.securitizadora,

      duplicatas: state.PreCadastroReducer.duplicatas,
      cheques: state.PreCadastroReducer.cheques,
      escrow: state.PreCadastroReducer.escrow,

      outrasOperacoes: state.PreCadastroReducer.outrasOperacoes,
      restricoes: state.PreCadastroReducer.restricoes,
    };
  }
  
  export default connect(mapStateToProps, { addStep3 } )(Step3);
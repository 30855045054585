import { SET_COMPONENT } from '../constants/DashboardConstants';

const INITIAL_STATE = {
  currentComponent: 0
}

export default (state = INITIAL_STATE, action) => {

  console.log(action.type);
  switch(action.type) {
    case SET_COMPONENT:
      console.log(action.data);
      return {
        ...state,
        currentComponent: action.data,
      }
  }

  return state;
}
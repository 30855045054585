import React, { Component } from 'react';
import '../../../components/Style.css';
// @material-ui/core
import { Paper, Typography, Divider, Grid, Box, TextField, Button } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { updateInformation } from '../../../../../redux/actions/WelcomeCardAction';
import { updateList } from '../../../../../redux/actions/PendenciesListActions';
// react-toastify
import { toast } from 'react-toastify';



class PendenciesValueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textValue: ''
    };

    this.saveFile = this.saveFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({textValue: event.target.value});
  }

  saveFile(){
    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9DTElFTlRFX1BFTkRFTkNJQVM6MTUyMDY2',
      fid: 125,
      data: {
        VALOR: this.state.textValue,
        FLG_PREENCHIDO: 1
      },
      type: 1,
      key: {
        id: this.props.pendencyID
      }
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        this.props.updateList(this.props.credencials.user_id);
        this.props.updateInformation(this.props.credencials);

        if(responseJson.error == null) {
          toast.success("Valor enviado com sucesso!");
        }
        else {
          toast.error(responseJson.error.message);
          console.log('ERRO: ', responseJson);
        }
    });

    this.props.handleOpenModal();
  }

  render() {
    return (
      <Paper className="modalPaper">
        <div className="modalPaperWrapper">
          <Grid container>
            <Grid item justify="flex-end" alignItems="center" xs={11} style={{paddingBottom: '10px'}}>
              <Typography variant="subtitle1" align="left">
                Pendências {this.props.pendencyName}
              </Typography>
            </Grid>
            <Grid item justify="flex-end" alignItems="center" xs={1}>
              <a href="#/dashboard" onClick={this.props.handleOpenModal}>
                <Box display="flex" flexDirection="row-reverse">
                  <CloseIcon />
                </Box>
              </a>
            </Grid>
          </Grid>
          <Divider />
          <Grid container style={{paddingTop: '10px', width: '500px'}}> 
            <Grid item xs={10}>
              <form noValidate autoComplete="off">
                <TextField 
                  placeholder="Valor.."
                  variant="outlined"
                  fullWidth
                  value={this.state.textValue} 
                  onChange={this.handleChange} 
                  InputProps={{ style: { height: "45px", width: '95%' } }} />
              </form>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{width: '100%', height: '100%'}}>
                <Box p={0}>
                  <Button onClick={this.saveFile} variant="contained" color="primary" disabled={this.state.textValue.length == 0}>
                    Enviar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    pendencyID: state.DashboardHomeReducer.pendencyID
  };
}

export default connect(mapStateToProps, { updateInformation, updateList } )(PendenciesValueModal);
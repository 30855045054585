import React, { Component } from 'react';
// @material-ui/core
import { Grid, Typography } from '@material-ui/core';
// core components
import WelcomeCard from './WelcomeCard.js';
import PendenciesList from './Pendencies/PendenciesList.js';
import DoughnutChart from './DoughnutChart.js';
import DashboardAreaChart from './DashboardAreaChart.js';
import DashboardBox from './DashboardBox.js';

import { connect } from 'react-redux';
import DashboardBoxMensage from './DashboardBoxMensage.js';


class DashboardHome extends Component {
  constructor(props) {

    

    super(props);

    this.state = {
      bordero: 0,
      borderoPercent: 0,
    }
  }

  
  render() {
    return (
      <div style={{display: 'flex'}}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <WelcomeCard />
          </Grid>
          <Grid item md={12} style={{paddingTop: '35px', paddingBottom: '20px'}}>
            <Typography variant="h5" align="left" ref="Title" style={{fontFamily: 'lovelo'}}>
              Visão Geral
            </Typography>
          </Grid>
          <Grid item md={8}>
            <PendenciesList style={{height: 250}} />
          </Grid>
          <Grid item md={4}>
            <DoughnutChart  style={{height: 250}}  />
          </Grid>
          <Grid item md={4}>
            <DashboardBox />
          </Grid>
          <Grid item md={8}>
            <DashboardBoxMensage />
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(DashboardHome);
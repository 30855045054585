import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment, MenuItem, Checkbox } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
// react-redux
import { connect } from 'react-redux';
import { setItemList } from '../../../../redux/actions/NewOperationActions';
// react-currency-format
import CurrencyFormat from 'react-currency-format';
// react-input-mask
import InputMask from "react-input-mask";
import DrawnRegistration from './DrawnRegistration';
// react-toastify
import { toast } from 'react-toastify';

class ItemObject {
  tipoItem = 0;
  documento = '';
  nome = '';
  valor = 0;
  vencimento = null;
  emissao = null;
  nroDocumento = '';
  aceite = false;
  tipoDuplicata = "100";
  desconto = 0;
  descontoPorCento = 0;
}

class tipoDuplicataObject {
  id = '';
  tipo = '';
}

class documento {
  documento = '';
  nome = '';
}

var states = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];

class EditItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoItem: null,
      documento: null,
      nome: null,
      valor: null,
      vencimento: null,
      emissao: null,
      nroDocumento: null,
      aceite: null,
      tipoDuplicata: null,
      desconto: null,
      descontoPorCento: null,
      logradouro: null,
      numero: null,
      bairro: null,
      municipio: null,
      uf: null,

      documentoMask: '999.999.999-999',
      tipoDuplicataList: [],
      loading: true,
      drawnRegistrationModal: false,
    };

    this.editItemList = this.editItemList.bind(this);
    this.handleDrawnRegistrationModal = this.handleDrawnRegistrationModal.bind(this);
  }

  valorMask = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyFormat
        {...other}
        getInputRef={inputRef}
        value={this.state.valor} 
        onValueChange={values => {
          this.setState({
            valor: values.floatValue,
          });
        }}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        allowNegative={false}
        fixedDecimalScale
      />
    );
  }

  descontoMask = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyFormat
        {...other}
        getInputRef={inputRef}
        value={this.state.desconto} 
        onValueChange={values => {
          this.setState({
            desconto: values.floatValue,
          });
        }}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        allowNegative={false}
        fixedDecimalScale
      />
    );
  }

  getTipoDuplicata() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:TIPO_DUPLICATA',
    }

    var tipoDuplicataItemList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log("responseJsonTipoDuplicata", responseJson);

        responseJson.list.map((entry, index) => {
          let tipoDuplicataItem = Object.create({}, tipoDuplicataObject);
          
          tipoDuplicataItem.id = entry.ID;
          tipoDuplicataItem.tipo = entry.TIPO;

          tipoDuplicataItemList.push(tipoDuplicataItem);
        });

        this.setState({
          tipoDuplicataList: tipoDuplicataItemList
        });
    });
  }

  getDate(date){
    let dd = date.substring(0, 2);
    let mm = date.substring(3, 5);
    let yyyy = date.substring(6, 10);
    let result = yyyy + '-' + mm + '-' + dd;
    
    return result;
  }

  componentDidMount() {
    this.getTipoDuplicata();

    if (this.props.itemList[this.props.index].documento.match(/\d/g) && this.props.itemList[this.props.index].documento.match(/\d/g).length > 11){
      this.setState({
        documentoMask: '99.999.999/9999-99'
      })
    }
    else {
      this.setState({
        documentoMask: '999.999.999-999'
      })
    }

    this.setState({
      tipoItem: this.props.itemList[this.props.index].tipoItem,
      documento: this.props.itemList[this.props.index].documento,
      nome: this.props.itemList[this.props.index].nome,
      valor: this.props.itemList[this.props.index].valor,
      vencimento: this.getDate(this.props.itemList[this.props.index].vencimento),
      emissao: this.getDate(this.props.itemList[this.props.index].emissao),
      nroDocumento: this.props.itemList[this.props.index].nroDocumento,
      aceite: this.props.itemList[this.props.index].aceite,
      tipoDuplicata: this.props.itemList[this.props.index].tipoDuplicata,
      desconto: this.props.itemList[this.props.index].desconto,
      descontoPorCento: (Math.round(this.props.itemList[this.props.index].descontoPorCento * 100) / 100).toFixed(2), // gambiarra pra ficar 0.00,
      logradouro: this.props.itemList[this.props.index].logradouro,
      numero: this.props.itemList[this.props.index].numero,
      bairro: this.props.itemList[this.props.index].bairro,
      municipio: this.props.itemList[this.props.index].municipio,
      uf: this.props.itemList[this.props.index].uf,
    }, () => {this.setState({ loading: false })});
  }

  handleDocumentoChange = (event) => {
    this.setState({
      documento: event.target.value,
    }, () => {
      if (this.state.documento.match(/\d/g) && this.state.documento.match(/\d/g).length > 11){
        this.setState({
          documentoMask: '99.999.999/9999-99'
        })
      }
      else {
        this.setState({
          documentoMask: '999.999.999-999'
        })
      }
    })
  }

  handleNomeChange = (event) => {
    this.setState({
      nome: event.target.value,
    })
  }

  handleValorChange = (event) => {
    this.setState({
      valor: event.target.value,
    })
  }

  setDate(date) {
    var dd = String(new Date(date).getDate()).padStart(2, '0');
    var mm = String(new Date(date).getMonth() + 1).padStart(2, '0');
    var yyyy = String(new Date(date).getFullYear()).padStart(4, '0');
    
    yyyy = yyyy.substring(0, 4);
    
    var result = yyyy + '-' + mm + '-' + dd;
    
    return result;
  }

  handleVencimentoChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      event.target.value = this.setDate(event.target.value);
    }

    this.setState({
      vencimento: event.target.value,
    })
  }

  handleEmissaoChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      event.target.value = this.setDate(event.target.value);
    }

    this.setState({
      emissao: event.target.value,
    })
  }

  handleNroDocumentoChange = (event) => {
    this.setState({
      nroDocumento: event.target.value,
    })
  }

  handleAceiteChange = () => {
    this.setState({
      aceite: !this.state.aceite,
    })
  }

  handleTipoDuplicataChange = (event) => {
    this.setState({
      tipoDuplicata: event.target.value,
    })
  }

  handleDescontoChange = (event) => {
    let descontoPorCento = 0;

    if (event.target.value % 1 > 0 && event.target.value.toString().split(".")[1].length > 2)
      event.target.value = parseFloat(event.target.value).toFixed(2);
    
    if (event.target.value < 0)
      event.target.value = 0;

    if (event.target.value > parseFloat(this.state.valor.toString().replace('.', '').replace(',', '.'))) 
      event.target.value = parseFloat(this.state.valor.toString().replace('.', '').replace(',', '.'));
    
    if (this.state.valor > 0)
      descontoPorCento = (parseFloat(event.target.value.toString().replace('.', '').replace(',', '.')) * 100) / parseFloat(this.state.valor.toString().replace('.', '').replace(',', '.'))

    this.setState({
      desconto: event.target.value,
      descontoPorCento: descontoPorCento.toFixed(2),
    })
  }

  handleDescontoPorCentoChange = (event) => {
    let desconto = 0;

    if (event.target.value % 1 > 0 && event.target.value.toString().split(".")[1].length > 2)
      event.target.value = parseFloat(event.target.value).toFixed(2);
      
    if (event.target.value < 0)
      event.target.value = 0;

    if (event.target.value > 100) 
      event.target.value = 100;
    
    if (this.state.valor > 0)
      desconto = (parseFloat(this.state.valor.toString().replace('.', '').replace(',', '.')) * event.target.value) / 100
    
    this.setState({
      descontoPorCento: event.target.value,
      desconto: desconto,
    })
  }

  handleLogradouroChange = (event) => {
    this.setState({
      logradouro: event.target.value,
    });
  }

  handleBairroChange = (event) => {
    this.setState({
      bairro: event.target.value,
    });
  }

  handleNumeroChange = (event) => {
    this.setState({
      numero: event.target.value,
    });
  }

  handleMunicipioChange = (event) => {
    this.setState({
      municipio: event.target.value,
    });
  }

  handleUfChange = (event) => {
    this.setState({
      uf: event.target.value,
    });
  }

  editItemList() {
    var list = this.props.itemList;
    let item = Object.create({}, ItemObject);

    if (!this.checkItem())
      return
    // if (!this.checkRegistered())
    //   return

    item.tipoItem = this.props.itemList[this.props.index].tipoItem;
    item.documento = this.state.documento;
    item.nome = this.state.nome;
    item.valor = this.state.valor;
    item.vencimento = new Date(this.state.vencimento.replace(/-/g, '\/')).toLocaleDateString();
    item.emissao = new Date(this.state.emissao.replace(/-/g, '\/')).toLocaleDateString();
    item.nroDocumento = this.state.nroDocumento;
    item.aceite = this.state.aceite;
    item.tipoDuplicata = this.state.tipoDuplicata;
    item.desconto = this.state.desconto;
    item.descontoPorCento = this.state.descontoPorCento;
    item.logradouro = this.state.logradouro;
    item.numero = this.state.numero;
    item.bairro = this.state.bairro;
    item.municipio = this.state.municipio;
    item.uf = this.state.uf;

    list.splice(this.props.index, 1, item);

    this.props.setItemList(list);
    this.props.handleCloseModal();
  }

  checkItem() {
    if (!this.state.documento || !this.state.documento.match(/\d/g)){
      toast.error('CPF/CNPJ deve ser preenchido.');
      return false
    }

    if (this.state.documento.match(/\d/g).length > 11){
      if (!this.validateCNPJ(this.state.documento)){
        toast.error('CNPJ Invalido.');
        return false
      }
    }
    else {
      if (!this.validateCPF(this.state.documento)){
        toast.error('CPF Invalido.');
        return false
      }
    }

    if(this.state.vencimento == null || this.state.vencimento == '' ) {
      toast.error('Preencha data de vencimento.');

      return false
    }

    if(this.state.emissao == null || this.state.emissao == '') {
      toast.error('Preencha data de emissão.');

      return false
    }

    return true
  }

  checkRegistered() {
    for (var i = 0; i < this.props.drawnList.length; i++) {
      if (this.state.documento.replace(/[^\d]+/g,'') == this.props.drawnList[i].documento.replace(/[^\d]+/g,''))
        return true 
    }

    toast.error('CPF/CNPJ não cadastrado.');

    return false 
  }

  validateCPF(cpf) {	
    cpf = cpf.replace(/[^\d]+/g,'');

    if(cpf == '') 
      return false;	
    
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		

    let add = 0;	
    
    for (var i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
    
    let rev = 11 - (add % 11);	
    
    if (rev == 10 || rev == 11)		
      rev = 0;	
    
    if (rev != parseInt(cpf.charAt(9)))		
      return false;		

    add = 0;	

    for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    
    rev = 11 - (add % 11);	
  
    if (rev == 10 || rev == 11)	
      rev = 0;	
  
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    
    return true;   
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') 
      return false;
     
    if (cnpj.length != 14)
        return false;
 
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      
      if (pos < 2)
        pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(1))
      return false;
           
    return true;
  }

  handleSelectDocument = (event) => {
    if (event.target.value.documento.match(/\d/g) && event.target.value.documento.match(/\d/g).length > 11){
      this.setState({
        documentoMask: '99.999.999/9999-99'
      })
    }
    else {
      this.setState({
        documentoMask: '999.999.999-999'
      })
    }

    this.setState({
      documento: event.target.value.documento,
      nome: event.target.value.razSoc
    })
  }

  handleDrawnRegistrationModal() {
    this.setState({
      drawnRegistrationModal: !this.state.drawnRegistrationModal
    });
  }

  render() {
    return (
      <Paper className="modalPaper">
        <div className="modalPaperWrapper">
          <Grid container>
            <Grid item justify="flex-end" alignItems="center" xs={11}>
              <Typography variant="subtitle1" align="left">
                {this.state.tipoItem == 100 ? "Editar " : "Editar Duplicata" }
              </Typography>
            </Grid>
            <Grid item justify="flex-end" alignItems="center" xs={1}>
              <a href="#/dashboard" onClick={this.props.handleCloseModal}>
                <Box display="flex" flexDirection="row-reverse">
                  <CloseIcon />
                </Box>
              </a>
            </Grid>
          </Grid>
          { this.state.loading ? 
            <div>
              <Grid container justify="center" alignItems="center" xs={12} style={{height: '200px', width: '400px'}}>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </div>
          : 
            <div style={{paddingTop: "40px"}}>
              <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                <Grid item xs style={{width: '100%'}}>
                  <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    {/* <Grid item xs={1}>
                      <Button 
                        color="primary" 
                        variant="contained" 
                        style={{marginTop: '18px', height: '42px'}}
                        onClick={this.handleDrawnRegistrationModal}
                      >
                        <AddIcon />
                      </Button>
                    </Grid> */}
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>CPF/CNPJ Sacado*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <InputMask
                              mask={this.state.documentoMask}
                              maskPlaceholder=" "
                              value={this.state.documento}
                              onChange={this.handleDocumentoChange}
                              disabled={this.props.isXML}
                            >
                              <TextField 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ style: { height: "45px" } }} 
                              />
                            </InputMask>
                            <Select 
                              style={{position: 'absolute', height: '45px', marginLeft: '-45px'}} 
                              value='' 
                              disableUnderline
                              onChange={this.handleSelectDocument}
                              disabled={this.props.isXML}
                            >
                              {this.props.drawnList.map((document, index) => (
                                <MenuItem value={document}>{document.documento} - {document.razSoc}</MenuItem>
                              ))}
                            </Select>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Razão Social Sacado*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.nome} 
                              onChange={this.handleNomeChange}
                              disabled={this.props.isXML}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{width: '100%'}}>
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Logradouro*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.logradouro} onChange={this.handleLogradouroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Bairro*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.bairro} onChange={this.handleBairroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Número*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField variant="outlined" type="number" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.numero} onChange={this.handleNumeroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{width: '100%'}}>
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Município*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.municipio} onChange={this.handleMunicipioChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Estado*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl style={{width: '100%'}}>
                            <Select
                              value={this.state.uf}
                              onChange={this.handleUfChange}
                              variant="outlined"
                              style={{height: '45px'}}
                              fullWidth
                            >
                            {states.map((entry, index) => (
                              <MenuItem key={index} value={entry}>{entry}</MenuItem>
                            ))}
                            </Select>
                          </FormControl>  
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
                <Grid item xs>
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Nro. Documento</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.nroDocumento} 
                              onChange={this.handleNroDocumentoChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Valor</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ 
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                                inputComponent: this.valorMask,
                                style: { height: "45px" } 
                              }} 
                              value={this.state.valor} 
                              onChange={this.handleValorChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Emissão*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              type="date" 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ 
                                inputProps: { max: new Date().toISOString().split('T')[0] },
                                style: { height: "45px" } 
                              }} 
                              value={this.state.emissao} 
                              onChange={this.handleEmissaoChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Vencimento*</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              type="date" 
                              variant="outlined" 
                              fullWidth 
                              value={this.state.vencimento} 
                              onChange={this.handleVencimentoChange}
                              InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] }, 
                                            style: { height: "45px" } }} 
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.tipoItem == 100 ? 
                  <div></div> 
                  : 
                    <Grid item xs>
                      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                                <Box p={0}>
                                  <Typography variant="body2">
                                    <b>Tipo</b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl style={{width: '100%'}}>
                                <Select
                                  value={this.state.tipoDuplicata}
                                  onChange={this.handleTipoDuplicataChange}
                                  variant="outlined"
                                  style={{height: '45px'}}
                                  fullWidth
                                >
                                {this.state.tipoDuplicataList.map((entry, index) => (
                                  <MenuItem key={index} value={entry.id}>{entry.tipo}</MenuItem>
                                ))}
                                </Select>
                              </FormControl>  
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                                <Box p={0}>
                                  <Typography variant="body2">
                                    <b>Desconto</b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <form noValidate autoComplete="off">
                              <TextField 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ 
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      R$
                                    </InputAdornment>
                                  ),
                                  inputComponent: this.descontoMask,
                                  style: { height: "45px" } 
                                }} 
                                value={this.state.desconto} 
                                onChange={this.handleDescontoChange}
                              />
                              </form>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                                <Box p={0}>
                                  <Typography variant="body2">
                                    <b>% Desconto</b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <form noValidate>
                                <TextField 
                                  value={this.state.descontoPorCento}
                                  onChange={this.handleDescontoPorCentoChange}
                                  type="number"
                                  className="percentualTextField"
                                  variant="outlined" 
                                  InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>, 
                                                style: { height: "45px" },
                                              }}
                                />
                              </form>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs style={{paddingTop: "20px"}}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                  <Box p={0}>
                    <Button variant="contained" color="primary" onClick={this.editItemList}>
                      Salvar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </div>
          
          }
        </div>
        <Modal 
          open={this.state.drawnRegistrationModal} 
          onClose={this.handleDrawnRegistrationModal} 
          className="modalPaper"
        >
          <div>
            <DrawnRegistration 
              documento={this.state.documento} 
              razaoSocial={this.state.nome} 
              handleModal={this.handleDrawnRegistrationModal} 
            />
          </div>
        </Modal>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    itemList: state.NewOperationReducer.itemList,

    drawnList: state.NewOperationReducer.drawnList,
  };
}

export default connect(mapStateToProps, { setItemList } )(EditItemModal);
import {combineReducers} from 'redux';

import PreCadastroReducer from './PreCadastroReducer';
import DashboardHomeReducer from './DashboardHomeReducer';
import DashboardReducer from './DashboardReducer';
import NewOperationReducer from './NewOperationReducer';
import OperationsReducer from './OperationsReducer'

export default combineReducers({
	PreCadastroReducer : PreCadastroReducer,
	DashboardHomeReducer : DashboardHomeReducer,
	DashboardReducer : DashboardReducer,
	NewOperationReducer : NewOperationReducer,
	OperationsReducer: OperationsReducer
});
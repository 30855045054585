import React, { Children, Component } from "react";
import "../Style.css";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@material-ui/core";
// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import dashboardRoutes from "../../DashboardRoutes.js";
import logoImage from "../../../../images/logo_home.png";
import AppbarUserPanel from "./AppbarUserPanel.js";
// react-redux
import { connect } from "react-redux";
import { setComponent } from "../../../../redux/actions/DashboardActions";

class Appbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userAcessLevel: 0,
      showReports: false,
    };

    this.handleOpenDashboard = this.handleOpenDashboard.bind(this);
  }

  handleOpenDashboard(event, componentID) {
    this.props.setComponent(componentID);
  }

  componentDidMount() {
    this.getUserAcessLevel();
  }

  getUserAcessLevel(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:CLIENTE_POR_ID',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        if (responseJson.list[0].NIVEL != null) {
          if (responseJson.list[0].RelatArquivoRemoto == true ||
              responseJson.list[0].RelatContaGrafica == true ||
              responseJson.list[0].RelatListaRecebiveis == true) {
            this.setState({
              showReports: true,
            });
          }

          this.setState({
            userAcessLevel: responseJson.list[0].NIVEL,
          })
        }
    });
  }

  render() {
    return (
      <div className="root">
        <CssBaseline />
        <AppBar
          position="fixed"
          className="appBar"
          elevation={0}
          style={{ boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.1)" }}
        >
          <Toolbar>
            <Box
              display="flex"
              alignItems="center"
              p={1}
              m={1}
              css={{ height: 100 }}
            />
            <div className="searchBarWrapper" />
            <div className="appbarRightSide">
              <AppbarUserPanel />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className="drawer"
          variant="permanent"
          classes={{
            paper: "drawerPaper",
          }}
          anchor="left"
        >
          <div className="toolbar">
            <span
              style={{
                display: "inline-block",
                height: "100%",
                verticalAlign: "middle",
              }}
            />
            <a href='http://nobelbanco.com.br/'>
              <img className="drawerImage" src={logoImage} />
            </a>
          </div>
          <Divider />
          <List style={{ paddingTop: "24px" }}>
            {dashboardRoutes.map((prop, index) => (
              <a
                key={index}
                href="#/dashboard"
                onClick={(e) => this.handleOpenDashboard(e, prop.componentID)}
                hidden={prop.componentID == 3 && this.state.showReports == false}
              >
                <ListItem
                  className={
                    prop.componentID == this.props.currentComponent
                      ? "itemActive"
                      : "item"
                  }
                  button
                  key={index}
                >
                  <ListItemIcon>
                    <div
                      className={
                        prop.componentID == this.props.currentComponent
                          ? "itemIconActive"
                          : "itemIcon"
                      }
                    >
                      <prop.icon className="itemIconSVG" />
                    </div>
                  </ListItemIcon>
                  <ListItemText className="itemText" primary={prop.name} />
                </ListItem>
              </a>
            ))}
          </List>
        </Drawer>
        <main className="content">{this.props.children}</main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    credencials: state.PreCadastroReducer.credencials,
    currentComponent: state.DashboardReducer.currentComponent,
  };
}

export default connect(mapStateToProps, { setComponent })(Appbar);

import React, { Component } from 'react';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Table, TableHead, TableCell, Checkbox, TableRow, TableBody, InputAdornment, MenuItem, Modal, Button } from '@material-ui/core';
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
// react-redux
import { connect } from 'react-redux';
import { setDuplicate } from '../../../../redux/actions/NewOperationActions';
// react-currency-format
import CurrencyFormat from 'react-currency-format';
// react-input-mask
import InputMask from "react-input-mask";
import DrawnRegistration from './DrawnRegistration';

class tipoDuplicataObject {
  id = '';
  tipo = '';
}

class documento {
  documento = '';
  nome = '';
}

var states = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];

class Duplicate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentoDuplicata: '',
      nomeDuplicata: '',
      valorDuplicata: 0,
      vencimentoDuplicata: null,
      nroDocumentoDuplicata: '',
      tipoDuplicata: '',
      desconto: 0,
      descontoPorCento: (Math.round(0 * 100) / 100).toFixed(2), // gambiarra pra ficar 0.00
      tipoDuplicataList: [],
      emissaoDuplicata: null,
      codigoWBA: '',
      logradouroDuplicata: '',
      numeroDuplicata: '',
      bairroDuplicata: '',
      municipioDuplicata: '',
      ufDuplicata: '',

      documentoMask: '999.999.999-999',
      documentosList: [],
      drawnRegistrationModal: false,
    }

    this.handleDrawnRegistrationModal = this.handleDrawnRegistrationModal.bind(this);
  }

  duplicataMask = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyFormat
        {...other}
        getInputRef={inputRef}
        value={this.state.valorDuplicata} 
        onValueChange={values => {
          this.setState({
            valorDuplicata: values.floatValue,
          }, () => {this.handleReducer()});
        }}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        allowNegative={false}
        fixedDecimalScale
      />
    );
  }

  descontoMask = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyFormat
        {...other}
        getInputRef={inputRef}
        value={this.state.desconto} 
        onValueChange={values => {
          this.setState({
            desconto: values.floatValue,
          }, () => {this.handleReducer()});
        }}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        allowNegative={false}
        fixedDecimalScale
      />
    );
  }

  getTipoDuplicata() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:TIPO_DUPLICATA',
    }

    var tipoDuplicataItemList = [];

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log("responseJsonTipoDuplicata", responseJson);

        responseJson.list.map((entry, index) => {
          let tipoDuplicataItem = Object.create({}, tipoDuplicataObject);
          
          tipoDuplicataItem.id = entry.ID;
          tipoDuplicataItem.tipo = entry.TIPO;

          tipoDuplicataItemList.push(tipoDuplicataItem);
        });

        this.setState({
          tipoDuplicataList: tipoDuplicataItemList
        }, () => {this.setState({
          tipoDuplicata: this.state.tipoDuplicataList[0].id
        })});
    });
  }

  componentDidMount() {
    this.getTipoDuplicata();
  }

  handleDocumentoDuplicataChange = (event) => {
    this.setState({
      documentoDuplicata: event.target.value,
    }, () => {
      this.handleReducer()

      if (this.state.documentoDuplicata.match(/\d/g) && this.state.documentoDuplicata.match(/\d/g).length > 11){
        this.setState({
          documentoMask: '99.999.999/9999-99'
        })
      }
      else {
        this.setState({
          documentoMask: '999.999.999-999'
        })
      }
    })
  }

  handleNomeDuplicataChange = (event) => {
    this.setState({
      nomeDuplicata: event.target.value,
    }, () => {this.handleReducer()})
  }

  handleValorDuplicataChange = (event) => {
    let desconto = 0;
    
    if (this.state.descontoPorCento > 0)
      desconto = (parseFloat(this.state.descontoPorCento.toString().replace(',', '.')) * parseFloat(event.target.value.toString().replace('.', '').replace(',', '.'))) / 100
      

    this.setState({
      valorDuplicata: event.target.value,
      desconto: desconto,
    }, () => {this.handleReducer()})
  }

  handleVencimentoDuplicataChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      vencimentoDuplicata: event.target.value,
    }, () => {this.handleReducer()})
  }

  handleNroDocumentoDuplicataChange = (event) => {
    this.setState({
      nroDocumentoDuplicata: event.target.value,
    }, () => {this.handleReducer()})
  }

  handleEmissaoDuplicataChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      emissaoDuplicata: event.target.value,
    }, () => {this.handleReducer()})
  }

  handleTipoDuplicataChange = (event) => {
    this.setState({
      tipoDuplicata: event.target.value,
    }, () => {this.handleReducer()})
  }

  handleDescontoChange = (event) => {
    let descontoPorCento = 0;
    
    if (event.target.value < 0)
      event.target.value = 0;

    if (parseFloat(event.target.value.toString().replace('.', '').replace(',', '.')) > parseFloat(this.state.valorDuplicata.toString().replace('.', '').replace(',', '.'))) 
      event.target.value = this.state.valorDuplicata;
    
    if (parseFloat(this.state.valorDuplicata.toString().replace('.', '').replace(',', '.')) > 0)
      descontoPorCento = (parseFloat(event.target.value.toString().replace('.', '').replace(',', '.')) * 100) / parseFloat(this.state.valorDuplicata.toString().replace('.', '').replace(',', '.'))
    
    this.setState({
      desconto: event.target.value,
      descontoPorCento: descontoPorCento.toFixed(2),
    }, () => {this.handleReducer()})
  }

  handleDescontoPorCentoChange = (event) => {
    let desconto = 0;

    if (event.target.value % 1 > 0 && event.target.value.toString().split(".")[1].length > 2)
      event.target.value = parseFloat(event.target.value).toFixed(2);
      
    if (event.target.value < 0)
      event.target.value = 0;

    if (event.target.value > 100) 
      event.target.value = 100;
      
    if (parseFloat(this.state.valorDuplicata.toString().replace('.', '').replace(',', '.')) > 0)
      desconto = (parseFloat(this.state.valorDuplicata.toString().replace('.', '').replace(',', '.')) * event.target.value) / 100

    this.setState({
      descontoPorCento: event.target.value,
      desconto: desconto.toFixed(2).toString().replace('.', ','),
    }, () => {this.handleReducer()})
  }

  handleLogradouroDuplicataChange = (event) => {
    this.setState({
      logradouroDuplicata: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleBairroChange = (event) => {
    this.setState({
      bairroDuplicata: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleNumeroDuplicataChange = (event) => {
    this.setState({
      numeroDuplicata: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleMunicipioDuplicataChange = (event) => {
    this.setState({
      municipioDuplicata: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleUfDuplicataChange = (event) => {
    this.setState({
      ufDuplicata: event.target.value,
    }, () => {this.handleReducer()});
  }
  
  handleReducer() {
    this.props.setDuplicate(this.state.documentoDuplicata, 
                            this.state.nomeDuplicata, 
                            this.state.valorDuplicata,
                            this.state.vencimentoDuplicata, 
                            this.state.emissaoDuplicata,
                            this.state.nroDocumentoDuplicata,
                            this.state.tipoDuplicata,
                            this.state.desconto,
                            this.state.descontoPorCento,
                            this.state.codigoWBA,
                            this.state.logradouroDuplicata,
                            this.state.numeroDuplicata,
                            this.state.bairroDuplicata,
                            this.state.municipioDuplicata,
                            this.state.ufDuplicata);
  }

  componentWillReceiveProps(newProps) {
    if(newProps.refresh !== this.props.refresh) 
      this.refreshComponent();
  }

  refreshComponent() {
    this.setState({
      documentoDuplicata: '',
      nomeDuplicata: '',
      valorDuplicata: 0,
      vencimentoDuplicata: '',
      nroDocumentoDuplicata: '',
      emissaoDuplicata: '',
      tipoDuplicata: this.state.tipoDuplicataList[0].id,
      desconto: 0,
      descontoPorCento: (Math.round(0 * 100) / 100).toFixed(2),
      logradouroDuplicata: '',
      numeroDuplicata: '',
      bairroDuplicata: '',
      municipioDuplicata: '',
      ufDuplicata: '',
    }, () => {this.handleReducer()});
  }

  handleSelectDocument = (event) => {
    if (event.target.value.documento.match(/\d/g) && event.target.value.documento.match(/\d/g).length > 11){
      this.setState({
        documentoMask: '99.999.999/9999-99'
      })
    }
    else {
      this.setState({
        documentoMask: '999.999.999-999'
      })
    }

    this.setState({
      documentoDuplicata: event.target.value.documento,
      nomeDuplicata: event.target.value.razSoc,
      codigoWBA: event.target.value.codigoWBA,
      logradouroDuplicata: event.target.value.logradouro,
      numeroDuplicata: event.target.value.numero,
      bairroDuplicata: event.target.value.bairro,
      municipioDuplicata: event.target.value.municipio,
      ufDuplicata: event.target.value.uf,
    }, () => {this.handleReducer()})
  }

  handleDrawnRegistrationModal() {
    this.setState({
      drawnRegistrationModal: !this.state.drawnRegistrationModal
    });
  }

  render() {
    return (
      <div style={{paddingTop: "40px", paddingBottom: "40px"}}>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
        <Grid item xs style={{width: '100%'}}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              {/* <Grid item xs={1}>
                <Button 
                  color="primary" 
                  variant="contained" 
                  style={{marginTop: '18px', height: '42px'}}
                  onClick={this.handleDrawnRegistrationModal}
                >
                  <AddIcon />
                </Button>
              </Grid> */}
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>CPF/CNPJ Sacado*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask={this.state.documentoMask}
                        maskPlaceholder=" "
                        value={this.state.documentoDuplicata}
                        onChange={this.handleDocumentoDuplicataChange}
                      >
                        <TextField 
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" } }} 
                        />
                      </InputMask>
                      <Select 
                        style={{position: 'absolute', height: '45px', marginLeft: '-45px'}} 
                        value='' 
                        disableUnderline
                        onChange={this.handleSelectDocument}
                      >
                        {this.props.drawnList.map((document, index) => (
                          <MenuItem value={document}>{document.documento} - {document.razSoc}</MenuItem>
                        ))}
                      </Select>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Razão Social Sacado*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.nomeDuplicata} onChange={this.handleNomeDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Logradouro</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.logradouroDuplicata} onChange={this.handleLogradouroDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Bairro</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.bairroDuplicata} onChange={this.handleBairroChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Número</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" type="number" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.numeroDuplicata} onChange={this.handleNumeroDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Município</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.municipioDuplicata} onChange={this.handleMunicipioDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Estado</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <FormControl style={{width: '100%'}}>
                      <Select
                        value={this.state.ufDuplicata}
                        onChange={this.handleUfDuplicataChange}
                        variant="outlined"
                        style={{height: '45px'}}
                        fullWidth
                      >
                      {states.map((entry, index) => (
                        <MenuItem key={index} value={entry}>{entry}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>  
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
          <Grid item xs style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Valor</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        variant="outlined" 
                        fullWidth 
                        InputProps={{ 
                          startAdornment: (
                            <InputAdornment position="start">
                              R$
                            </InputAdornment>
                          ),
                          inputComponent: this.duplicataMask,
                          style: { height: "45px" } 
                        }} 
                        value={this.state.valorDuplicata} 
                        onChange={this.handleValorDuplicataChange}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Desconto</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        variant="outlined" 
                        fullWidth 
                        InputProps={{ 
                          startAdornment: (
                            <InputAdornment position="start">
                              R$
                            </InputAdornment>
                          ),
                          inputComponent: this.descontoMask,
                          style: { height: "45px" } 
                        }} 
                        value={this.state.desconto} 
                        onChange={this.handleDescontoChange}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>% Desconto</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate>
                      <TextField 
                        value={this.state.descontoPorCento}
                        onChange={this.handleDescontoPorCentoChange}
                        type="number"
                        className="percentualTextField"
                        variant="outlined" 
                        InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment>, 
                                      style: { height: "45px" },
                                    }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Tipo</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{width: '100%'}}>
                      <Select
                        value={this.state.tipoDuplicata}
                        onChange={this.handleTipoDuplicataChange}
                        variant="outlined"
                        style={{height: '45px'}}
                        fullWidth
                      >
                      {this.state.tipoDuplicataList.map((entry, index) => (
                        <MenuItem key={index} value={entry.id}>{entry.tipo}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>  
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Nro. da Nota Fiscal / Duplicata</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.nroDocumentoDuplicata} onChange={this.handleNroDocumentoDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Emissão</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        type="date" 
                        variant="outlined" 
                        fullWidth 
                        InputProps={{ 
                          inputProps: { max: new Date().toISOString().split('T')[0] },
                          style: { height: "45px" } 
                        }} 
                        value={this.state.emissaoDuplicata} 
                        onChange={this.handleEmissaoDuplicataChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Vencimento</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        type="date" 
                        variant="outlined" 
                        fullWidth 
                        value={this.state.vencimentoDuplicata} 
                        onChange={this.handleVencimentoDuplicataChange}
                        InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] }, 
                                      style: { height: "45px" } }} 
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          
        </Grid>
        <Modal 
          open={this.state.drawnRegistrationModal} 
          onClose={this.handleDrawnRegistrationModal} 
          className="modalPaper"
        >
          <div>
            <DrawnRegistration 
              documento={this.state.documentoDuplicata} 
              razaoSocial={this.state.nomeDuplicata} 
              handleModal={this.handleDrawnRegistrationModal} 
            />
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,

    drawnList: state.NewOperationReducer.drawnList,
  };
}

export default connect(mapStateToProps, { setDuplicate } )(Duplicate);
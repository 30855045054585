import React, { Component } from 'react';
import '../../../components/Style.css';
// @material-ui/core
import { Typography, Grid, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction, Paper, Modal, CircularProgress, Box } from '@material-ui/core';
// @material-ui/icons
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
// react-redux
import { connect } from 'react-redux';
import { updateList, updatePendency, updateListByBordero, updateListByItem } from '../../../../../redux/actions/PendenciesListActions'
// core-components
import PendenciesValueModal from './PendenciesValueModal';
import PendenciesModal from './PendenciesModal';

class pendency{
  name = '';
  type = '';
}

class PendenciesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTimer: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount(){
    if (this.props.numBorderoID)
      this.props.updateListByBordero(this.props.credencials.user_id, this.props.numBorderoID)
    else if (this.props.itemID)
      this.props.updateListByItem(this.props.credencials.user_id, this.props.itemID)
    else 
      this.props.updateList(this.props.credencials.user_id);
    
    setTimeout(() => {
      this.setState({loadingTimer: true});
    }, 2000)
  }

  handleOpenModal(event, modalId) {
    this.props.updatePendency(modalId);
    
    this.setState({
      openedModalId: modalId,
    });
  }

  handleCloseModal() {
    this.setState({
      openedModalId: null,
    });
  }

  render() {
    const { openedModalId } = this.state;
    var loadingIcon;
    
    if(this.state.loadingTimer == false){
      loadingIcon = <CircularProgress /> 
    }
    else {
      if(this.props.numBorderoID){
        loadingIcon = <div style={{color: 'lightgray'}}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs>
                            <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                              <Box p={0}>
                                <InsertEmoticonIcon />
                              </Box>
                            </Box>  
                          </Grid>
                          <Grid item xs>
                            <Box display="row" alignItems="center" justifyContent="center" p={0}>
                              <Box p={0}>
                                <Typography variant="body2">
                                  Esse borderô não tem pendências!
                                </Typography>
                              </Box>
                            </Box>  
                          </Grid>
                        </Grid>
                      </div>
      }
      else if(this.props.itemID){
        loadingIcon = <div style={{color: 'lightgray'}}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs>
                            <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                              <Box p={0}>
                                <InsertEmoticonIcon />
                              </Box>
                            </Box>  
                          </Grid>
                          <Grid item xs>
                            <Box display="row" alignItems="center" justifyContent="center" p={0}>
                              <Box p={0}>
                                <Typography variant="body2">
                                  Esse lançamento não tem pendências!
                                </Typography>
                              </Box>
                            </Box>  
                          </Grid>
                        </Grid>
                      </div>
      }
      else {
        if(this.props.pendencyNumber == 0){
          loadingIcon = <div style={{color: 'lightgray'}}>
                          <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item xs>
                              <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                                <Box p={0}>
                                  <InsertEmoticonIcon />
                                </Box>
                              </Box>  
                            </Grid>
                            <Grid item xs>
                              <Box display="row" alignItems="center" justifyContent="center" p={0}>
                                <Box p={0}>
                                  <Typography variant="body2">
                                    Você não tem pendências!
                                  </Typography>
                                </Box>
                              </Box>  
                            </Grid>
                          </Grid>
                        </div>
        } else{
          loadingIcon = <div style={{color: 'lightgray'}}>
                          <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item xs>
                              <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                                <Box p={0}>
                                  <SentimentDissatisfiedIcon />
                                </Box>
                              </Box>  
                            </Grid>
                            <Grid item xs>
                              <Box display="row" alignItems="center" justifyContent="center" p={0}>
                                <Box p={0}>
                                  <Typography variant="body2">
                                    Algo deu errado!
                                  </Typography>
                                </Box>
                              </Box>  
                            </Grid>
                          </Grid>
                        </div>
        }
      }
    }
    
    return (
      <Paper style={{height: '100%'}}>
        <div className="listWrapper">
          <Typography variant="subtitle1" className="listTitle" align="left" style={{paddingLeft: '15px'}}>
            Pendências
          </Typography>
          <div className="listLabel">
            <List dense>
              <ListItem>
                  <ListItemText primary={"ATIVIDADE"} />
                <ListItemSecondaryAction>
                  <ListItemText primary={"ACESSAR"} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
          {this.props.pendencies == false ? 
            <div>
              <Grid container justify="center" alignItems="center" xs={12} style={{height: '200px'}}>
                <Grid item>
                  { loadingIcon }
                </Grid>
              </Grid>
            </div>
            : 
            <div>
              <List>
                {this.props.pendencies && this.props.pendencies.map((value, index) => {
                  return (
                    <ListItem key={index}>
                    <ListItemText primary={value.name} />
                    <ListItemSecondaryAction>
                      <div className="listIcon">
                        <IconButton edge="end" aria-label="delete" style={{color: "#3B86FF"}} onClick={(e) => this.handleOpenModal(e, value.id)}>
                          {value.type == "Informação" ? <EditIcon /> : <DescriptionIcon />}
                        </IconButton>
                        <Modal
                          id={value.id}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                          open={openedModalId === value.id}
                          onClose={this.handleCloseModal}
                          className="modalPaper"
                        >
                          <div>
                            {value.type == "Informação" ? 
                            <PendenciesValueModal handleOpenModal={this.handleOpenModal} pendencyName={value.name} /> 
                            : 
                            <PendenciesModal handleOpenModal={this.handleOpenModal} pendencyName={value.name} />
                            }
                          </div>
                        </Modal>
                      </div>
                    </ListItemSecondaryAction>
                  </ListItem>
                  );
                })}
              </List>
            </div>
          }
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    pendencies: state.DashboardHomeReducer.pendenciesList,
    pendencyNumber: state.DashboardHomeReducer.pendencyNumber,
  };
}

export default connect(mapStateToProps, { updateList, updatePendency, updateListByBordero, updateListByItem } )(PendenciesList);
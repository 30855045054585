import React, { Fragment, Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Table, TableHead, TableCell, Checkbox, TableRow, TableBody, InputAdornment, MenuItem, Modal, IconButton, Button } from '@material-ui/core';
// @material-ui/lab
import Autocomplete from '@material-ui/lab/Autocomplete';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
// @material-ui/icons
import AddIcon from '@material-ui/icons/Add';
// react-redux
import { connect } from 'react-redux';
import { setBankCheck } from '../../../../redux/actions/NewOperationActions';
// react-currency-format
import CurrencyFormat from 'react-currency-format';
// react-input-mask
import InputMask from "react-input-mask";
// core-component
import DrawnRegistration from './DrawnRegistration';

class documento {
  documento = '';
  nome = '';
}

var states = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];

class Information extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentoCheque: '',
      nomeCheque: '',
      valorCheque: 0,
      vencimentoCheque: null,
      nroDocumentoCheque: '',
      emissaoCheque: null,
      codigoWBA: '',
      logradouroCheque: '',
      numeroCheque: '',
      bairroCheque: '',
      municipioCheque: '',
      ufCheque: '',

      documentoMask: '999.999.999-999',
      minimumDate: '2019-12-05',
      drawnRegistrationModal: false,
    }

    this.handleDrawnRegistrationModal = this.handleDrawnRegistrationModal.bind(this);
  }

  bankCheckMask = (props) => {
    const { inputRef, ...other } = props;
  
    return (
      <CurrencyFormat
        {...other}
        getInputRef={inputRef}
        value={this.state.valorCheque} 
        onValueChange={values => {
          this.setState({
            valorCheque: values.floatValue,
          }, () => {this.handleReducer()});
        }}
        thousandSeparator='.'
        decimalSeparator=','
        decimalScale={2}
        allowNegative={false}
        fixedDecimalScale
      />
    );
  }

  documentMask = (props) => {
    const { params, ...other } = props;
  
    return (
      <InputMask
        mask={this.state.documentoMask}
        maskPlaceholder=" "
        value={this.state.documentoCheque}
        onChange={this.handleDocumentoChequeChange}
      />
    );
  }

  handleDocumentoChequeChange = (event, values) => {
    if (values != null){
      this.setState({
        documentoCheque: values.documento,
      }, () => {
        this.handleReducer()
        
        if (this.state.documentoCheque.match(/\d/g) && this.state.documentoCheque.match(/\d/g).length > 11){
          this.setState({
            documentoMask: '99.999.999/9999-99'
          })
        }
        else {
          this.setState({
            documentoMask: '999.999.999-999'
          })
        }
      });
    }
    else {
      this.setState({
        documentoCheque: event.target.value,
      }, () => {
        this.handleReducer()
        
        if (this.state.documentoCheque.match(/\d/g) && this.state.documentoCheque.match(/\d/g).length > 11){
          this.setState({
            documentoMask: '99.999.999/9999-99'
          })
        }
        else {
          this.setState({
            documentoMask: '999.999.999-999'
          })
        }
      });
    }
  }
  
  handleNomeChequeChange = (event) => {
    this.setState({
      nomeCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleValorChequeChange = (event) => {
    this.setState({
      valorCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleVencimentoChequeChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      vencimentoCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleNroDocumentoChequeChange = (event) => {
    this.setState({
      nroDocumentoCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleEmissaoChequeChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      emissaoCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleLogradouroChequeChange = (event) => {
    this.setState({
      logradouroCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleBairroChange = (event) => {
    this.setState({
      bairroCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleNumeroChequeChange = (event) => {
    this.setState({
      numeroCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleMunicipioChequeChange = (event) => {
    this.setState({
      municipioCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleUfChequeChange = (event) => {
    this.setState({
      ufCheque: event.target.value,
    }, () => {this.handleReducer()});
  }

  handleReducer() {
    this.props.setBankCheck(this.state.documentoCheque, 
                            this.state.nomeCheque, 
                            this.state.valorCheque, 
                            this.state.vencimentoCheque,
                            this.state.emissaoCheque,
                            this.state.nroDocumentoCheque,
                            this.state.codigoWBA,
                            this.state.logradouroCheque,
                            this.state.numeroCheque,
                            this.state.bairroCheque,
                            this.state.municipioCheque,
                            this.state.ufCheque);
  }

  componentWillReceiveProps(newProps) {
    if(newProps.refresh !== this.props.refresh) 
      this.refreshComponent();
  }

  refreshComponent = () => {
    this.setState({
      documentoCheque: '',
      nomeCheque: '',
      valorCheque: 0,
      vencimentoCheque: '',
      nroDocumentoCheque: '',
      emissaoCheque: '',
      logradouroCheque: '',
      numeroCheque: '',
      bairroCheque: '',
      municipioCheque: '',
      ufCheque: '',
    }, () => {this.handleReducer()});
  }

  handleSelectDocument = (event) => {
    if (event.target.value.documento.match(/\d/g) && event.target.value.documento.match(/\d/g).length > 11){
      this.setState({
        documentoMask: '99.999.999/9999-99'
      })
    }
    else {
      this.setState({
        documentoMask: '999.999.999-999'
      })
    }

    console.log('event.target.value', event.target.value);

    this.setState({
      documentoCheque: event.target.value.documento,
      nomeCheque: event.target.value.razSoc,
      codigoWBA: event.target.value.codigoWBA,
      logradouroCheque: event.target.value.logradouro,
      numeroCheque: event.target.value.numero,
      bairroCheque: event.target.value.bairro,
      municipioCheque: event.target.value.municipio,
      ufCheque: event.target.value.uf,
    }, () => {this.handleReducer()})
  }

  handleDrawnRegistrationModal() {
    this.setState({
      drawnRegistrationModal: !this.state.drawnRegistrationModal
    });
  }

  render() {
    return (
      <div style={{paddingTop: "40px", paddingBottom: "40px"}}>
        <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
          <Grid item xs style={{width: '100%'}}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
              {/* <Grid item xs={1}>
                <Button 
                  color="primary" 
                  variant="contained" 
                  style={{marginTop: '18px', height: '42px'}}
                  onClick={this.handleDrawnRegistrationModal}
                >
                  <AddIcon />
                </Button>
              </Grid> */}
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>CPF/CNPJ Sacado*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask={this.state.documentoMask}
                        maskPlaceholder=" "
                        value={this.state.documentoCheque}
                        onChange={this.handleDocumentoChequeChange}
                      >
                        <TextField
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" }, }} 
                        />
                      </InputMask>
                      <Select 
                        style={{position: 'absolute', height: '45px', marginLeft: '-45px'}} 
                        value='' 
                        disableUnderline
                        onChange={this.handleSelectDocument}
                      >
                        {this.props.drawnList.map((document, index) => (
                          <MenuItem value={document}>{document.documento} - {document.razSoc}</MenuItem>
                        ))}
                      </Select>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Razão Social Sacado*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.nomeCheque} onChange={this.handleNomeChequeChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Logradouro</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.logradouroCheque} onChange={this.handleLogradouroChequeChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Bairro</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.bairroCheque} onChange={this.handleBairroChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Número</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" type="number" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.numeroCheque} onChange={this.handleNumeroChequeChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Município</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField variant="outlined" fullWidth InputProps={{ style: { height: "45px" } }} value={this.state.municipioCheque} onChange={this.handleMunicipioChequeChange}/>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Estado</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <FormControl style={{width: '100%'}}>
                      <Select
                        value={this.state.ufCheque}
                        onChange={this.handleUfChequeChange}
                        variant="outlined"
                        style={{height: '45px'}}
                        fullWidth
                      >
                      {states.map((entry, index) => (
                        <MenuItem key={index} value={entry}>{entry}</MenuItem>
                      ))}
                      </Select>
                    </FormControl>  
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>CMC7</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <InputMask
                        mask='999999999999999999999999999999'
                        maskPlaceholder=" "
                        value={this.state.nroDocumentoCheque} 
                        onChange={this.handleNroDocumentoChequeChange}
                      >
                        <TextField 
                          variant="outlined" 
                          fullWidth 
                          InputProps={{ style: { height: "45px" } }} 
                        />
                      </InputMask>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Valor</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        variant="outlined" 
                        fullWidth 
                        InputProps={{ 
                          startAdornment: (
                            <InputAdornment position="start">
                              R$
                            </InputAdornment>
                          ),
                          inputComponent: this.bankCheckMask,
                          style: { height: "45px" } 
                        }} 
                        value={this.state.valorCheque} 
                        onChange={this.handleValorChequeChange}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{width: '100%'}}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Emissão*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        type="date" 
                        variant="outlined" 
                        fullWidth 
                        InputProps={{ 
                          inputProps: { max: new Date().toISOString().split('T')[0] },
                          style: { height: "45px" } 
                        }} 
                        value={this.state.emissaoCheque} 
                        onChange={this.handleEmissaoChequeChange}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                      <Box p={0}>
                        <Typography variant="body2">
                          <b>Vencimento*</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                      <TextField 
                        id="expiringDate"
                        type="date" 
                        variant="outlined" 
                        fullWidth 
                        value={this.state.vencimentoCheque} 
                        onChange={this.handleVencimentoChequeChange}
                        InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] },
                                      style: {height: '45px'} }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Modal 
          open={this.state.drawnRegistrationModal} 
          onClose={this.handleDrawnRegistrationModal} 
          className="modalPaper"
        >
          <div>
            <DrawnRegistration 
              documento={this.state.documentoCheque} 
              razaoSocial={this.state.nomeCheque} 
              handleModal={this.handleDrawnRegistrationModal} 
            />
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,

    drawnList: state.NewOperationReducer.drawnList,
  };
}

export default connect(mapStateToProps, { setBankCheck } )(Information);
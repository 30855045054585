import React, { Component } from 'react';
import '../Style.css';
import ReactDOMServer from "react-dom/server";
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
// @material-ui/icons
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// react-redux
import { connect } from 'react-redux';
// import Wijmo styles and components
import 'wijmo/styles/wijmo.css';
import { CollectionView } from 'wijmo/wijmo';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { FlexChart, FlexChartSeries } from 'wijmo/wijmo.react.chart';
import * as wjcCore from "wijmo/wijmo";
import { setLicenseKey } from 'wijmo/wijmo';
// core-components
import BilletList from './BilletList';
import PendenciesList from '../Home/Pendencies/PendenciesList';
import Information from './Information';
import { getCulture } from '../Culture';

setLicenseKey(
  "portal.nobelbanco.com.br|nobelbanco.com.br,456644433637969#B0ijOklkIs4nIxYHOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZpl6bCR7QBtyN0NWcHhGSClkVapWdNNGOlhzaslFRrs6LFZjWrtyZwpFRr56aUl6SulkNLR7KqpHTUJ7dFZzV9I5RulUO7JXMndUVlREWZ3EbYBjdiJ5bktUc74UM7BHMoNmUyIVMtR5bxYnRRFGdER5N5o7QLNET7MjcoNGbEV5d4kXdBVDZYJHMX3kNytWO5oGTttkaxgkTYRGTQhmV7QkZDdmaTZlbXl6U6g6bzFjTjNGVHZTa6Ekdi3mNWBFMUdkTXZWWrE7aRV4TkZTa6N5cudEZn3CeOxEMG94K8kjZ73EN6EDTZJmYiJzLOhkaLhTc9N5TPBnYw8We7xWM5YWR8l5Ukh4aw54YN5GVjV7KRBHawYWTvcUcyIXevtWWEpke4cUZIVEb05EV7YjaBh4KillcHdlcF94aGp5cjllRDtkVsxWQRBlewdGTDlVRwYzb6QmI0IyUiwiIyY4QBVUOEZjI0ICSiwyMzQzMxMDO4MTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzQDOxcDMgUDM5ATMyAjMiojI4J7QiwiIyJmLt36Yu26YuFmYsVmYv9GLyJmLt36Yu26YuFmYsVmYv9mLsFGdy3GciojIz5GRiwiIhRGdMBSYjlGdhOcby3mZulEItVEIhl6Zvx6buNWZUBSZgEWay3GdsV7cu36QgQmbp5mblB7TiojIh94QiwiI9YTO7MjNzMDN4QjN6nDui"  
);

class ItemObject {
  sel = false;
  id = 0;
  item = 0;
  status = '';
  operacao = '';
  documento = '';
  sacado = '';
  valor = '';
  valorExport = 0;
  vencimento = null;
  emissao = null;
  numeroDocumento = '';
  pendencias = 0;
  boleto = '';
  info = null;
}

class NewOperation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedModalIdBoleto: '',
      openedModalIdPendencias: '',
      openedModalIdInfo: '',
      itemList: [],
      operationList: [],
      selectedList: [],

      flexGrid: null,

      loading: false,
      boletoDialog: false,
    }

    this.handleCloseModalBoleto = this.handleCloseModalBoleto.bind(this);
    this.handleCloseModalPendencias = this.handleCloseModalPendencias.bind(this);
    this.handleCloseModalInfo = this.handleCloseModalInfo.bind(this);
  }

  componentWillReceiveProps(newProps) {
    // \/ \/ \/ comparar array em js é bugadasso por isso fiz isso \/ \/ \/

    if(JSON.stringify(newProps.operationList) != JSON.stringify(this.state.operationList)){
      this.setState({
        operationList: this.props.operationList
      }, () => {
        this.getOperacoes();
      });
    }
  }

  async getOperacoes() {
    this.setState({
      loading: true,
    }); 

    var itemList = [];

    if (this.state.operationList.length > 0){
      const promises = this.state.operationList.map(async (operation, idx) => {
        var obj = {
          qid : 'PAGINA_DASHBOARD:REMESSA_ITENS',
          conditions: [
            {
              filterid: 'REMESSA',
              values: [operation]
            }
          ]
        }
    
        await fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(obj)
          }).then((response) => response.json()).then((responseJson) => {
            responseJson.list.map((entry, index) => {
              let item = Object.create({}, ItemObject);
              
              // item.sel = false;
              item.id = entry.ID;
              item.item = entry.ITEM;
              item.status = entry.STATUS;
              item.operacao = entry.TIPO_OPERACAO; 
              item.sacado = entry.NOME_SACADO;
              item.numeroDocumento = entry.NRO_DOCUMENTO;

              item.emissao = (entry.DATA_EMISSAO != null ? this.convertDate(entry.DATA_EMISSAO.substring(0, 10)) : '')
              item.vencimento = (entry.DATA_VENCIMENTO != null ? this.convertDate(entry.DATA_VENCIMENTO.substring(0, 10)) : '');

              let _valor = wjcCore.Globalize.format(entry.VALOR, 'n2');

              item.valorExport = entry.VALOR;
              item.valor = _valor
              
              item.pendencias = entry.PENDENCIAS;
              // item.boleto = entry.URL_BOLETO;
              item.info = 'Info';

              itemList.push(item);
            });
        });
      });
  
      await Promise.all(promises);
    }
    
    this.setState({
      itemList: itemList,
      loading: false,
    });
  }

  convertDate(dateString){
    var p = dateString.split(/\D/g)
    
    return [p[2],p[1],p[0]].join("/")
  }

  handleOpenModalBoleto(modalId) {
    this.setState({
      openedModalIdBoleto: "urlBoleto" + modalId
    });
  }

  handleCloseModalBoleto() {
    this.setState({
      openedModalIdBoleto: null
    });
  }

  handleOpenModalPendencias(modalIndex) {
    this.setState({
      openedModalIdPendencias: "pendencia" + modalIndex
    });
  }

  handleCloseModalPendencias() {
    this.setState({
      openedModalIdPendencias: null
    });
  }

  handleOpenModalInfo(modalIndex) {
    this.setState({
      openedModalIdInfo: "info" + modalIndex
    });
  }

  handleCloseModalInfo() {
    this.setState({
      openedModalIdInfo: null
    });
  }

  initializeGrid(flex) {
    let self = this;

    flex.formatItem.addHandler((s, e) => {
      if (e.panel == s.columnHeaders) {
        if (s.columns[e.col].binding == 'sel') {
          e.cell.innerHTML = '<input class="select-all" tabindex="-1" type="checkbox">';
          this._updateSelectAllBox(s);
        }
      }
      else if (e.panel == s.cells) {
        wjcCore.setAttribute(e.cell.parentElement, 'aria-selected', s.rows[e.row].dataItem.sel);
      }
    });
    
    // select/de-select all items when user clicks the check box on the header
    flex.hostElement.addEventListener('click', (e) => {
      if (wjcCore.hasClass(e.target, 'select-all') && e.target instanceof HTMLInputElement) {
        flex.deferUpdate(() => {
          flex.collectionView.items.forEach((item) => {
            item.sel = (e.target).checked;
          });
        });
      }
    });

    flex.itemFormatter = function(panel, r, c, cell) {
      var col = panel.columns[c];
      
      let downloadIcon =  <div>
                            <ReceiptIcon style={{color: '#3f51b5', pointerEvents: "none"}}/>
                          </div>

                        

      let pendencyFalseHtml = <div style={{color: '#1dff00', pointerEvents: "none"}}>
                                <b>{cell.innerHTML}</b>
                              </div>

      let pendencyTrueHtml =  <div style={{color: 'red', pointerEvents: "none"}}>
                                <b>{cell.innerHTML}</b>
                              </div>
                          
      if (col.name == 'sel') {
        cell.onmouseup = () => self.handleSelectedOperations();
        col.width = 50
      }
      
      if (col.name == 'id') {
        col.width = 75;
        col.align = 'center';
      }

      if (col.name == 'item' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 100; 
      }

      if (col.name == 'status' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 100; 
      }

      if (col.name == 'operacao' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 100; 
      }

      if (col.name == 'valorExport') {
        col.width = 1; 
      }

      if (col.name == 'valor') {
        col.header = 'Valor (R$)';
        cell.align = 'right'

        flex.columnFooters.setCellData(0, col.index, flex.collectionView.getAggregate(wjcCore.Aggregate.Sum, 'valorExport'))
      }

      if (col.name == 'pendencias' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 125

        if (cell.innerHTML > 0) {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<ErrorOutlineIcon style={{color: '#D7AD1B', pointerEvents: "none"}}/>);
          cell.onclick = () => self.handleOpenModalPendencias(r); 
        }
        else {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<CheckCircleOutlineIcon style={{color: '#3f51b5', pointerEvents: "none"}}/>);
        }
      }
      
      // if (col.name == 'boleto' && panel.cellType == 1) {
      //   col.align = 'center';
      //   col.width = 75
      //   cell.innerHTML = ReactDOMServer.renderToStaticMarkup(downloadIcon);
      //   cell.onclick = () => self.handleOpenModalBoleto(r); 
      // }

      if (col.name == 'info' && panel.cellType == 1) {
        col.align = 'center';
        col.width = 75;
        cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<AnnouncementIcon style={{color: '#3f51b5', pointerEvents: "none"}}/>);
        cell.onclick = () => self.handleOpenModalInfo(r); 
      }
    }

    flex.columnFooters.rows.push(new wjcCore.grid.GroupRow());

    wjcCore.culture = getCulture();

    this.setState({
      flexGrid: flex
    });
  }

  _updateSelectAllBox(grid) {
    let cb = grid.hostElement.querySelector('.select-all');

    if (cb instanceof HTMLInputElement) {
      let items = grid.collectionView.items, last = null, indeterminate = false;
      
      for (let i = 0; i < items.length; i++) {
        if (last != null && items[i].sel != last) {
          indeterminate = true;
          break;
        }
        last = items[i].sel;
      }
      
      if (indeterminate) {
        cb.checked = true;
        cb.indeterminate = true;
      }
      else {
        cb.checked = last;
      }
    }

    this.handleSelectedOperations();
  }

  handleSelectedOperations() {
    console.log('handleSelectedOperations')
    
    let selectedList = [];
    let self = this;

    // setTimeout(function(){
      for (var i = 0; i < self.state.flexGrid.cells.rows.length; i++){
        if (self.state.flexGrid.cells.rows[i]._data.sel){
          selectedList.push(self.state.flexGrid.cells.rows[i]._data.id);
        }
      }
  
      self.setState({
        selectedList: selectedList
      }, console.log(this.state.selectedList))
    // }, 500) // gambiarra
  }

  handleBoletoDialog() {
    this.setState({
      boletoDialog: !this.state.boletoDialog
    })
  }

  downloadFiles() {
    console.log('downloadFiles', this.props.selectedList);

    this.props.selectedList.map((item) => {
      window.open(item.link, '_blank');
    })
  }

  render() {
    return (
      <div>
        <div className="expansionPanelWrapper" style={{paddingTop: "20px", paddingBottom: "20px" }}>
          <div className="App">
            <Box display="row" alignItems="center" justifyContent="center" p={0}>
              <Box p={0}>
                <div className="App-panel">
                  {this.state.itemList.length == 0 ?
                  <div>
                    {this.state.loading ? 
                      <Box display="flex" alignItems="center" justifyContent="center" p={0}>
                        <Box p={0}>
                          <div style={{position: 'absolute', paddingTop: '25vh'}}>
                            <CircularProgress />
                          </div>
                        </Box>
                      </Box>
                    :
                      <div>
                        Este borderô não possui lançamentos.
                      </div>
                    }
                  </div>
                  :
                  <div />
                  }
                  <FlexGrid 
                    alternatingRowStep={0} 
                    headersVisibility="Column" 
                    selectionMode="Cell" 
                    itemsSource={this.state.itemList} 
                    initialized={this.initializeGrid.bind(this)}
                    class="custom-flex-grid"
                  >
                    <FlexGridFilter/>
                  </FlexGrid>  
                </div>
              </Box>
              {/* <Box p={0}>
                <Box  display="flex" flexDirection="row-reverse" p={0}>
                  <Box p={0}>
                    <Button variant="contained" color="primary" onClick={() => this.handleBoletoDialog()} disabled={this.state.selectedList.length == 0}>
                      Gerar Boletos
                    </Button>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </div>
        </div>
        {this.state.itemList.map((row, index) => (
          <Modal id={"boleto" + index} 
                 open={this.state.openedModalIdBoleto === "urlBoleto" + index} 
                 onClose={this.handleCloseModalBoleto} 
                 className="modalPaper">
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper">
                  <Grid container>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="subtitle1" align="left">
                        Boletos do item {row.item}
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleCloseModalBoleto}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <BilletList remessaItens={row.id} />
                </div>
              </Paper>
            </div>
          </Modal>
        ))
        }
        {this.state.itemList.map((entry, index) => (
          <Modal id={"pendencia" + index} 
                 open={this.state.openedModalIdPendencias === "pendencia" + index} 
                 onClose={this.handleCloseModalPendencias} 
                 className="modalPaper">
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper" style={{width: '500px'}}>
                  <Grid container>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="subtitle1" align="left">
                        Pendencias do Lançamento {entry.item}
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleCloseModalPendencias}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <div style={{paddingTop: '20px'}}>
                    <PendenciesList itemID={entry.id} />
                  </div>
                </div>
              </Paper>
            </div>
          </Modal>
        ))
        }
        {this.state.itemList.map((entry, index) => (
          <Modal id={"info" + index} 
                 open={this.state.openedModalIdInfo === "info" + index} 
                 onClose={this.handleCloseModalInfo} 
                 className="modalPaper">
            <div>
              <Paper className="modalPaper">
                <div className="modalPaperWrapper" style={{width: '500px'}}>
                  <Grid container>
                    <Grid item justify="flex-end" alignItems="center" xs={11}>
                      <Typography variant="subtitle1" align="left">
                        Info do Lançamento {entry.item}
                      </Typography>
                    </Grid>
                    <Grid item justify="flex-end" alignItems="center" xs={1}>
                      <a href="#/dashboard" onClick={this.handleCloseModalInfo}>
                        <Box display="flex" flexDirection="row-reverse">
                          <CloseIcon />
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                  <div style={{paddingTop: '20px'}}>
                    <Information itemID={entry.id} handleCloseModalInfo={this.handleCloseModalInfo} />
                  </div>
                </div>
              </Paper>
            </div>
          </Modal>
        ))
        }
        <Dialog open={this.state.boletoDialog} onClose={() => this.handleBoletoDialog()} >
          <DialogTitle>
            Gerar Boletos
          </DialogTitle>
          <DialogContent style={{marginBottom: '20px'}}>
            <div style={{height: '300px'}}>
              <BilletList selectedList={this.state.selectedList} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleBoletoDialog()} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => this.downloadFiles()} color="primary" autoFocus disabled={this.props.selectedList.length == 0}>
              Download de Todos
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
    
    operationList: state.OperationsReducer.operationList,
    selectedList: state.OperationsReducer.selectedList
  };
}

export default connect(mapStateToProps, { } )(NewOperation);
import { SET_COMPONENT } from '../constants/DashboardConstants';

const setComponentEvent = (dispatch, data) => {;
  dispatch({ type: SET_COMPONENT, data });
}

export const setComponent = (data) => 
{
  return dispatch => {
    setComponentEvent(dispatch, data);
  }
}




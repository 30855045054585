import React, { Component } from 'react';
import '../../../components/Style.css';
// @material-ui/core
import { Paper, Typography, Divider, Grid, Box } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
// core components
import PendenciesFilesTable from './PendenciesFilesTable';

class PendenciesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Paper className="modalPaper">
        <div className="modalPaperWrapper">
          <Grid container>
            <Grid item justify="flex-end" alignItems="center" xs={11}>
              <Typography variant="subtitle1" align="left">
                Pendências - {this.props.pendencyName}
              </Typography>
            </Grid>
            <Grid item justify="flex-end" alignItems="center" xs={1}>
              <a href="#/dashboard" onClick={this.props.handleOpenModal}>
                <Box display="flex" flexDirection="row-reverse">
                  <CloseIcon />
                </Box>
              </a>
            </Grid>
          </Grid>
          {/* <Typography variant="subtitle2" align="left" className="liststatus">
            Aditivo Entregue: 
          </Typography>
          <Typography variant="subtitle2" align="left" className="liststatus">
            Nota Promissório Entregue: 
          </Typography> */}
          <Divider />
          <Typography variant="subtitle1" className="listTitle" align="left">
            Informe os documentos
          </Typography>
          <PendenciesFilesTable handleOpenModal={this.props.handleOpenModal} pendencyID={this.props.pendencyID} />
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(PendenciesModal);
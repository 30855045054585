import { SET_INFORMATION, SET_DUPLICATE, SET_BANKCHECK, SET_FAVORED, SET_FILE_LIST, SET_ITEM_LIST, SET_DRAWN_LIST } from '../constants/NewOperationConstants';
// aws-s3
import AWS from 'aws-sdk';

class bankCheck {
  documentoCheque = null;
  nomeCheque = null;
  valorCheque = null;
  vencimentoCheque = null;
  emissaoCheque = null;
  nroDocumentoCheque = null;
  codigoWBA = null;

  logradouroCheque = null;
  numeroCheque = null;
  bairroCheque = null;
  municipioCheque = null;
  ufCheque = null;
}

class duplicate {
  documentoDuplicata = null;
  nomeDuplicata = null;
  valorDuplicata = null;
  vencimentoDuplicata = null;
  emissaoDuplicata = null;
  nroDocumentoDuplicata = null;
  tipoDuplicata = null;
  desconto = null;
  descontoPorCento = null;
  codigoWBA = null;

  logradouroDuplicata = null;
  numeroDuplicata = null;
  bairroDuplicata = null;
  municipioDuplicata = null;
  ufDuplicata = null;
}

class item {
  tipoItem = null;
  documento = null;
  nome = null;
  valor = null;
  vencimento = null;
  emissao = null;
  nroDocumento = null;
  documentIndex = null;
  urlXML = null;

  codigoWBA = null;

  logradouro = null;
  numero = null;
  bairro = null;
  municipio = null;
  uf = null;
}

class drawn {
  documento = null;
  IM = null;
  IE = null;
  email = null;

  razSoc = null;
  nomeFantasia = null;
  telefone = null;

  cep = null;
  logradouro = null;
  complemento = null;
  cidade = null;
  numero = null;
  bairro = null;
  estado = null;

  codigoWBA = null;
}

const setInformationEvent = (dispatch, carteiraID) => {
  dispatch({ type: SET_INFORMATION, data: carteiraID });
}

export const setInformation = (carteiraID) => 
{
  return dispatch => {
    setInformationEvent(dispatch, carteiraID);
  }
}

const setBankCheckEvent = (dispatch, documentoCheque, nomeCheque, valorCheque, vencimentoCheque, emissaoCheque, nroDocumentoCheque, codigoWBA, logradouroCheque, numeroCheque, bairroCheque, municipioCheque, ufCheque) => {
  let bankCheckObject = Object.create({}, bankCheck);

  bankCheckObject.documentoCheque = documentoCheque;
  bankCheckObject.nomeCheque = nomeCheque;
  bankCheckObject.valorCheque = valorCheque;
  bankCheckObject.vencimentoCheque = vencimentoCheque;
  bankCheckObject.emissaoCheque = emissaoCheque; 
  bankCheckObject.nroDocumentoCheque = nroDocumentoCheque;
  bankCheckObject.codigoWBA = codigoWBA;

  bankCheckObject.logradouroCheque = logradouroCheque;
  bankCheckObject.numeroCheque = numeroCheque;
  bankCheckObject.bairroCheque = bairroCheque;
  bankCheckObject.municipioCheque = municipioCheque;
  bankCheckObject.ufCheque = ufCheque;

  dispatch({ type: SET_BANKCHECK, data: bankCheckObject });
}

export const setBankCheck = (documentoCheque, nomeCheque, valorCheque, vencimentoCheque, emissaoCheque, nroDocumentoCheque, codigoWBA, logradouroCheque, numeroCheque, bairroCheque, municipioCheque, ufCheque) => 
{
  return dispatch => {
    setBankCheckEvent(dispatch, documentoCheque, nomeCheque, valorCheque, vencimentoCheque, emissaoCheque, nroDocumentoCheque, codigoWBA, logradouroCheque, numeroCheque, bairroCheque, municipioCheque, ufCheque);
  }
}

const setDuplicateEvent = (dispatch, documentoDuplicata, nomeDuplicata, valorDuplicata, vencimentoDuplicata, emissaoDuplicata, nroDocumentoDuplicata, tipoDuplicata, desconto, descontoPorCento, codigoWBA, logradouroDuplicata, numeroDuplicata, bairroDuplicata, municipioDuplicata, ufDuplicata) => {
  let duplicateObject = Object.create({}, duplicate);

  duplicateObject.documentoDuplicata = documentoDuplicata;
  duplicateObject.nomeDuplicata = nomeDuplicata;
  duplicateObject.valorDuplicata = valorDuplicata;
  duplicateObject.vencimentoDuplicata = vencimentoDuplicata;
  duplicateObject.emissaoDuplicata = emissaoDuplicata; 
  duplicateObject.nroDocumentoDuplicata = nroDocumentoDuplicata;
  duplicateObject.tipoDuplicata = tipoDuplicata;
  duplicateObject.desconto = desconto;
  duplicateObject.descontoPorCento = descontoPorCento;
  duplicateObject.codigoWBA = codigoWBA;

  duplicateObject.logradouroDuplicata = logradouroDuplicata;
  duplicateObject.numeroDuplicata = numeroDuplicata;
  duplicateObject.bairroDuplicata = bairroDuplicata;
  duplicateObject.municipioDuplicata = municipioDuplicata;
  duplicateObject.ufDuplicata = ufDuplicata;

  dispatch({ type: SET_DUPLICATE, data: duplicateObject });
}

export const setDuplicate = (documentoDuplicata, nomeDuplicata, valorDuplicata, vencimentoDuplicata, emissaoDuplicata, nroDocumentoDuplicata, tipoDuplicata, desconto, descontoPorCento, codigoWBA, logradouroDuplicata, numeroDuplicata, bairroDuplicata, municipioDuplicata, ufDuplicata) => 
{
  return dispatch => {
    setDuplicateEvent(dispatch, documentoDuplicata, nomeDuplicata, valorDuplicata, vencimentoDuplicata, emissaoDuplicata, nroDocumentoDuplicata, tipoDuplicata, desconto, descontoPorCento, codigoWBA, logradouroDuplicata, numeroDuplicata, bairroDuplicata, municipioDuplicata, ufDuplicata);
  }
}

const setFavoredEvent = (dispatch, favored) => {
  dispatch({ type: SET_FAVORED, data: favored });
}

export const setFavored = (favored) => 
{
  return dispatch => {
    setFavoredEvent(dispatch, favored);
  }
}

const setFileListEvent = (dispatch, fileList) => {
  let list = [];

  let self = this;
      
  const s3 = new AWS.S3({
    accessKeyId: 'AKIAIM54H7SBTJOZI6DQ',
    secretAccessKey: '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',
    region: 'us-east-2',
  });

  fileList.map((file, index) => {
    s3.upload({
      Bucket: 'blue-temp-files',
      Key: file.name.replace(/ /g, '_'),
      Body: file,

      ACL: 'public-read'
    },function(err, data){
      list.push(data.Location);
    });
  })

  dispatch({ type: SET_FILE_LIST, data: list});
}

export const setFileList = (fileList) => 
{
  return dispatch => {
    setFileListEvent(dispatch, fileList);
  }
}

const setItemListEvent = (dispatch, itemListItem) => {
  let list = [];

  itemListItem.map((entry, index) => {
    let itemObject = Object.create({}, item);
    
    itemObject.tipoItem = entry.tipoItem;
    itemObject.documento = entry.documento;
    itemObject.nome = entry.nome;
    itemObject.valor = entry.valor;
    itemObject.vencimento = entry.vencimento;
    itemObject.emissao = entry.emissao;
    itemObject.nroDocumento = entry.nroDocumento;
    itemObject.tipoDuplicata = entry.tipoDuplicata;
    itemObject.desconto = entry.desconto;
    itemObject.descontoPorCento = entry.descontoPorCento;
    itemObject.documentIndex = entry.documentIndex;
    itemObject.urlXML = entry.urlXML;

    itemObject.codigoWBA = entry.codigoWBA;

    itemObject.logradouro = entry.logradouro;
    itemObject.numero = entry.numero;
    itemObject.bairro = entry.bairro;
    itemObject.municipio = entry.municipio;
    itemObject.uf = entry.uf;

    list.push(itemObject);
  })

  dispatch({ type: SET_ITEM_LIST, data: list});
}

export const setItemList = (itemList) => 
{
  return dispatch => {
    setItemListEvent(dispatch, itemList);
  }
}

const setDrawnListEvent = (dispatch, ClientID) => {
  var obj = {
    qid : 'PAGINA_DASHBOARD:SACADOS',
    conditions: [
      {
        filterid: 'USUARIO',
        values: [ClientID]
      }
    ]
  }

  var drawnList = [];

  fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj)
    }).then((response) => response.json()).then((responseJson) => {
      responseJson.list.map((entry, index) => {
        let drawnObject = Object.create({}, drawn);
        
        drawnObject.documento = entry.DOCUMENTO;
        drawnObject.razSoc = entry.RAZAO_SOCIAL;
        drawnObject.logradouro = entry.LOGRADOURO;
        drawnObject.bairro = entry.BAIRRO;
        drawnObject.numero = entry.NUMERO;
        drawnObject.municipio = entry.CIDADE;
        drawnObject.uf = entry.ESTADO;

        drawnObject.codigoWBA = entry.CODIGO_WBA;

        drawnList.push(drawnObject);
      });

      dispatch({ type: SET_DRAWN_LIST, data: drawnList});
  });
}

export const setDrawnList = (ClientID) => 
{
  return dispatch => {
    setDrawnListEvent(dispatch, ClientID);
  }
}
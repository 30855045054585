import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Tab, AppBar, Tabs, Paper, Typography, Divider, TextField, Box, FormControl, NativeSelect, Select, Button, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Modal, InputAdornment, MenuItem, Checkbox } from '@material-ui/core';
// @material-ui/icons
import CloseIcon from '@material-ui/icons/Close';
// react-redux
import { connect } from 'react-redux';
import { setDrawnList } from '../../../../redux/actions/NewOperationActions';
// react-input-mask
import InputMask from "react-input-mask";
// react-toastify
import { toast } from 'react-toastify';

class DrawnRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documento: '',
      IM: '',
      IE: '',
      email: '',

      razSoc: '',
      nomeFantasia: '',
      telefone: '',

      cep: '',
      logradouro: '',
      complemento: '',
      cidade: '',
      numero: '',
      bairro: '',
      estado: '',

      documentoMask: '999.999.999-999',
      telefoneMask: '(99) 9999-9999',

      loading: false,

      clientID: 0,
    };

    this.AddSacado = this.AddSacado.bind(this);
  }

  handleDocumentoChange = (event) => {
    this.setState({
      documento: event.target.value,
    }, () => {
      if (this.state.documento.match(/\d/g) && this.state.documento.match(/\d/g).length > 11){
        this.setState({
          documentoMask: '99.999.999/9999-99'
        })
      }
      else {
        this.setState({
          documentoMask: '999.999.999-999'
        })
      }
    })
  }

  handleIMChange = (event) => {
    this.setState({
      IM: event.target.value,
    })
  }

  handleIEChange = (event) => {
    this.setState({
      IE: event.target.value,
    })
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    })
  }

  handleRazSocChange = (event) => {
    this.setState({
      razSoc: event.target.value,
    })
  }

  handleNomeFantasiaChange = (event) => {
    this.setState({
      nomeFantasia: event.target.value,
    })
  }

  handleTelefoneChange = (event) => {
    this.setState({
      telefone: event.target.value,
    }, () => {
      if (this.state.telefone.match(/\d/g) && this.state.telefone.match(/\d/g).length > 10){
        this.setState({
          telefoneMask: '(99) 99999-9999'
        })
      }
      else {
        this.setState({
          telefoneMask: '(99) 9999-99999'
        })
      }
    })
  }

  handleCEPChange = (event) => {
    this.setState({
      cep: event.target.value,
    }, () => {
      if (this.state.cep.match(/\d/g) && this.state.cep.match(/\d/g).length > 7){
        var cep = this.state.cep.replace('-', '');
        
        fetch('https://viacep.com.br/ws/' + cep + '/json/').then(
          (response) => response.json()).then((responseJson) => {
            console.log("cep", responseJson);

            this.setState({
              logradouro: responseJson.logradouro,
              complemento: responseJson.complemento,
              cidade: responseJson.localidade,
              bairro: responseJson.bairro,
              estado: responseJson.uf,
            })
        });
      }
    })

  }

  handleLogradouroChange = (event) => {
    this.setState({
      logradouro: event.target.value,
    })
  }

  handleComplementoChange = (event) => {
    this.setState({
      complemento: event.target.value,
    })
  }

  handleCidadeChange = (event) => {
    this.setState({
      cidade: event.target.value,
    })
  }

  handleNumeroChange = (event) => {
    this.setState({
      numero: event.target.value,
    })
  }

  handleBairroChange = (event) => {
    this.setState({
      bairro: event.target.value,
    })
  }

  handleEstadoChange = (event) => {
    this.setState({
      estado: event.target.value,
    })
  }

  AddSacado() {
    if (!this.validateSacado())
      return

    this.setState({
      loading: true
    });

    var token = this.props.credencials.auth_ticket;

    var obj = {
      tid: 'VF9TQUNBRE9TOjA2ODkwOA==',
      fid: 148,
      data: {
        FK_CLIENTE: this.state.clientID,

        DOCUMENTO: this.state.documento, 
        IM: this.state.IM, 
        IE: this.state.IE, 
        EMAIL: this.state.email, 
        RAZAO_SOCIAL: this.state.razSoc, 
        NOME_FANTASIA: this.state.nomeFantasia, 
        TELEFONE: this.state.telefone, 
        
        CEP: this.state.cep, 
        LOGRADOURO: this.state.logradouro, 
        COMPLEMENTO: this.state.complemento, 
        CIDADE: this.state.cidade, 
        NUMERO: this.state.numero, 
        BAIRRO: this.state.bairro, 
        ESTADO: this.state.estado,
      },
      type: 1,
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'auth' : token,
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log("AddSacado", responseJson);

        this.props.setDrawnList(this.props.credencials.user_id);
        // this.generateScript(responseJson.data.result.id);
        
        this.setState({
          loading: false
        });

        toast.success('Sacado incluído com sucesso. Nas próximas 2 horas você receberá a notificação de Aprovação do Sacado para prosseguir a operação.');

        this.props.handleModal();
    });
  }

  generateScript(sacadoID) {
    let script = `insert into [SECURIT]..sigcad 
                    (codigo, nome, cgc, tipo, registro, tipopessoa, raizcnpj, celular, email, compl)  
                    SELECT MAX(codigo) + 1 AS codigo,'` + this.state.razSoc + `',
                    '` + this.state.documento + `','Sacado',GETDATE(),'J',
                    '` + this.state.documento.substring(0, 10) + `','` + this.state.telefone + `',
                    '` + this.state.email + `','{T_SACADOS_ID:` + sacadoID + `}'
                  FROM [SECURIT]..sigcad` 

    var obj = {
      tid: 'VF9TQUNBRE9TX1NDUklQVDoxMjUzNDQ=',
      fid: 154,
      data: {
        FK_SACADO: sacadoID,
        SCRIPT: script
      }
    }

    fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json())
      .then((responseJson) => {
        console.log('generateScript', responseJson);
    });
  }

  componentDidMount() {
    if (this.props.documento.match(/\d/g) && this.props.documento.match(/\d/g).length > 11){
      this.setState({
        documentoMask: '99.999.999/9999-99'
      })
    }
    else {
      this.setState({
        documentoMask: '999.999.999-999'
      })
    }
    
    this.state.documento = this.props.documento;
    this.state.razSoc = this.props.razaoSocial;
    
    this.getClientID();
    this.props.setDrawnList(this.props.credencials.user_id);
  }

  getClientID(){
    var obj = {
      qid : 'PAGINA_DASHBOARD:CLIENTE_POR_ID',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          this.setState({ clientID: entry.ID });
        })
    });
  }

  validateCPF(cpf) {	
    cpf = cpf.replace(/[^\d]+/g,'');

    if(cpf == '') 
      return false;	
    
    if (cpf.length != 11 || 
      cpf == "00000000000" || 
      cpf == "11111111111" || 
      cpf == "22222222222" || 
      cpf == "33333333333" || 
      cpf == "44444444444" || 
      cpf == "55555555555" || 
      cpf == "66666666666" || 
      cpf == "77777777777" || 
      cpf == "88888888888" || 
      cpf == "99999999999")
        return false;		

    let add = 0;	
    
    for (var i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
    
    let rev = 11 - (add % 11);	
    
    if (rev == 10 || rev == 11)		
      rev = 0;	
    
    if (rev != parseInt(cpf.charAt(9)))		
      return false;		

    add = 0;	

    for (i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    
    rev = 11 - (add % 11);	
  
    if (rev == 10 || rev == 11)	
      rev = 0;	
  
    if (rev != parseInt(cpf.charAt(10)))
      return false;		
    
    return true;   
  }

  validateCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,'');
 
    if(cnpj == '') 
      return false;
     
    if (cnpj.length != 14)
        return false;
 
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;
         
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(0))
        return false;
         
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      
      if (pos < 2)
        pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(1))
      return false;
           
    return true;
  }

  validateSacado() {
    if (!this.state.documento || !this.state.documento.match(/\d/g)){
      toast.error('CPF/CNPJ deve ser preenchido.');
      return false
    }
    
    if (!this.state.razSoc){
      toast.error('Razão Social deve ser preenchido.');
      return false
    }

    if (this.state.documento.match(/\d/g).length > 11){
      if (!this.validateCNPJ(this.state.documento)){
        toast.error('CNPJ Invalido.');
        return false
      }
    }
    else {
      if (!this.validateCPF(this.state.documento)){
        toast.error('CPF Invalido.');
        return false
      }
    }

    return true
  }

  render() {
    return (
      <Paper className="modalPaper">
        <div className="modalPaperWrapper">
          {this.state.loading ? 
            <div>
              <Grid container justify="center" alignItems="center" xs={12} style={{height: '350px', width: '1000px'}}>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </div>
          :
           <div>

            <Grid container>
              <Grid item justify="flex-end" alignItems="center" xs={11}>
                <Typography variant="subtitle1" align="left">
                  Cadastrar Sacado
                </Typography>
              </Grid>
              <Grid item justify="flex-end" alignItems="center" xs={1}>
                <a href="#/dashboard" onClick={this.props.handleModal}>
                  <Box display="flex" flexDirection="row-reverse">
                    <CloseIcon />
                  </Box>
                </a>
              </Grid>
            </Grid>
            <div style={{paddingTop: "40px"}}>
              <Grid container spacing={2} style={{width: '75vw'}}>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>CPF/CNPJ *</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <InputMask
                              mask={this.state.documentoMask}
                              maskPlaceholder=" "
                              value={this.state.documento}
                              onChange={this.handleDocumentoChange}
                            >
                              <TextField 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ style: { height: "45px" } }} 
                              />
                            </InputMask>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>IM</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.IM} 
                              onChange={this.handleIMChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>IE</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.IE} 
                              onChange={this.handleIEChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Email</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.email} 
                              onChange={this.handleEmailChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Razão Social *</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.razSoc} 
                              onChange={this.handleRazSocChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Nome Fantasia</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.nomeFantasia} 
                              onChange={this.handleNomeFantasiaChange}
                            />
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Telefone</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <InputMask
                              mask={this.state.telefoneMask}
                              maskPlaceholder=" "
                              value={this.state.telefone}
                              onChange={this.handleTelefoneChange}
                            >
                              <TextField 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ style: { height: "45px" } }} 
                              />
                            </InputMask>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{marginTop: '30px', marginBottom: '30px'}} />
              <Typography style={{marginBottom: '25px'}}>
                <b>Endereço</b>
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>CEP</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <InputMask
                              mask="99999-999"
                              maskPlaceholder=" "
                              value={this.state.cep}
                              onChange={this.handleCEPChange}
                            >
                              <TextField 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ style: { height: "45px" } }} 
                              />
                            </InputMask>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Logradouro</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.logradouro} 
                              onChange={this.handleLogradouroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Complemento</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.complemento} 
                              onChange={this.handleComplementoChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Cidade</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.cidade} 
                              onChange={this.handleCidadeChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={2}> 
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Número</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.numero} 
                              onChange={this.handleNumeroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Bairro</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.bairro} 
                              onChange={this.handleBairroChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                            <Box p={0}>
                              <Typography variant="body2">
                                <b>Estado</b>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <form noValidate autoComplete="off">
                            <TextField 
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" } }} 
                              value={this.state.estado} 
                              onChange={this.handleEstadoChange}/>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs style={{paddingTop: "20px"}}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" p={0}>
                  <Box p={0}>
                    <Button variant="contained" color="primary" onClick={this.AddSacado}>
                      Salvar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </div>
           </div>
          }
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
  };
}

export default connect(mapStateToProps, { setDrawnList } )(DrawnRegistration);
import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import img from "../../images/step1.png";
import img_upload from "../../images/upload.png";

import img_file from "../../images/attached-file.png";

import { addStep1 } from "../../redux/actions/PreCadastroActions";

import Dropzone from "react-dropzone";

import { connect } from "react-redux";

import InputMask from "react-input-mask";

import { PulseLoader } from "react-spinners";

// aws-s3
import AWS from "aws-sdk";
import { Grid, Typography } from "@material-ui/core";

import "../../index.css";

var self = null;

export class Step1 extends Component {
  constructor(props) {
    super(props);

    self = this;

    this.state = {
      action: "",
      nome: "",
      email: "",
      relacaoEmpresa: "",
      telefone: "",
      celular: "",
      senha: "",
      confirmacaoSenha: "",
      fileFaturamento: "",
      fileFaturamentoLocal: "",
      fileEndividamento: "",
      fileEndividamentoLocal: "",
    };
  }

  componentDidMount() {
    this.props.handleBack();
    // this.setState({relacaoEmpresa: event.target.value});

    this.setState({
      nome: this.props.nome,
      email: this.props.email,
      telefone: this.props.telefone,
      celular: this.props.celular,
      senha: this.props.senha,
      confirmacaoSenha: this.props.confirmacaoSenha,
      relacaoEmpresa: this.props.relacaoEmpresa,
    });
  }

  uploadFileFaturamento(file) {
    this.setState({
      loadingFaturamento: true,
      fileFaturamentoLocal: file.name,
    });

    const s3 = new AWS.S3({
      accessKeyId: "AKIAIM54H7SBTJOZI6DQ",
      secretAccessKey: "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",
      region: "us-east-2",
    });

    s3.upload(
      {
        Bucket: "blue-temp-files",
        Key: file.name.replace(/ /g, "_"),
        Body: file,
        ACL: "public-read",
      },
      function (err, data) {
        console.log(data.Location);
        console.log("Successfully uploaded package.");

        self.setState({
          loadingFaturamento: false,
          fileFaturamento: data.Location,
        });

        var obj = self.state;
        obj.fileFaturamento = data.Location;

        self.props.addStep1(obj);
      }
    );
  }

  uploadFileEnvidamento(file) {
    this.setState({
      loadingEndividamento: true,
      fileEndividamentoLocal: file.name,
    });

    const s3 = new AWS.S3({
      accessKeyId: "AKIAIM54H7SBTJOZI6DQ",
      secretAccessKey: "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",
      region: "us-east-2",
    });

    s3.upload(
      {
        Bucket: "blue-temp-files",
        Key: file.name.replace(/ /g, "_"),
        Body: file,
        ACL: "public-read",
      },
      function (err, data) {
        console.log(data.Location);
        console.log("Successfully uploaded package.");

        self.setState({
          loadingEndividamento: false,
          fileEndividamento: data.Location,
        });

        var obj = self.state;
        obj.fileEndividamento = data.Location;

        self.props.addStep1(obj);
      }
    );
  }

  handleNome(txt) {
    //console.log(txt);
    this.setState({ nome: txt });

    var obj = this.state;
    obj.nome = txt;

    this.props.addStep1(obj);
  }

  handleEmail(txt) {
    //console.log(txt);
    this.setState({ email: txt });

    var obj = this.state;
    obj.email = txt;

    this.props.addStep1(obj);
  }

  handleCelular(txt) {
    //console.log(txt);
    this.setState({ celular: txt });

    var obj = this.state;
    obj.celular = txt;

    this.props.addStep1(obj);
  }

  handleTelefone(txt) {
    //console.log(txt);
    this.setState({ telefone: txt });

    var obj = this.state;
    obj.telefone = txt;

    this.props.addStep1(obj);
  }

  handleSenha(txt) {
    this.setState({ senha: txt });

    var obj = this.state;
    obj.senha = txt;

    this.props.addStep1(obj);
  }

  handleConfirmacaoSenha(txt) {
    this.setState({ confirmacaoSenha: txt });

    var obj = this.state;
    obj.confirmacaoSenha = txt;

    this.props.addStep1(obj);
  }

  relacaoChange(event) {
    this.setState({ relacaoEmpresa: event.target.value });

    var obj = this.state;
    obj.relacaoEmpresa = event.target.value;

    this.props.addStep1(obj);
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ justifyContent: "center", marginBottom: 20 }}>
            <img
              src={img}
              alt="car"
              style={{ width: 45, height: 45, marginRight: 15 }}
            />
            <a className="step-title">Responsável pelo Cadastramento</a>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <div className="form-fields">
                <a className="form-labels">*Nome</a>
                <input
                  value={this.state.nome}
                  onChange={(event) => this.handleNome(event.target.value)}
                  class="form-control"
                  type="text"
                />
              </div>
              <div className="form-fields">
                <a className="form-labels">*E-mail</a>
                <input
                  value={this.state.email}
                  onChange={(event) => this.handleEmail(event.target.value)}
                  class="form-control"
                  type="text"
                />
              </div>
              <div className="form-fields" style={{marginBottom: '-15px'}}>
                <a className="form-labels">*Senha</a>
                <input
                  value={this.state.senha}
                  onChange={(event) => this.handleSenha(event.target.value)}
                  class="form-control"
                  type="password"
                  style={{ marginBottom: "5px" }}
                />
              </div>
              <a style={{ color: "gray", fontSize: '12px' }}>
                A senha deve conter no mínimo 8 caracteres e pelo menos um
                número e uma letra.
              </a>
              <div className="form-fields" style={{marginTop: '15px'}}>
                <a className="form-labels">*Confirme a Senha</a>
                <input
                  value={this.state.confirmacaoSenha}
                  onChange={(event) =>
                    this.handleConfirmacaoSenha(event.target.value)
                  }
                  class="form-control"
                  type="password"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <div>
                <a className="form-labels">*Relação com a Empresa</a>
                <div
                  className="form-options"
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <ul
                    onChange={this.relacaoChange.bind(this)}
                    style={{ textAlign: "left" }}
                  >
                    <li>
                      <input
                        class="radioBtn"
                        type="radio"
                        value="100"
                        checked={
                          this.state.relacaoEmpresa == 100 ? true : false
                        }
                        name="gender"
                      />{" "}
                      Sócio
                    </li>
                    <li>
                      <input
                        class="radioBtn"
                        type="radio"
                        value="101"
                        checked={
                          this.state.relacaoEmpresa == 101 ? true : false
                        }
                        name="gender"
                      />{" "}
                      Gerente
                    </li>
                    <li>
                      <input
                        class="radioBtn"
                        type="radio"
                        value="102"
                        checked={
                          this.state.relacaoEmpresa == 102 ? true : false
                        }
                        name="gender"
                      />{" "}
                      Financeiro
                    </li>
                    <li>
                      <input
                        class="radioBtn"
                        type="radio"
                        value="103"
                        checked={
                          this.state.relacaoEmpresa == 103 ? true : false
                        }
                        name="gender"
                      />{" "}
                      Outros
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="form-fields" style={{marginTop: "5px"}}>
                  <a className="form-labels">Telefone</a>
                  <InputMask
                    mask="(99) 9999 9999"
                    value={this.props.telefone}
                    onChange={(event) =>
                      this.handleTelefone(event.target.value)
                    }
                    class="form-control"
                    type="text"
                  />
                </div>
                <div className="form-fields" style={{marginTop: "19px"}}>
                  <a className="form-labels">*Celular</a>
                  <InputMask
                    mask="(99) 99999 9999"
                    value={this.props.celular}
                    onChange={(event) => this.handleCelular(event.target.value)}
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
              <div>
                <a className="form-labels">Anexar Arquivos de Faturamento</a>
                <div style={{ width: "100%", marginTop: 10 }}>
                  {!!this.state.fileFaturamento != "" && (
                    <section>
                      <div
                        className="removeFile"
                        onClick={() =>
                          self.setState({
                            fileFaturamentoLocal: "",
                            fileFaturamento: "",
                          })
                        }
                      >
                        x
                      </div>
                      <div class="form-upload">
                        <img
                          src={img_file}
                          alt="car"
                          style={{ width: 62, height: 62, marginBottom: 10 }}
                        />
                        <p>{this.state.fileFaturamentoLocal}</p>
                      </div>
                    </section>
                  )}
                  {!this.state.fileFaturamento != "" && (
                    <div>
                      {!!this.state.loadingFaturamento && (
                        <section>
                          <div class="form-upload">
                            <PulseLoader
                              size={10}
                              color={"#B6CBC2"}
                              loading={true}
                            />
                            <p></p>
                          </div>
                        </section>
                      )}
                      {!this.state.loadingFaturamento && (
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.uploadFileFaturamento(acceptedFiles[0])
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div class="form-upload" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img
                                  src={img_upload}
                                  alt="car"
                                  style={{
                                    width: 62,
                                    height: 40,
                                    marginRight: 15,
                                    marginBottom: 10,
                                  }}
                                />
                                <p style={{ fontSize: 14, color: "#B4BCD6" }}>
                                  Arraste e Solte seu arquivo para adicionar ou
                                  clique aqui
                                </p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <a className="form-labels">Anexar Arquivos de Endividamento</a>
                <div style={{ width: "100%", marginTop: 10 }}>
                  {!!this.state.fileEndividamentoLocal != "" && (
                    <section>
                      <div
                        className="removeFile"
                        onClick={() =>
                          self.setState({
                            fileEndividamentoLocal: "",
                            loadingEndividamento: "",
                          })
                        }
                      >
                        x
                      </div>
                      <div class="form-upload">
                        <img
                          src={img_file}
                          alt="car"
                          style={{ width: 62, height: 62, marginBottom: 10 }}
                        />
                        <p>{this.state.fileEndividamentoLocal}</p>
                      </div>
                    </section>
                  )}
                  {!this.state.fileEndividamentoLocal != "" && (
                    <div>
                      {!!this.state.loadingEndividamento && (
                        <section>
                          <div class="form-upload">
                            <PulseLoader
                              size={10}
                              color={"#B6CBC2"}
                              loading={true}
                            />
                          </div>
                        </section>
                      )}
                      {!this.state.loadingEndividamento && (
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.uploadFileEnvidamento(acceptedFiles[0])
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div class="form-upload" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img
                                  src={img_upload}
                                  alt="car"
                                  style={{
                                    width: 62,
                                    height: 40,
                                    marginRight: 15,
                                    marginBottom: 10,
                                  }}
                                />
                                <p style={{ fontSize: 14, color: "#B4BCD6" }}>
                                  Arraste e Solte seu arquivo para adicionar ou
                                  clique aqui
                                </p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // <div>
      //   <Row>
      //     <Col style={{textAlign: 'left'}}>
      //       <div style={{justifyContent: 'center', marginBottom: 20 }}>
      //         <img src={img} alt="car" style={{width: 45, height: 45, marginRight: 15}}/>
      //         <a className="step-title">Responsável pelo Cadastramento</a>
      //       </div>
      //     </Col>
      //   </Row>
      //   <Row style={{textAlign: 'left'}}>
      //     <Col>
      //       <div className="form-fields">
      //         <a className="form-labels">*Nome</a>
      //         <input value={this.state.nome} onChange={(event) => this.handleNome(event.target.value)} class="form-control" type='text' />
      //       </div>
      //       <div className="form-fields">
      //         <a className="form-labels">*E-mail</a>
      //         <input value={this.state.email} onChange={(event) => this.handleEmail(event.target.value)} class="form-control" type='text' />
      //       </div>
      //       <div className="form-fields">
      //         <a className="form-labels">*Senha</a>
      //         <input value={this.state.senha} onChange={(event) => this.handleSenha(event.target.value)}  class="form-control" type='password' />
      //       </div>
      //       <div className="form-fields">
      //         <a className="form-labels">*Confirme a Senha</a>
      //         <input value={this.state.confirmacaoSenha} onChange={(event) => this.handleConfirmacaoSenha(event.target.value)}  class="form-control" type='password' />
      //       </div>
      //     </Col>
      //     <Col>
      //       <div style={{height: 180}}>
      //         <a className="form-labels">Relação com a Empresa</a>
      //         <div className="form-options" style={{width: '100%', marginTop: 10}}>
      //           <ul  onChange={this.relacaoChange.bind(this)}>
      //             <li><input class="radioBtn" type="radio" value="100" checked={(this.state.relacaoEmpresa == 100 ? true : false)} name="gender"/> Sócio</li>
      //             <li><input class="radioBtn"  type="radio" value="101" checked={(this.state.relacaoEmpresa == 101 ? true : false)} name="gender"/> Gerente</li>
      //             <li><input class="radioBtn"  type="radio" value="102" checked={(this.state.relacaoEmpresa == 102 ? true : false)} name="gender"/> Financeiro</li>
      //             <li><input class="radioBtn"  type="radio" value="103" checked={(this.state.relacaoEmpresa == 103 ? true : false)} name="gender"/> Outros</li>
      //           </ul>
      //         </div>
      //       </div>
      //       <div className="form-fields">
      //         <div className="form-fields">
      //           <a className="form-labels">Telefone</a>
      //           <InputMask mask="(99) 9999 9999" value={this.props.telefone} onChange={(event) => this.handleTelefone(event.target.value)} class="form-control" type='text' />
      //         </div>
      //         <div  className="form-fields">
      //           <a className="form-labels">*Celular</a>
      //           <InputMask mask="(99) 99999 9999"  value={this.props.celular} onChange={(event) => this.handleCelular(event.target.value)} class="form-control" type='text' />
      //         </div>
      //       </div>
      //     </Col>
      //     <Col>
      //       <div>
      //         <a className="form-labels">Anexar Arquivos de Faturamento</a>
      //         <div style={{width: '100%', marginTop: 10}}>
      //           {!! this.state.fileFaturamento != '' &&
      //             <section>
      //               <div className='removeFile' onClick={() => self.setState({fileFaturamentoLocal: '', fileFaturamento: ''})}>
      //                   x
      //               </div>
      //               <div class="form-upload">
      //                 <img src={img_file} alt="car" style={{width: 62, height: 62, marginBottom: 10}}/>
      //                 <p>{this.state.fileFaturamentoLocal}</p>
      //               </div>
      //             </section>
      //           }
      //           {! this.state.fileFaturamento != '' &&
      //             <div>
      //               {!! this.state.loadingFaturamento &&
      //                 <section>
      //                   <div class="form-upload">
      //                     <PulseLoader
      //                       size={10}
      //                       color={'#B6CBC2'}
      //                       loading={true}
      //                     />
      //                     <p></p>
      //                   </div>
      //                 </section>
      //               }
      //               {! this.state.loadingFaturamento &&
      //                 <Dropzone onDrop={acceptedFiles => this.uploadFileFaturamento(acceptedFiles[0])}>
      //                   {({getRootProps, getInputProps}) => (
      //                     <section>
      //                       <div class="form-upload" {...getRootProps()}>
      //                         <input {...getInputProps()} />
      //                         <img src={img_upload} alt="car" style={{width: 62, height: 40, marginRight: 15, marginBottom: 10}}/>
      //                         <p style={{fontSize: 14, color: '#B4BCD6'}}>Arraste e Solte seu arquivo para adicionar ou clique aqui</p>
      //                       </div>
      //                     </section>
      //                   )}
      //                 </Dropzone>
      //               }
      //             </div>
      //           }
      //         </div>
      //       </div>
      //       <div style={{marginTop: 20}}>
      //         <a className="form-labels">Anexar Arquivos de Endividamento</a>
      //         <div style={{width: '100%', marginTop: 10}}>
      //           {!! this.state.fileEndividamentoLocal != '' &&
      //             <section>
      //               <div className='removeFile' onClick={() => self.setState({fileEndividamentoLocal: '', loadingEndividamento: ''})}>
      //                 x
      //               </div>
      //               <div class="form-upload">
      //                 <img src={img_file} alt="car" style={{width: 62, height: 62, marginBottom: 10}}/>
      //                 <p>{this.state.fileEndividamentoLocal}</p>
      //               </div>
      //             </section>
      //           }
      //           {! this.state.fileEndividamentoLocal != '' &&
      //             <div>
      //               {!! this.state.loadingEndividamento &&
      //                 <section>
      //                   <div class="form-upload">
      //                     <PulseLoader
      //                       size={10}
      //                       color={'#B6CBC2'}
      //                       loading={true}
      //                     />
      //                   </div>
      //                 </section>
      //               }
      //               {! this.state.loadingEndividamento &&
      //                 <Dropzone onDrop={acceptedFiles => this.uploadFileEnvidamento(acceptedFiles[0])}>
      //                   {({getRootProps, getInputProps}) => (
      //                     <section>
      //                       <div class="form-upload" {...getRootProps()}>
      //                         <input {...getInputProps()} />
      //                         <img src={img_upload} alt="car" style={{width: 62, height: 40, marginRight: 15, marginBottom: 10}}/>
      //                         <p style={{fontSize: 14, color: '#B4BCD6'}}>Arraste e Solte seu arquivo para adicionar ou clique aqui</p>
      //                       </div>
      //                     </section>
      //                   )}
      //                 </Dropzone>
      //               }
      //             </div>
      //           }
      //         </div>
      //       </div>
      //     </Col>
      //   </Row>
      // </div>
    );
  }
}

function mapStateToProps(state) {
  console.log(state);

  return {
    nome: state.PreCadastroReducer.nome,
    telefone: state.PreCadastroReducer.telefone,
    email: state.PreCadastroReducer.email,
    celular: state.PreCadastroReducer.celular,
    relacaoEmpresa: state.PreCadastroReducer.relacaoEmpresa,
  };
}

export default connect(mapStateToProps, { addStep1 })(Step1);

import React, { Component } from 'react';
import '../Style.css';
// @material-ui/core
import { Grid, Badge, Popover, Typography, Avatar, OutlinedInput, InputAdornment } from '@material-ui/core';
// @material-ui/icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
// core notification
import AppbarNotification from './AppbarNotification';
import AppbarUserControl from './AppbarUserControl';
// react-redux
import { connect } from 'react-redux';

class AppbarUserPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      notificationLength: 0,
      notificationList: []
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  componentDidMount() {
    this.getNotificationList();
  }

  getNotificationList() {
    var obj = {
      qid : 'PAGINA_DASHBOARD:NOTIFICACOES',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        console.log('getNotificationList', responseJson);

        if (responseJson.error == null) {
          this.setState({
            notificationLength: responseJson.list.length,
            notificationList: responseJson.list,
          });
        }
    });
  }

  handlePopoverOpen(event, popoverId) {
    this.setState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });

    this.clearNotificationList();
  }

  handlePopoverClose() {
    this.setState({
      openedPopoverId: null,
      anchorEl: null,
    });
  }

  clearNotificationList() {
    var token = this.props.credencials.auth_ticket;

    var count = 0;

    this.state.notificationList.map((entry) => {
      var obj = {
        tid: 'VF9DTElFTlRFX1BFTkRFTkNJQVM6MTUyMDY2',
        fid: 125,
        key: {
          id: entry.ID,
        },
        data: {
          FLG_NOTIFICACAO: 1
        },
        type: 1,
      }

      fetch('https://apps.blueprojects.com.br/nobel/Integration/Save', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'auth' : token,
        },
        body: JSON.stringify(obj)
        }).then((response) => response.json().then((responseJson) => {
          console.log("clearNotificationList", responseJson);
          
          count++;

          console.log(count, this.state.notificationList.length)

          if (count == this.state.notificationList.length)
            this.getNotificationList();
      }));
    })
  }

  render() {
    const { anchorEl, openedPopoverId } = this.state;
    var str = this.props.credencials.full_name;
    
    var avatarChar;
    
    if (str != null)
      avatarChar = str.substring(0, 1) + str.substring(str.indexOf(" ") + 1, str.indexOf(" ") + 2);

    return (
      <div>
        <Grid container justify="center" alignItems="center">
          <div className="notificationWrapper">
            <Badge variant="dot" badgeContent={this.state.notificationLength} color="error" className="notificationBadge" >
              <a href="#/dashboard" variant="contained" onClick={(e) => this.handlePopoverOpen(e, "notificationPopOver")}>
                <NotificationsIcon className="notificationIcon" />
              </a>
            </Badge>
          </div>
          <Popover
            id="notificationPopOver"
            open={openedPopoverId === "notificationPopOver"}
            onClose={this.handlePopoverClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{ style: {paddingLeft: "30px", paddingRight: "30px", width:"400px"} }}
          >
            <AppbarNotification notificationList={this.state.notificationList} />
          </Popover>
          <a href="#/dashboard" className="appbarUser" onClick={(e) => this.handlePopoverOpen(e, "userPopOver")}>
            <Typography>Usuário <ArrowDropDownIcon /></Typography>
          </a>
          <Popover
            id="userPopOver"
            open={openedPopoverId === "userPopOver"}
            onClose={this.handlePopoverClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{ style: {paddingLeft: "30px", paddingRight: "30px", width:"300px"} }}
          >
            <AppbarUserControl />
          </Popover>
          <Avatar>{avatarChar}</Avatar>
        </Grid> 
      </div>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(AppbarUserPanel);
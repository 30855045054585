import React, { Component } from 'react';
import '../../components/Style.css';
// @material-ui/core
import { Typography, Grid, Paper, Divider, CircularProgress } from '@material-ui/core';
// @material-ui/icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// recharts
import { PieChart, Pie, Cell, ResponsiveContainer, Label, AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip} from 'recharts';
// react-redux
import { connect } from 'react-redux';

var self = null;

const data = [{name: '10/10/2019', uv: 400, pv: 100,},
              {name: '20/10/2019', uv: 250, pv: 180,}, 
              {name: '30/10/2019', uv: 300, pv: 170,}, 
              {name: '10/11/2019', uv: 500, pv: 200,}];



const COLORS = ['#0088FE', '#d3d3d3'];

class chartPerCent{
  name = '';
  value = 0;
}

class DashboardAreaChart extends Component {
  constructor(props) {

    

    super(props);
    this.state = {
      totalValue: 0,
      valueToExpire: 0,
      expiredValue: 0,
      loadingTimer: true,
      dashSource: [],
    };

    self = this;

  }

  componentDidMount(){
    var obj = {
      qid : 'SACADO_VENCER_VENCIDO:VENCER_VENCIDO',
      conditions: [
          {
              filterid: 'USUARIO',
              values: [this.props.credencials.user_id]
          }
      ]
    }
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        
        console.log(">>>>>");


        for(var i = 0; i < responseJson.list.length; i++)
        {
          responseJson.list[i].DATA = self.formatDate(responseJson.list[i].DATA);
        }
        self.setState({dashSource: responseJson.list});
        console.log(responseJson.list);

        
    });
  }

  calculatePerCent(){
    var listPerCent = [];
    let totalValue = 0;
    
    totalValue = this.state.valueToExpire + this.state.expiredValue;

    let chartPerCentValue = Object.create({}, chartPerCent);

    chartPerCentValue.name = "A vencer";
    chartPerCentValue.value = (this.state.valueToExpire * 100) / totalValue;  

    listPerCent.push(chartPerCentValue);

    chartPerCentValue.name = "Vencido";
    chartPerCentValue.value = (this.state.expiredValue * 100) / totalValue;  

    listPerCent.push(chartPerCentValue);

    return listPerCent;
  }

  formatMoney(number) {
    if (number != null)
      return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    else 
      return 'R$ 0,00'
  }


  formatDate(str) {
    var rr = str.split('T');

    var rr2 = rr[0].split('-');
    return rr2[2] + '/' + rr2[1] + '/' + rr2[0];

  }
  

  render() {
    var listPerCent = this.calculatePerCent();

    return (
      <Paper>
        {this.state.loadingTimer == false ? 
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '400px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
        :
        <div className="listWrapper">
          <Grid container>
            <Grid item xs={12} style={{paddingLeft: 20}}>
              <Typography variant="subtitle1" className="listTitle" align="left">
                Top 10 Sacados / Valor Título Vencer
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              
              <Divider />
              

              <ResponsiveContainer width="95%" height={400}>
              
              <AreaChart width={"100%"} height={250} data={this.state.dashSource}
                    margin={{ top: 10, right: 0, left: 0, bottom: 30 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="DATA" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />

                    <Area type="monotone" dataKey="VENCER" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                    <Area type="monotone" dataKey="VENCIDO" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>
              
            </Grid>
            
          </Grid>
        </div>
        }
      </Paper>
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials 
  };
}

export default connect(mapStateToProps, { } )(DashboardAreaChart);
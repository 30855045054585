import { ADD_STEP1, ADD_STEP2, ADD_STEP3, ADD_USER, USER_CREATED } from '../constants/PreCadastroConstants';

const addStep1Event = (dispatch, data) => {
    dispatch({ type: ADD_STEP1, data });
}


export const addStep1 = (item) => 
{
    return dispatch => {
        
        addStep1Event(dispatch, item);
    }
}


const addStep2Event = (dispatch, data) => {
    dispatch({ type: ADD_STEP2, data });
}


export const addStep2 = (item) => 
{
    return dispatch => {
        
        addStep2Event(dispatch, item);
    }
}

const addStep3Event = (dispatch, data) => {
    dispatch({ type: ADD_STEP3, data });
}


export const addStep3 = (item) => 
{
    return dispatch => {
        
        addStep3Event(dispatch, item);
    }
}


const addUserEvent = (dispatch, data) => {
    dispatch({ type: ADD_USER, data });
}


export const addUser = (user) => 
{
    return dispatch => {
        
        addUserEvent(dispatch, user);
    }
}


const userCreatedEvent = (dispatch, data) => {
    dispatch({ type: USER_CREATED, data });
}


export const addCreated = (user) => 
{
    return dispatch => {
        
        userCreatedEvent(dispatch, user);
    }
}



import React from 'react';
// @material-ui/icons
import HomeIcon from '@material-ui/icons/Home';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import HelpIcon from '@material-ui/icons/Help';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import NotesIcon from '@material-ui/icons/Notes';
// core-components
import DashboardHome from "./components/Home/DashboardHome";
import NewOperation from './components/NewOperation/NewOperation';
import FAQ from './components/FAQ/FAQ'
import Operations from './components/Operations/Operations';
import Reports from './components/Reports/Reports';

let dashboardRoutes = [
  {
    name: "Home",
    icon: HomeIcon,
    componentID: 0,
    componentObject: <DashboardHome />,
    accessLevel: 0,
  },
  {
    name: "Nova Operação",
    icon: AddCircleRoundedIcon,
    componentID: 1,
    componentObject: <NewOperation />,
    accessLevel: 0,
  },
  {
    name: "Operações",
    icon: EqualizerIcon,
    componentID: 2,
    componentObject: <Operations />,
    accessLevel: 0,
  },
  {
    name: "Relatórios",
    icon: NotesIcon,
    componentID: 3,
    componentObject: <Reports />,
    accessLevel: 1,
  },
  {
    name: "FAQ",
    icon: HelpIcon,
    componentID: 4,
    componentObject: <FAQ />,
    accessLevel: 0,
  },
];

export default dashboardRoutes;

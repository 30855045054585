import React, { Component } from 'react';
import '../Style.css';
import { forwardRef } from 'react';
import ReactDOMServer from "react-dom/server";
import '../Style.css';
// @material-ui/core
import { Grid, Paper, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Divider, Modal, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Select, MenuItem, FormControl, InputAdornment, CircularProgress, FormControlLabel, Checkbox, Menu } from '@material-ui/core';
import MaterialTable from 'material-table';
// react-redux
import { connect } from 'react-redux';
import { updateOperationsList } from '../../../../redux/actions/OperationsActions';
// @material-ui/icons
import GetAppIcon from '@material-ui/icons/GetApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewListIcon from '@material-ui/icons/ViewList';
import NoteIcon from '@material-ui/icons/Note';
// react-toastify
import { toast } from 'react-toastify';
// import Wijmo styles and components
import 'wijmo/styles/wijmo.css';
import { CollectionView } from 'wijmo/wijmo';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from 'wijmo/wijmo.react.grid';
import { FlexGridFilter } from 'wijmo/wijmo.react.grid.filter';
import { FlexChart, FlexChartSeries } from 'wijmo/wijmo.react.chart';
import * as wjcCore from "wijmo/wijmo";
import '@grapecity/wijmo.cultures/wijmo.culture.pt';
// apply Wijmo license key
import { setLicenseKey } from 'wijmo/wijmo';
import InputMask from "react-input-mask";
import { setDrawnList } from '../../../../redux/actions/NewOperationActions'
import ReactExport from "react-export-excel";
import { getCulture } from '../Culture';
import jsPDF from "jspdf";
import "jspdf-autotable";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

setLicenseKey(
  "portal.nobelbanco.com.br|nobelbanco.com.br,456644433637969#B0ijOklkIs4nIxYHOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZpl6bCR7QBtyN0NWcHhGSClkVapWdNNGOlhzaslFRrs6LFZjWrtyZwpFRr56aUl6SulkNLR7KqpHTUJ7dFZzV9I5RulUO7JXMndUVlREWZ3EbYBjdiJ5bktUc74UM7BHMoNmUyIVMtR5bxYnRRFGdER5N5o7QLNET7MjcoNGbEV5d4kXdBVDZYJHMX3kNytWO5oGTttkaxgkTYRGTQhmV7QkZDdmaTZlbXl6U6g6bzFjTjNGVHZTa6Ekdi3mNWBFMUdkTXZWWrE7aRV4TkZTa6N5cudEZn3CeOxEMG94K8kjZ73EN6EDTZJmYiJzLOhkaLhTc9N5TPBnYw8We7xWM5YWR8l5Ukh4aw54YN5GVjV7KRBHawYWTvcUcyIXevtWWEpke4cUZIVEb05EV7YjaBh4KillcHdlcF94aGp5cjllRDtkVsxWQRBlewdGTDlVRwYzb6QmI0IyUiwiIyY4QBVUOEZjI0ICSiwyMzQzMxMDO4MTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIzQDOxcDMgUDM5ATMyAjMiojI4J7QiwiIyJmLt36Yu26YuFmYsVmYv9GLyJmLt36Yu26YuFmYsVmYv9mLsFGdy3GciojIz5GRiwiIhRGdMBSYjlGdhOcby3mZulEItVEIhl6Zvx6buNWZUBSZgEWay3GdsV7cu36QgQmbp5mblB7TiojIh94QiwiI9YTO7MjNzMDN4QjN6nDui"  
);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class operation {
  NomeCedente = '';
  TipoRecebivel = '';
  ChequeMotivo = '';
  Carteira = '';
  DocumentoSacado = '';
  NumeroDocumento = '';
  Vencimento = null;
  Valor = 0;
  ValorExport = 0;
  Sacado = '';
  NumeroBordero = '';
  Status = '';
  DataPagamento = null;
  ValorPagamento = 0;
  ValorPagamentoExport = 0;
  DataEmissaoNFe = null;
  BoletoLink = '';
  BoletoIcon = '';
};

class ListaRecebiveis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      operationList: [],
      selectedList: [],
      flexGrid: null,

      documentoMask: '999.999.999-999',
      documento: '',
      nome: '',
      numero: '',
      dataInicial: null,
      dataFinal: null,

      selectAllState: true,
      selectAllState2: false,

      selectedAll: false,

      vencimentoFiltro: false,
      aVencerFiltro: false,
      liquidadoFiltro: false,

      loading: false,

      menuAnchor: null,
    }
  }

  updateOperationList(){
    this.setState({
      loading: true,
    })

    var obj = {
      qid : 'PAGINA_DASHBOARD:RELATORIO_LANCAMENTO',
      conditions: [
        {
          filterid: 'USUARIO',
          values: [this.props.credencials.user_id]
        }
      ]
    }
    
    var operationList = [];
  
    fetch('https://apps.blueprojects.com.br/nobel/Integration/Query', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)
      }).then((response) => response.json()).then((responseJson) => {
        responseJson.list.map((entry, index) => {
          let operationObject = Object.create({}, operation);
          
          operationObject.sel = false;
          
          if (entry.BoletoLink != null && entry.Status.toLowerCase() != "liquidado") {
            
            operationObject.BoletoLink = entry.BoletoLink;
          }
          else {
            operationObject.BoletoLink = ""
          }

          operationObject.BoletoIcon = 'Boleto';

          operationObject.TipoRecebivel = entry.TipoRecebivel;

          if (entry.ChequeMotivo != null) {
            operationObject.ChequeMotivo = entry.ChequeMotivo;
          }
          else {
            operationObject.ChequeMotivo = ""
          }

          operationObject.NumeroDocumento = entry.Numero;
          operationObject.Sacado = entry.NomeSacado;

          if (entry.CNPJSacado != null) {
            if (entry.CNPJSacado.length > 11) {
              operationObject.DocumentoSacado = entry.CNPJSacado.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }
            else {
              operationObject.DocumentoSacado = entry.CNPJSacado.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            }
          }
          else {
            operationObject.DocumentoSacado = "";
          }

          var _vencimento = null;
          
          if (entry.Vencimento != null)
            _vencimento = new Date(entry.Vencimento);

          operationObject.Vencimento = _vencimento;

          operationObject.ValorExport = entry.Valor;

          let _valor = wjcCore.Globalize.format(entry.Valor, 'n2');

          operationObject.Valor = _valor;

          var _dataPagamento = null;
          
          if (entry.DataPagamento != null)
            _dataPagamento = new Date(entry.DataPagamento);

          operationObject.DataPagamento = _dataPagamento;

          operationObject.ValorPagamentoExport = entry.ValorPagamento;

          let _valorPagamento = wjcCore.Globalize.format(entry.ValorPagamento, 'n2');
        
          operationObject.ValorPagamento = _valorPagamento;
          
          operationObject.Status = entry.Status;

          // operationObject.NomeCedente = entry.NomeCedente;
          // operationObject.Carteira = entry.Carteira;
          // operationObject.NumeroBordero = entry.NumeroBordero;

          // var _dataEmissaoNFe = null;
          
          // if (entry.DataEmissaoNFe != null)
          //   _dataEmissaoNFe = new Date(entry.DataEmissaoNFe);

          // operationObject.DataEmissaoNFe = _dataEmissaoNFe;
            
          operationList.push(operationObject);
        })
        
        console.log('updateOperationList', responseJson);

        this.setState({
          operationList: operationList,
          loading: false,
        });
      });
  }
  
  componentDidMount() {
    this.updateOperationList();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeTab)
      this.updateOperationList();
  }

  initializeGrid(flex) {
    this.flex = flex;
    let self = this;

    let itensHtml = <div>
                      <NoteIcon style={{color: '#3f51b5'}}/>
                    </div>

    flex.formatItem.addHandler((s, e) => {
      if (e.panel == s.columnHeaders) {
        if (s.columns[e.col].binding == 'sel') {
          // e.cell.innerHTML = '<input class="select-all" tabindex="-1" type="checkbox">';
          this._updateSelectAllBox(s);
        }
      }
      else if (e.panel == s.cells) {
        wjcCore.setAttribute(e.cell.parentElement, 'aria-selected', s.rows[e.row].dataItem.sel);
      }

      if(self.state.selectAllState && self.state.selectAllState2) {
        flex.collectionView.items.forEach((item) => {
          if (self.state.selectedAll){
            item.sel = false;
          }
          else {
            item.sel = true;
          }
        });
        
        self.setState({
          selectAllState2: false,
          selectedAll: !self.state.selectedAll,
        });
      }
      else if(!self.state.selectAllState && !self.state.selectAllState2) {
        self.setState({
          selectAllState: true,
          selectAllState2: true
        });
      }
    });
    
    // select/de-select all items when user clicks the check box on the header
    flex.hostElement.addEventListener('click', (e) => {
      if (wjcCore.hasClass(e.target, 'select-all') && e.target instanceof HTMLInputElement) {
        flex.deferUpdate(() => {
          flex.collectionView.items.forEach((item) => {
            item.sel = (e.target).checked;
          });
        });
      }
    });

    flex.itemFormatter = function(panel, r, c, cell) {
      var col = panel.columns[c];

      cell.align = 'center'

      if (col.name == 'sel') {
        col.header = ' ';
        col.width = 50
      }

      if (col.name == 'Valor') {
        col.header = 'Valor (R$)';
        cell.align = 'right'

        col.width = 120

        flex.columnFooters.setCellData(0, col.index, flex.collectionView.getAggregate(wjcCore.Aggregate.Sum, 'ValorExport'))
      }

      if (col.name == 'ValorExport') {
        col.width = 1
      }

      if (col.name == 'ValorPagamentoExport') {
        col.width = 1
      }

      if (col.name == 'ValorPagamento') {
        col.header = 'Pagamento (R$)';
        cell.align = 'right';

        col.width = 150

        flex.columnFooters.setCellData(0, col.index, flex.collectionView.getAggregate(wjcCore.Aggregate.Sum, 'ValorPagamentoExport'))
      }

      if (col.name == 'BoletoLink') {
        col.width = 1
      }

      if (col.name == 'BoletoIcon' && panel.cellType == 1) {
        col.header = 'Boleto';
        col.width = 100;

        if (self.state.flexGrid.cells.getCellElement(r, col.index - 1) != null && 
            self.state.flexGrid.cells.getCellElement(r, col.index - 1).childNodes.length > 0 &&
            cell.innerHTML == 'Boleto') {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<NoteIcon style={{color: '#3f51b5', pointerEvents: "none"}}/>);
          cell.onclick = () => window.open(self.state.flexGrid.cells.getCellElement(r, col.index - 1).childNodes[0].data, '_blank');
        }
        else {
          cell.innerHTML = ReactDOMServer.renderToStaticMarkup(<NoteIcon style={{color: '#3f51b5', pointerEvents: "none", opacity: "0.2"}}/>);
        }
      }

      if (col.name == 'TipoRecebivel') {
        col.header = 'Recebível';

        col.width = 120
      }

      if (col.name == 'NumeroDocumento') {
        col.header = 'Nº Documento';

        col.width = 140
      }

      if (col.name == 'DocumentoSacado') {
        col.width = 170
      }

      if (col.name == 'ValorPagamento') {
        cell.align = 'center'
      }

      if (col.name == 'Vencimento') {
        col.width = 130
      }

      if (col.name == 'DataPagamento') {
        col.header = 'Pagamento';

        col.width = 130
      }

      if (col.name == 'Status') {
        col.width = 130
      }

      if (col.name == 'ChequeMotivo') {
        col.header = 'Motivo';

        col.width = 90
      }
    }

    flex.columnFooters.rows.push(new wjcCore.grid.GroupRow());

    wjcCore.culture = getCulture();

    this.setState({
      flexGrid: flex
    });

    // if (this.props.quickAcess) {
    //   this.filterAberto();
    // }
  }

  _updateSelectAllBox(grid) {
    let cb = grid.hostElement.querySelector('.select-all');

    if (cb instanceof HTMLInputElement) {
      let items = grid.collectionView.items, last = null, indeterminate = false;
      
      for (let i = 0; i < items.length; i++) {
        if (last != null && items[i].sel != last) {
          indeterminate = true;
          break;
        }
        last = items[i].sel;
      }
      
      if (indeterminate) {
        cb.checked = true;
        cb.indeterminate = true;
      }
      else {
        cb.checked = last;
      }
    }

    this.handleSelectedOperations();
  }

  handleSelectedOperations() {
    let SelectedList = [];
    let self = this;

    setTimeout(function(){
      for (var i = 0; i < self.state.flexGrid.cells.rows.length; i++){
        if (self.state.flexGrid.cells.rows[i]._data.sel){
          SelectedList.push(self.state.flexGrid.cells.rows[i]._data);
        }
      }
  
      self.setState({
        selectedList: SelectedList
      })
    }, 250) // gambiarra
  }

  handleDocumentoChange = (event) => {
    this.setState({
      documento: event.target.value,
    }, () => {
      if (this.state.documento.replace(/\D/g, "").length > 11) {
        this.setState({
          documentoMask: '99.999.999/9999-99'
        })
      }
      else {
        this.setState({
          documentoMask: '999.999.999-999'
        })
      }

      this.initCustomFilterFun()
    });
  }

  handleNomeChange = (event) => {
    this.setState({
      nome: event.target.value,
    }, () => {this.initCustomFilterFun()});
  }

  handleNumeroChange = (event) => {
    this.setState({
      numero: event.target.value,
    }, () => {this.initCustomFilterFun()});
  }

  handleDataInicialChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      dataInicial: event.target.value,
    }, () => {this.initCustomFilterFun()});
  }

  handleDataFinalChange = (event) => {
    if (new Date(event.target.value) > new Date('9999-12-31')){
      var dd = String(new Date(event.target.value).getDate()).padStart(2, '0');
      var mm = String(new Date(event.target.value).getMonth() + 1).padStart(2, '0');
      var yyyy = String(new Date(event.target.value).getFullYear()).padStart(4, '0');

      yyyy = yyyy.substring(0, 4);

      event.target.value = yyyy + '-' + mm + '-' + dd;
    }

    this.setState({
      dataFinal: event.target.value,
    }, () => {this.initCustomFilterFun()});
  } 

  filterCNPJ(e) {
    let filter = e.target.value.toLowerCase().replace(/\D/g, "");
    
    this.flex.collectionView.filter = item => {
      return (filter.length == 0 || item.DocumentoSacado.toLowerCase().replace(/\D/g, "").indexOf(filter) > -1);
    };
  }

  filterNome(e) {
    let filter = e.target.value.toLowerCase();
    
    this.flex.collectionView.filter = item => {
      return (filter.length == 0 || item.NomeCedente.toLowerCase().indexOf(filter) > -1);
    };
  }

  filterNumeroDocumento(e) {
    let filter = e.target.value.toLowerCase();
    
    this.flex.collectionView.filter = item => {
      return (filter.length == 0 || item.NumeroDocumento.toLowerCase().indexOf(filter) > -1);
    };
  }

  filterData(initialDate, finalDate) {
    let filterInitialDateText;
    let filterFinalDateText;

    if (initialDate != null)
      filterInitialDateText = initialDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

    if (finalDate != null)
      filterFinalDateText = finalDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

    let filterInitialDate = new Date(filterInitialDateText);
    let filterFinalDate = new Date(filterFinalDateText);

    this.flex.collectionView.filter = item => {
      let date = new Date(item.Vencimento)
      
      return ((date >= filterInitialDate && (finalDate == null || finalDate == '')) || 
              (date <= filterFinalDate && (initialDate == null || initialDate == '')) || 
              (date >= filterInitialDate && date <= filterFinalDate) ||
              (initialDate == '' && finalDate == ''))
    };
  }

  initCustomFilterFun() {
    let filterFun = (item) => {
      let filCols = this.filter.filterColumns;
      
      if(!filCols){
        filCols = this.filter.grid.columns.map(col=>col.binding);
      }
      
      for(let i = 0;i < filCols.length;i++){
        let colBinding = filCols[i];
        
        let colFil = this.filter.getColumnFilter(colBinding);
        
        if(!colFil.apply(item)){
          return false;
        }
      }

      let initialDate = this.state.dataInicial;
      let finalDate = this.state.dataFinal;

      let filterInitialDateText;
      let filterFinalDateText;

      if (initialDate != null)
        filterInitialDateText = initialDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

      if (finalDate != null)
        filterFinalDateText = finalDate.replace( /(\d{4})-(\d{2})-(\d{2})/, "$2/$3/$1");

      let filterInitialDate = new Date(filterInitialDateText);
      let filterFinalDate = new Date(filterFinalDateText);

      let dateVencimento = new Date(item.Vencimento);
      let datePagamento = new Date(item.DataPagamento);
      let now = new Date(Date.now());
      let nowOffset = new Date(Date.now());

      nowOffset.setDate(now.getDate() - 1);

      //AQUI!!!
      if ((this.state.documento.replace(/\D/g, "").length == 0 || item.DocumentoSacado.toLowerCase().replace(/\D/g, "").indexOf(this.state.documento.replace(/\D/g, "")) > -1) && 
          (this.state.numero.length == 0 || item.NumeroDocumento.toLowerCase().indexOf(this.state.numero) > -1) &&
          (this.state.nome.length == 0 || item.Sacado.toLowerCase().indexOf(this.state.nome.toLowerCase()) > -1) &&
          ((dateVencimento >= filterInitialDate && (finalDate == null || finalDate == '')) || 
           (dateVencimento <= filterFinalDate && (initialDate == null || initialDate == '')) || 
           (dateVencimento >= filterInitialDate && dateVencimento <= filterFinalDate) ||
           ((initialDate == '' || initialDate == null) && (finalDate == '' || finalDate == null))) && 
          ((item.Status.toLowerCase() == 'aberto' && this.props.quickAcess) || !this.props.quickAcess) && 
          ((item.Status.toLowerCase() == 'aberto' && dateVencimento <= nowOffset && this.state.vencimentoFiltro) || !this.state.vencimentoFiltro) &&
          ((item.Status.toLowerCase() == 'liquidado' && datePagamento.setHours(0,0,0,0) == now.setHours(0,0,0,0) && this.state.liquidadoFiltro) || !this.state.liquidadoFiltro) &&
          ((item.Status.toLowerCase() == 'aberto' && dateVencimento > nowOffset && this.state.aVencerFiltro) || !this.state.aVencerFiltro)){
            
        return true;
      }

      return false;
    }

    if(this.filter.grid.collectionView){
      this.filter.grid.collectionView.filter = filterFun;
    }
  }

  initializeFilter(filter) {
    this.filter = filter;
  }

  downloadPdf() {
    console.log(this.state.selectedList);

    for (var i = 0; i < this.state.selectedList.length; i++) {
      if (this.state.selectedList[i].BoletoLink != "") {
        var path = this.state.selectedList[i].BoletoLink; 
        var save = document.createElement('a');  
        save.href = path; 
        save.download = this.state.selectedList[i].NumeroDocumento + ".pdf"; 
        save.target = '_blank'; 
        document.body.appendChild(save);
        save.click();
        document.body.removeChild(save);
      }
    }
  }

  filterAberto() {
    this.flex.collectionView.filter = item => {
      return (item.Status.toLowerCase() == 'aberto');
    };
  }

  loadingComponent(props) {
    const loading = props.loading;

    if (loading) {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return (
        <div>
          <Grid container justify="center" alignItems="center" xs={12} style={{height: '500px'}}>
            <Grid item>
              Você não possui Recebíveis para consultar
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  handleVencido() {
    this.setState({
      vencimentoFiltro: !this.state.vencimentoFiltro
    }, () => {
      this.setState({
        aVencerFiltro: false,
        liquidadoFiltro: false,
      }, () => {
        this.initCustomFilterFun();
      });
    });
  }

  handleAVencer() {
    this.setState({
      aVencerFiltro: !this.state.aVencerFiltro
    }, () => {
      this.setState({
        vencimentoFiltro: false,
        liquidadoFiltro: false,
      }, () => {
        this.initCustomFilterFun()
      });
    });
  }

  handleLiquidado() {
    this.setState({
      liquidadoFiltro: !this.state.liquidadoFiltro
    }, () => {
      this.setState({
        vencimentoFiltro: false,
        aVencerFiltro: false,
      }, () => {
        this.initCustomFilterFun()
      });
    });
  }

  today() {
    var now = new Date();

    var dd = String(new Date(now).getDate()).padStart(2, '0');
    var mm = String(new Date(now).getMonth() + 1).padStart(2, '0');
    var yyyy = String(new Date(now).getFullYear()).padStart(4, '0');

    let today = yyyy + '-' + mm + '-' + dd;

    return today
  }

  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Recebíveis Nobel";
    const headers = [["Nº Documento", "Sacado", "Documento Sacado", "Vencimento", "Valor (R$)", "Data Pagamento", "Pagamento (R$)", "Status"]];

    const data = this.state.selectedList.map(item=> [item.NumeroDocumento, item.Sacado, item.DocumentoSacado, item.Vencimento != null ? item.Vencimento.toLocaleDateString() : '', item.Valor, item.DataPagamento != null ? item.DataPagamento.toLocaleDateString() : '', item.ValorPagamento, item.Status]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("recebiveis_nobel.pdf")
  }

  render() {
    return (
      <div className="expansionPanelWrapper" style={{padding: "20px"}}>
        <div className="App">
          <div>
            <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
              <Grid item xs style={{width: '100%'}}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>CPF/CNPJ Sacado</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <InputMask
                            mask={this.state.documentoMask}
                            maskPlaceholder=" "
                            value={this.state.documento}
                            onChange={this.handleDocumentoChange}
                            disabled={this.state.operationList.length == 0}
                          >
                            <TextField
                              variant="outlined" 
                              fullWidth 
                              InputProps={{ style: { height: "45px" }, }}
                              onChange={this.handleDocumentoChange}
                              // onInput={this.filterCNPJ.bind(this)}
                            />
                          </InputMask>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Razão Social Sacado</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { 
                                height: "45px" 
                              } 
                            }} 
                            value={this.state.nome} 
                            onChange={this.handleNomeChange}
                            // onInput={this.filterNome.bind(this)}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{width: '100%'}}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Nº Documento</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { 
                                height: "45px" 
                              } 
                            }} 
                            value={this.state.numero} 
                            onChange={this.handleNumeroChange}
                            // onInput={this.initCustomFilterFun.bind(this)}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{width: '100%'}}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                  <Grid item xs={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Data de Vencimento (Inicial)</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            type="date" 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { height: "45px" } 
                            }} 
                            onClick={() => {this.setState({dataInicial: this.today()}, () => {this.initCustomFilterFun()})}}
                            value={this.state.dataInicial} 
                            onChange={this.handleDataInicialChange}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" justifyContent="flex-start" p={0}>
                          <Box p={0}>
                            <Typography variant="body2">
                              <b>Data de Vencimento (Final)</b>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                          <TextField 
                            type="date" 
                            variant="outlined" 
                            fullWidth 
                            InputProps={{ 
                              style: { height: "45px" } 
                            }} 
                            onClick={() => {this.setState({dataFinal: this.today()}, () => {this.initCustomFilterFun()})}}
                            value={this.state.dataFinal} 
                            onChange={this.handleDataFinalChange}
                            disabled={this.state.operationList.length == 0}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-end"
                    >
                      <Grid item xs={2} />
                      <Grid item xs={3}>
                        <FormControlLabel
                          style={{marginTop: '25px'}}
                          control={
                            <Checkbox
                              checked = {this.state.vencimentoFiltro}
                              onChange = {() => {this.handleVencido()}}
                              name="checkedB"
                              color="primary"
                              disabled={this.state.operationList.length == 0}
                            />
                          }
                          label="Vencido"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          style={{marginTop: '25px'}}
                          control={
                            <Checkbox
                              checked = {this.state.aVencerFiltro}
                              onChange = {() => {this.handleAVencer()}}
                              name="checkedB"
                              color="primary"
                              disabled={this.state.operationList.length == 0}
                            />
                          }
                          label="A Vencer"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          style={{marginTop: '25px'}}
                          control={
                            <Checkbox
                              checked = {this.state.liquidadoFiltro}
                              onChange = {() => {this.handleLiquidado()}}
                              name="checkedB"
                              color="primary"
                              disabled={this.state.operationList.length == 0}
                            />
                          }
                          label="Liquidado no Dia"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
            </Grid>
          </div>
          <Box display="flex" alignItems="center" justifyContent="center" p={0}>
            <Box p={0}>
              {this.state.operationList.length > 0 ? 
                <div className="App-panel" >
                  <FlexGrid 
                    alternatingRowStep={0} 
                    headersVisibility="Column" 
                    selectionMode="Cell" 
                    itemsSource={this.state.operationList} 
                    initialized={this.initializeGrid.bind(this)}
                    class="custom-flex-grid"
                  >
                    <FlexGridFilter
                      filterApplied={this.initCustomFilterFun.bind(this)}
                      initialized={this.initializeFilter.bind(this)}
                    />
                  </FlexGrid>   
                </div>
              :
                <this.loadingComponent loading={this.state.loading} />
              }
            </Box>
          </Box>
          <Grid item xs={12} style={{width: '100%'}}>
            <Divider style={{width: '100%', margin: '20px 0px 20px 0px'}} />
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" p={0} style={{paddingBottom: "20px"}}>
                <Box p={1}>
                  <Button 
                    color="primary" 
                    onClick={() => this.setState({selectAllState: !this.state.selectAllState})}
                  >
                    {this.state.selectedAll ? 
                      'Remover Todos'
                    :
                      'Selecionar Todos'
                    }
                  </Button>
                </Box>
                <Box p={1}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => this.downloadPdf()}
                  >
                    <CloudDownloadIcon />
                    <div style={{width: '10px'}} />
                    Download PDF
                  </Button>
                </Box>
                <Box p={1}>
                  <Button 
                    variant="contained" 
                    color="primary"
                    onClick={(event) => this.setState({menuAnchor: event.target})}
                    disabled={this.state.selectedList.length == 0}
                  >
                    <GetAppIcon />
                    <div style={{width: '10px'}} />
                    Exportar
                  </Button>
                  <Menu
                    anchorEl={this.state.menuAnchor}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={() => this.setState({menuAnchor: null})}
                  >
                    <MenuItem onClick={() => {
                      this.exportPDF();
                      this.setState({menuAnchor: null})
                    }}>
                      PDF
                    </MenuItem>
                    <MenuItem onClick={() => this.setState({menuAnchor: null})}>
                      <ExcelFile 
                        element={
                          "Excel"
                        }
                        filename='recebiveis_nobel'
                      >
                        <ExcelSheet data={this.state.selectedList} name="Recebíveis">
                          <ExcelColumn label="NomeCedente" value="NomeCedente"/>
                          <ExcelColumn label="Tipo recebível" value="TipoRecebivel"/>
                          <ExcelColumn label="Carteira" value="Carteira"/>
                          <ExcelColumn label="CNPJ Sacado" value="DocumentoSacado"/>
                          <ExcelColumn label="Numero Documento" value="NumeroDocumento"/>
                          <ExcelColumn label="Vencimento" value="Vencimento"/>
                          <ExcelColumn label="Valor" value="ValorExport"/>
                          <ExcelColumn label="Sacado" value="Sacado"/>
                          <ExcelColumn label="Num. Documento" value="NumeroBordero"/>
                          <ExcelColumn label="Status" value="Status"/>
                          <ExcelColumn label="Data Pagamento" value="DataPagamento"/>
                          <ExcelColumn label="Valor Pagamento" value="ValorPagamentoExport"/>
                          <ExcelColumn label="Data Emissao NFe" value="DataEmissaoNFe"/>
                        </ExcelSheet>
                      </ExcelFile>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box p={1}>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div> 
    )
  }
}

function mapStateToProps(state){
  return { 
    credencials: state.PreCadastroReducer.credencials,
  };
}

export default connect(mapStateToProps, { updateOperationsList } )(ListaRecebiveis);